import styled from 'styled-components'
import { Theme } from '../../styles/Theme'

export const Container = styled.div`
  width: 50%;
  background-color: white;
  position: fixed;
  margin: 0 auto;
  top: 0;
  min-height: 500px;
  max-height: max-content;
  border-radius: 10px;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 9999;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .6);

`
export const header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  border-radius: 10px 10px 0 0;
`
export const Title = styled.h1`
  color: ${Theme.primary};
`
export const Icon = styled.div`
  color: black;
  cursor: pointer;
`
export const Form = styled.div`
  color: black;
  padding: 30px;

  display: flex;
  flex-direction: column;
  input {
    padding: 10px;
    border-radius: 5px;
    width: 300px;
    background-color: #efefef;
    border:none;
    margin: 10px 0;
  }
`

export const Button = styled.div`
  padding: 10px;
  background-color: ${Theme.primary};
  color: white;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
  width: 100px;

`

