import React, { useEffect, useState } from 'react';
import { FiImage, FiPaperclip, FiEdit, FiTrash } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie'
import Select from 'react-select';
import Formatter from 'src/components/CompanyAnnouncements/Formatter';
import Loader from 'src/components/Loader';
import axios from '../../services/axios';
import jwt from 'jsonwebtoken'

const SpecialEvent: React.FC = () => {
  type ParamTypes = {
    id: string;
  };
  interface imageFormat {
    selectedFile: '';
    type: '';
  }
  interface imageArray extends Array<imageFormat> {}

  const owner_id = localStorage.getItem('owner_id');
  const [loaded, setLoaded] = useState<boolean>(true);
  const [specialEvent, setSpecialEvent] = useState<any>({});
  const [newComment, setNewComment] = useState('');
  const [imageFile, setImageFile] = useState<imageArray>();
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const [usersData, setUsersData] = useState([]);
  const [additionalFollowers, setAdditionalFollowers] = useState([]);
  const [additionalFollowersIds, setAdditionalFollowersIds] = useState<any>({})
  const [editMode, setEditMode] = useState<string[]>([]);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [address, setAddress] = useState('');
  const [eventStart, setEventStart] = useState('');
  const [eventEnd, setEventEnd] = useState('');
  const [editComment, setEditComment] = useState('');
  const [updatedComment, setUpdatedComment] = useState('');
  const [permissions, setPermissions] = useState({
    view: false,
    add: false,
    edit: false,
    delete: false
  })
  const [cookies, ,] = useCookies(['token']);
  const token = cookies.token


  const handleLoaded = async () => {
    try {
      const response: any = await axios.get(`/api/v1/events/${id}`);
      // const filteredEvent = mockEventsData.filter((e) => e.id === id);
      // if (filteredEvent.length <= 0) history.push('/calendar');
      if (response.data.results.length <= 0) history.push('/calendar');
      await setSpecialEvent(response.data.results);
      const additionalFollowers: any = [];
      if (response.data.results.additional_users) {
        await response.data.results.additional_users.forEach((user: any) => {
          additionalFollowers.push({
            value: user.customer_id,
            label: user.label
          });
        });
      }
      await setAdditionalFollowers(additionalFollowers);
      await setAdditionalFollowersIds(additionalFollowers)
      await setLoaded(false);
    } catch (error) {
      alert('Failed to fetch the event, try again later.');
      console.log(error);
    }
  };

  function fileSelect(props: any) {
    function multiImages(props: any, size: any) {
      const reader = new FileReader();
      reader.readAsDataURL(props[size]);
      reader.onload = (e: any) => {
        setImageFile((prevState: any) => [
          ...prevState,
          {
            selectedFile: e?.target?.result,
            type: props[size].type
          }
        ]);
      };
    }

    const reader = new FileReader();
    reader.readAsDataURL(props[0]);
    reader.onload = (e: any) => {
      setImageFile([
        {
          selectedFile: e?.target?.result,
          type: props[0].type
        }
      ]);
    };
    setTimeout(() => {
      for (let i = 1; i < props.length; i++) {
        multiImages(props, i);
      }
    }, 500);
  }

  const changeEditModeOfInputs = (field: string) => {
    if (editMode.indexOf(field) === -1) {
      setEditMode([...editMode, field]);
    } else {
      setEditMode(editMode.filter((val) => val !== field));
    }
  };

  const createComment = async () => {
    const data = {
      event_id: id,
      owner_id,
      message: newComment,
      files: imageFile
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const updateFollowersData = {
      event_id: id,
      customer_ids: additionalFollowers
        ? additionalFollowers.map((a: any) => a.value)
        : []
    };

    try {
      await axios.post(`/api/v1/events/special/comments`, data);
      // await axios.put(
      //   '/api/v1/events/special/additional_users',
      //   updateFollowersData
      // );
      alert('Comment created successfully.');
      window.location.reload();
    } catch (error) {
      alert('Error while creating a comment, please try again.');
      handleLoaded();
    }
  };

  const fetchUsers = async (input: string) => {
    if (input.length >= 3) {
      const users: any = [];
      const { data } = await axios.get(`/api/v1/accounts?full_name=${input}`);
      data.results.forEach((user: any) => {
        users.push({
          value: user.id,
          label: `${user.first_name} ${user.last_name} | ${user.email}`
        });
      });
      setUsersData(users);
    }
  };

  const updateEvent = async () => {
    const data = {
      event_title: title || specialEvent?.event_title,
      event_description: description || specialEvent?.event_description,
      event_address: address || specialEvent?.event_address,
      event_start: eventStart || specialEvent?.event_start,
      event_end: eventEnd || specialEvent?.event_end
    };

    try {
      setLoaded(true);
      await axios.put(`/api/v1/events/special/${id}`, data);
      alert('Event updated successfully.');
      setEditMode([]);
      handleLoaded();
    } catch (error) {
      alert(
        'Error while updating the event, please reload the page and try again.'
      );
      setLoaded(false);
    }
  };

  const deleteEvent = async () => {
    try {
      setLoaded(true);
      await axios.delete(`/api/v1/events/${id}`);
      alert('The event was deleted successfully.');
      history.push('/calendar');
    } catch (error) {
      alert(
        'Error while deleting the event, please reload the page and try again.'
      );
      setLoaded(false);
    }
  };

  const deleteConfirmationModal = (
    <div
      id='myModal'
      style={{ display: confirmation ? 'block' : 'none' }}
      className='modal'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <span onClick={() => setConfirmation(false)} className='close'>
            &times;
          </span>
          <h2>
            This event will be deleted for all users, not just on your calendar.
            This exclusion is irreversible. Do you wish to continue?
          </h2>
        </div>
        <div className='modal-confirmation'>
          <form>
            {modalLoading ? (
              <Loader />
            ) : (
              <>
                             <button
                  type='button'
                  onClick={deleteEvent}
                  className='cancelbtn'
                >
                  Yes
                </button>
                <button
                  type='button'
                  onClick={() => setConfirmation(false)}
                  className='deletebtn'
                >
                  No
                </button>
 
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    handleLoaded();
    handlePermissions()
  }, []);


  function handlePermissions() {
    if (localStorage.getItem('token')) {
      jwt.verify(
        localStorage.getItem('token')!.toString(),
        '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
        (err: any, decoded: any) => {
          if (err) {
            alert(err)
          } else {
            if (decoded.roles.events.view === false) {
              alert('Permission denied')
              if (process.env.REACT_APP_URL) {
                window.location.href = `${process.env.REACT_APP_URL}`
              }
            } else {
              setPermissions(decoded.roles.events)
            }
          }
        }
      )
    } else {
      jwt.verify(
        token!.toString(),
        '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
        (err: any, decoded: any) => {
          if (err) {
            alert(err)
          } else {
            if (decoded.roles.events.view === false) {
              alert('Permission denied');
              if (process.env.REACT_APP_URL) {
                window.location.href = `${process.env.REACT_APP_URL}`
              }
            } else {
              setPermissions(decoded.roles.events)
            }
          }
        }
      )
    }
  }


  function saveAdditionalFollowers() {
    let customerIds = []

    if (additionalFollowersIds.length > 0) {
      for (let x of additionalFollowersIds) {
        customerIds.push(x.value)
      }
    }

    const updateFollowersData = {
      event_id: id,
      customer_ids: customerIds
    }

    axios.post('/api/v1/events/special/additional_users', updateFollowersData).then(() => {
      setAdditionalFollowers(additionalFollowersIds)
    })
  }

  return loaded ? (
    <Loader />
  ) : (
    <>
      <div className='specialEventContainer'>
        <h2>
          <FiEdit
            width={34}
            height={34}
            className='specialEventEditButton'
            onClick={() => changeEditModeOfInputs('title')}
          />
          &nbsp;
          {/* <b>{specialEvent?.event_title}</b> */}
          <input
            disabled={editMode.indexOf('title') === -1}
            type='text'
            defaultValue={specialEvent?.event_title}
            onChange={(e) => setTitle(e.target.value)}
            className='specialEventTitle'
          />
        </h2>
        <div className='specialEventInfo'>
          <div className='specialEventInfoRow'>
            <div>
              <section>
                <b>Event type:</b>
              </section>
              <p>{specialEvent?.event_type}</p>
            </div>
            <div>
              <section>
                <b>Creator:</b>
              </section>
              <p>{specialEvent?.created_by.full_name}</p>
            </div>
            <div>
              <section>
                <FiEdit
                  width={34}
                  height={34}
                  className='specialEventEditButton'
                  onClick={() => changeEditModeOfInputs('description')}
                />
                &nbsp;
                <b>Description:</b>
              </section>
              <textarea
                disabled={editMode.indexOf('description') === -1}
                defaultValue={specialEvent?.event_description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div>
              <section>
                <FiEdit
                  width={34}
                  height={34}
                  className='specialEventEditButton'
                  onClick={() => changeEditModeOfInputs('address')}
                />
                &nbsp;
                <b>Address, meeting link, or file link:</b>
              </section>
              <textarea
                disabled={editMode.indexOf('address') === -1}
                defaultValue={specialEvent?.event_address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <button
              onClick={() => updateEvent()}
              className='specialEventUpdateButton'
              style={{ display: editMode.length >= 1 ? '' : 'none' }}
            >
              Save changes
            </button>
          </div>

          <div className='specialEventInfoRow'>
            <div>
              <b>Created at:</b>
              <p>
                {specialEvent?.createdAt.split('').splice(0, 10).join('')}&nbsp;
                {Formatter(
                  specialEvent?.createdAt.split('').splice(11, 2).join(''),
                  specialEvent?.createdAt.split('').splice(13, 3).join('')
                )}
              </p>
            </div>
            <div>
              <b>Last updated at:</b>
              <p>
                {specialEvent?.updatedAt
                  ? specialEvent?.updatedAt.split('').splice(0, 10).join('')
                  : specialEvent?.createdAt.split('').splice(0, 10).join('')}
                &nbsp;
                {specialEvent?.updatedAt
                  ? Formatter(
                      specialEvent?.updatedAt.split('').splice(11, 2).join(''),
                      specialEvent?.updatedAt.split('').splice(13, 3).join('')
                    )
                  : Formatter(
                      specialEvent?.createdAt.split('').splice(11, 2).join(''),
                      specialEvent?.createdAt.split('').splice(13, 3).join('')
                    )}
              </p>
            </div>
            <div>
              <FiEdit
                width={34}
                height={34}
                className='specialEventEditButton'
                onClick={() => changeEditModeOfInputs('start_time')}
              />
              &nbsp;
              <b>Start time:</b>
              <br />
              <input
                type='datetime-local'
                defaultValue={specialEvent?.event_start.slice(0, -8)}
                disabled={editMode.indexOf('start_time') === -1}
                onChange={(e) => setEventStart(e.target.value)}
              />
            </div>
            <div>
              <FiEdit
                width={34}
                height={34}
                className='specialEventEditButton'
                onClick={() => changeEditModeOfInputs('end_time')}
              />
              &nbsp;
              <b>End time:</b>
              <br />
              <input
                type='datetime-local'
                defaultValue={specialEvent?.event_end.slice(0, -8)}
                disabled={editMode.indexOf('end_time') === -1}
                onChange={(e) => setEventEnd(e.target.value)}
              />
            </div>
          </div>

          <div className='specialEventInfoMultiSelectRow'>
            <p>
              <b>Followers (groups):</b>
              <Select
                isMulti
                isDisabled
                id='demo-simple-select'
                name='colors'
                value={specialEvent?.groups_ids}
                className='specialEventMultiSelect'
                classNamePrefix='select'
              />
            </p>
            <p>
              <b>Additional followers:</b>
              <Select
                isMulti
                id='demo-simple-select'
                name='colors'
                // options={[
                //   { value: 'open', label: 'User 1 | user.1@fegllc.com' },
                //   { value: 'closed', label: 'User 2 | user.2@fegllc.com' }
                // ]}
                defaultValue={additionalFollowers}
                onChange={(e) => {
                  if (e) {
                    setAdditionalFollowersIds(e)
                  } else {
                    setAdditionalFollowersIds({})
                  }
                }}
                options={usersData}
                className='specialEventMultiSelect'
                classNamePrefix='select'
                onInputChange={(e: any) => {
                  fetchUsers(e);
                }}
              />
            </p>
            <div className='followersButton'>
              <button
                className='specialEventUpdateButton'
                onClick={() => saveAdditionalFollowers()}
                disabled={additionalFollowers === additionalFollowersIds}
              >
                Save
              </button>
              <button
                style={{display: permissions.delete ? '' : 'none'}}
                onClick={() => setConfirmation(true)}
                className='specialEventDeleteButton'
              >
                Delete event
              </button>
            </div>
          </div>
        </div>

        <h2>Comments</h2>
        <div className='specialEventComments'>
          <div className='specialEventNewCommentContainer'>
            <textarea
              rows={4}
              cols={50}
              maxLength={100}
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
            />
            <div className='specialEventNewCommentActions'>
              <label
                className='btn'
                style={{ display: imageFile === undefined ? '' : 'none' }}
                htmlFor='inputFileImg'
              >
                <FiPaperclip width={30} height={30} />
              </label>
              <input
                style={{ display: imageFile === undefined ? 'none' : '' }}
                id='inputFileImg'
                type='file'
                onChange={(e) => fileSelect(e.target.files)}
                className='form-control mb-3'
                accept='.jpg,.png'
                multiple
              />
              <button
                onClick={() => createComment()}
                className='btn btn-custom'
              >
                Reply
              </button>
            </div>
          </div>

          <div className='specialEventCommentsSection'>
            {specialEvent.comments &&
              specialEvent?.comments.map((singleComment: any, key: any) => (
                <div className='specialEventComment' key={key}>
                  <div className='specialEventCommentData'>
                    <span>
                      <b>#{key + 1}</b>
                    </span>
                    &nbsp;
                    <textarea
                      readOnly={editComment === singleComment.id ? false : true}
                      rows={3}
                      cols={100}
                      maxLength={255}
                      defaultValue={singleComment.message}
                      style={{
                        border:
                          editComment === singleComment.id
                            ? '1px solid rgba(53, 53, 53, 0.267)'
                            : '0px solid rgba(53, 53, 53, 0.267)'
                      }}
                      onChange={(e) => setUpdatedComment(e.target.value)}
                    />
                    {/* <p>{singleComment.message}</p> */}
                    {singleComment.files.map((link: any) => (
                      <>
                        <a href={link}>
                          <FiImage width={30} height={30} />
                        </a>
                        &nbsp;
                      </>
                    ))}
                  </div>
                  <div className='specialEventCommentMetadata'>
                    <button
                      style={{
                        display: editComment === singleComment.id ? '' : 'none'
                      }}
                      onClick={async () => {
                        try {
                          setLoaded(true);
                          await axios.put(
                            `/api/v1/events/special/comments/${singleComment.id}`,
                            {
                              message: updatedComment
                            }
                          );
                          window.location.reload();
                        } catch (error) {
                          alert(
                            'Error while deleting this comment, please reload the page and try again.'
                          );
                          setLoaded(false);
                        }
                      }}
                    >
                      Confirm Updates
                    </button>
                    <FiEdit
                      width={34}
                      height={34}
                      className='specialEventEditButton'
                      style={{ marginRight: '4px' }}
                      onClick={() => {
                        setEditComment(singleComment.id);
                        setUpdatedComment(singleComment.message);
                      }}
                    />
                    <FiTrash
                      width={34}
                      height={34}
                      className='specialEventEditButton'
                      style={{ marginRight: '4px' }}
                      onClick={async () => {
                        const confirmation = window.confirm(
                          'Please confirm your decision. This action is permanent.'
                        );
                        if (confirmation == true) {
                          try {
                            setLoaded(true);
                            await axios.delete(
                              `/api/v1/events/special/comments/${singleComment.id}`
                            );
                            window.location.reload();
                          } catch (error) {
                            alert(
                              'Error while deleting this comment, please reload the page and try again.'
                            );
                            setLoaded(false);
                          }
                        }
                      }}
                    />
                    <img
                      src={singleComment.created_by.url_image}
                      alt='Comment creator profile image'
                      style={{
                        height: '24px',
                        width: '24px',
                        borderRadius: '1'
                      }}
                    />
                    <p>
                      <b>{singleComment.created_by.full_name}</b>
                      &nbsp;
                      {new Date(singleComment.createdAt).toLocaleString(
                        'en-US',
                        {
                          timeZone: 'US/Central'
                        }
                      )}
                      &nbsp;
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      {deleteConfirmationModal}
    </>
  );
};

export default SpecialEvent;
