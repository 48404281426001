import { Theme } from 'src/styles/Theme';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${Theme.light};
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  margin-top: 70px;
  transition: 0.2s;
  z-index: 999;
  overflow: scroll;
  .short {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    border-bottom: 1px solid ${Theme.grey};

    svg {
      color: ${Theme.primary};
    }
  }
  .ask-fegpt {
    margin: 10px 0;
    padding: 0;
  }
  div.list-short {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;

    border-bottom: 1px solid ${Theme.grey};
    :first-of-type {
      border-top: 1px solid ${Theme.grey};
    }
    svg {
      color: ${Theme.primary};
    }
    label {
      width: 5px;
      height: 30px;
      background: ${Theme.primary};
    }
    label.act {
      width: 5px;
      height: 30px;
      background: purple;
    }
  }
  div.list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;

    border-bottom: 1px solid ${Theme.grey};
    :first-of-type {
      border-top: 1px solid ${Theme.grey};
    }

    label.act {
      width: 5px;
      height: 30px;
      background: ${Theme.primary};
    }
    svg {
      color: ${Theme.primary};
    }
  }
`;

export const AppContainer = styled.div`
  div#config {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  display: flex;
  z-index: 99999;
  top: 0;
  width: 100%;
  align-items: center;
  height: 70px;
  position: fixed;
  background-color: ${Theme.light};
  div.app-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 2px 20px;
    img {
      width: 50px;
      height: 50px;
    }
  }
`;
