import { Theme } from 'src/styles/Theme';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 460px) {
    grid-template-columns: 1fr;
  }
`;
export const TitleShortcuts = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 36px;
  justify-content: space-between;
  margin-bottom: 14px;
  h1 {
    color: ${Theme.primary};
    width: 100%;
    @media (max-width: 700px) {
      display: none;
    }
  }
  input {
    padding: 10px;
    border: 1px solid ${Theme.primary};
    border-radius: 14px;
    width: 100%;
    max-width: 380px;
    margin-right: 12px;
  }
  div {
    width: 100%;
    display: flex;
    justify-content: end;
  }
`;

export const GridShortcut = styled.div`
  background-color: ${Theme.light}cc;
  border-radius: 50px;
  margin: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  min-height: 140px;
  height: max-content;
  max-height: 200px;
  max-width: 340px;
  text-align: center;

  a {
    display: none;
  }
  p {
    color: black;
    font-weight: bold;
    font-size: 16px;
  }
  &.active {
    background-color: #cccccccc;

    p {
      display: none;
    }
    a {
      font-size: 13px;
      text-decoration: none;
    }
    a {
      display: flex;
    }
  }

  @media (max-width: 700px) {
    margin: 0;
    height: 100%;

    width: 100%;

    padding: 10px;
    gap: 4px;
    div.legend {
      display: flex;
      flex-direction: column;
    }
  }

  div.legend {
    width: 100%;
  }

  div.div-heart {
    height: 30px;
    width: 30px;
    min-width: 30px;
    position: relative;
    bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid purple;
    cursor: pointer;

    @media (max-width: 700px) {
      height: 25px;
      width: 25px;
      min-width: 25px;
      bottom: 00px;
    }
  }
  img {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    @media (max-width: 700px) {
      width: 50px !important;
      height: 50px;
    }
  }

  p {
    color: #151515;
    font-weight: bold;
  }
  a {
    color: ${Theme.grey};
    text-decoration: none;
  }
  @media (max-width: 460px) {
    p {
      font-size: 12px;
    }
    a {
      font-size: 12px;
    }
  }
`;
