import React, { useState, useEffect } from 'react';
import {
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton
} from '@material-ui/core';
import { ToggleOff } from '@material-ui/icons';
import jwt from 'jsonwebtoken';
import axios from '../../../services/axios';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import Loader from 'src/components/Loader';
import { withStyles } from '@material-ui/core/styles';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FiImage } from 'react-icons/fi';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvided,
  DroppableProvided,
  DraggableStateSnapshot
} from 'react-beautiful-dnd';
import { Theme } from 'src/styles/Theme';

const ShortcutManagement: React.FC = () => {
  const [permissions, setPermissions] = useState({
    view: false,
    add: false,
    edit: false,
    delete: false
  });
  const [loaded, setLoaded] = useState<boolean>(false);
  const [defaultSearch, setDefaultSearch] = useState('');
  const [shortcuts, setShortcuts] = useState<any>([]);
  const [defaultShortcuts, setDefaultShortcuts] = useState<any>([]);
  const [cookies, ,] = useCookies(['token']);
  const token = cookies.token;
  const history = useHistory();

  const bgColor = Theme.primary;
  const textColor = '#808285';

  const TableHeadContainer = withStyles(() => ({
    root: {
      backgroundColor: bgColor
    }
  }))(TableHead);

  const TableHeaderCell = withStyles(() => ({
    root: {
      color: 'white'
    }
  }))(TableCell);

  const TableBodyCell = withStyles(() => ({
    root: {
      color: textColor
    }
  }))(TableCell);

  const handlePermissions = () => {
    if (token) {
      jwt.verify(
        token.toString(),
        '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
        (err: any, decoded: any) => {
          if (err) {
            history.push('/');
          } else {
            if (decoded.roles.shortcuts.view !== true) {
              alert('Insufficient Permissions');
              history.push('/');
            } else {
              setPermissions(decoded.roles.shortcuts);
            }
          }
        }
      );
    }
  };

  function handleLoad() {
    setLoaded(false);

    axios.get(`/api/v1/shortcuts?status=inactive`).then((shortcutsResponse) => {
      setShortcuts(shortcutsResponse.data);
      setDefaultShortcuts(shortcutsResponse.data);
      setLoaded(true);
    });
  }

  const handleSearchShortcut = (name: string) => {
    const filteredShortcuts = defaultShortcuts.filter(
      (shortcut: any) =>
        shortcut.name.toLowerCase().includes(name.toLowerCase()) ||
        shortcut.display_name1.toLowerCase().includes(name.toLowerCase()) ||
        shortcut.display_name2.toLowerCase().includes(name.toLowerCase())
    );
    setShortcuts(filteredShortcuts);
    setDefaultSearch(name);
  };

  const handleActiveShortcut = (id: string, order: number) => {
    setLoaded(false);
    try {
      axios.put(`api/v1/shortcuts/${id}/${order}/activate`).then(() => {
        setTimeout(() => {
          handleLoad();
        }, 300);
      });
    } catch (error) {
      alert('Error activing the shortcut, please try again.');
      handleLoad();
    }
  };

  useEffect(() => {
    handlePermissions();
    handleLoad();
  }, []);

  return !loaded ? (
    <Loader />
  ) : (
    <div className='background'>
      <div className='container'>
        {/* <h2>FEG Home Shortcut Manager</h2> */}
        <h2>Inactive Shortcuts</h2>
        <div className='searchAndAddRow'>
          <TextField
            variant='outlined'
            size='small'
            label='Search Shortcut'
            defaultValue={defaultSearch}
            onChange={(e) => handleSearchShortcut(e.target.value)}
            className='searchShortcut'
          />
        </div>
        <div className='tableSection'>
          <TableContainer component={Paper}>
            <Table size='small' aria-label='Shortcuts table'>
              <TableHeadContainer>
                <TableRow>
                  <TableHeaderCell align='center'>
                    Shortcut Name
                  </TableHeaderCell>
                  <TableHeaderCell align='center'>
                    Display Name 01
                  </TableHeaderCell>
                  <TableHeaderCell align='center'>
                    Display Name 02
                  </TableHeaderCell>
                  <TableHeaderCell align='center'>
                    Desktop Image
                  </TableHeaderCell>
                  <TableHeaderCell align='center'>Mobile Image</TableHeaderCell>
                  <TableHeaderCell align='center'>Link Desktop</TableHeaderCell>
                  <TableHeaderCell align='center'>Link Mobile</TableHeaderCell>
                  <TableHeaderCell align='center'>Order</TableHeaderCell>
                  <TableHeaderCell align='center'>Actions</TableHeaderCell>
                </TableRow>
              </TableHeadContainer>
              <DragDropContext onDragEnd={() => {}}>
                <Droppable droppableId='droppable' direction='vertical'>
                  {(droppableProvided: DroppableProvided) => (
                    <TableBody
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                    >
                      {shortcuts.map((shortcut: any, index: number) => (
                        <Draggable
                          key={shortcut.id}
                          draggableId={shortcut.id}
                          index={index}
                          isDragDisabled
                        >
                          {(
                            draggableProvided: DraggableProvided,
                            snapshot: DraggableStateSnapshot
                          ) => {
                            return (
                              <TableRow
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                style={{
                                  ...draggableProvided.draggableProps.style,
                                  background: snapshot.isDragging
                                    ? 'rgba(245,245,245, 0.75)'
                                    : 'none'
                                }}
                              >
                                <TableBodyCell
                                  component='th'
                                  scope='row'
                                  align='center'
                                >
                                  <div
                                    title={
                                      shortcut.name.length > 18
                                        ? shortcut.name
                                        : null
                                    }
                                  >
                                    {shortcut.name.length > 18
                                      ? shortcut.name.substring(0, 18) + '...'
                                      : shortcut.name}
                                  </div>
                                </TableBodyCell>
                                <TableBodyCell align='center'>
                                  <div
                                    title={
                                      shortcut.display_name1.length > 14
                                        ? shortcut.display_name1
                                        : null
                                    }
                                  >
                                    {shortcut.display_name1.length > 14
                                      ? shortcut.display_name1.substring(
                                          0,
                                          14
                                        ) + '...'
                                      : shortcut.display_name1}
                                  </div>
                                </TableBodyCell>
                                <TableBodyCell align='center'>
                                  <div
                                    title={
                                      shortcut.display_name2.length > 18
                                        ? shortcut.display_name2
                                        : null
                                    }
                                  >
                                    {shortcut.display_name2.length > 18
                                      ? shortcut.display_name2.substring(
                                          0,
                                          18
                                        ) + '...'
                                      : shortcut.display_name2}
                                  </div>
                                </TableBodyCell>
                                <TableBodyCell align='center'>
                                  <FiImage
                                    width={48}
                                    height={48}
                                    onClick={() =>
                                      window.open(shortcut.desktop_image)
                                    }
                                    style={{ color: `${Theme.primary}` }}
                                  />
                                </TableBodyCell>
                                <TableBodyCell align='center'>
                                  <a
                                    onClick={() =>
                                      window.open(shortcut.mobile_image)
                                    }
                                    style={{ color: `${Theme.primary}` }}
                                  >
                                    <FiImage width={48} height={48} />
                                  </a>
                                </TableBodyCell>
                                <TableBodyCell align='center'>
                                  <div title={shortcut.link_desktop}>
                                    {shortcut.link_desktop.length > 10
                                      ? shortcut.link_desktop.substring(0, 10) +
                                        '...'
                                      : shortcut.link_desktop}
                                  </div>
                                </TableBodyCell>
                                <TableBodyCell align='center'>
                                  <div title={shortcut.link_mobile}>
                                    {shortcut.link_mobile.length > 10
                                      ? shortcut.link_mobile.substring(0, 10) +
                                        '...'
                                      : shortcut.link_mobile}
                                  </div>
                                </TableBodyCell>
                                <TableBodyCell align='center'>
                                  {shortcut.order}
                                </TableBodyCell>
                                <TableCell align='center'>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row'
                                    }}
                                  >
                                    <IconButton
                                      style={{
                                        display: permissions.delete
                                          ? ''
                                          : 'none'
                                      }}
                                      onClick={() =>
                                        handleActiveShortcut(
                                          shortcut.id,
                                          shortcut.order
                                        )
                                      }
                                      aria-label='active'
                                    >
                                      <ToggleOff />
                                    </IconButton>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          }}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default ShortcutManagement;
