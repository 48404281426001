import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import Loginbutton from '../LoginButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import sss from '../../images/Monograma.png';
import Notifications from '../Notifications/index';
import { FiMenu, FiSettings } from 'react-icons/fi';
import ProfileInfo from '../LoginButton/ProfileInfo';
import { Theme as ThemeColor } from 'src/styles/Theme';
import { AppContainer } from './styled';
import Monograma from '../../images/miniLogoFeg.png';
import { MdClose } from 'react-icons/md';

interface MyComponentProps {
  handleSidebarOpen: () => void;
  handleDrawerOpen: () => void;
  drawer: () => void;
  sidebarStatus: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block'
      }
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.white
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto'
      }
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    inputRoot: {
      color: 'inherit'
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch'
      }
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      }
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    }
  })
);
export const PrimarySearchAppBar: React.FC<MyComponentProps> = ({
  handleDrawerOpen,
  handleSidebarOpen,
  drawer,
  sidebarStatus
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [
    mobileMoreAnchorEl,
    setMobileMoreAnchorEl
  ] = React.useState<null | HTMLElement>(null);
  const [extended, setExtended] = React.useState(false);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    handleSidebarOpen();
    drawer();
  };

  const handleClickCloseSidebar = () => {
    handleSidebarOpen();
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label='show 11 new notifications' color='inherit'>
          <Badge badgeContent={11} color='secondary'>
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label='account of current user'
          aria-controls='primary-search-account-menu'
          aria-haspopup='true'
          color='inherit'
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  function openNav() {
    setExtended(true);
  }

  function closeNav() {
    setExtended(false);
  }

  return (
    <>
      <div style={{ marginBottom: 70 }}></div>
      <AppContainer>
        <div className='app-bar'>
          <img
            id='monograma'
            src={Monograma}
            alt='FEG LOGO'
            width={35}
            style={{ marginRight: 25 }}
          />
          <IconButton
            edge='start'
            className={classes.menuButton}
            color='inherit'
            aria-label='open drawer'
            onClick={handleClick}
            style={{ color: `${ThemeColor.primary}` }}
          >
            {sidebarStatus ? <MdClose size={30} /> : <FiMenu size={30} />}
          </IconButton>
          <Typography className={classes.title} variant='h6' noWrap>
            Feg Home
          </Typography>

          <div
            className={classes.grow}
            style={{ display: 'flex', alignItems: 'center' }}
          />
          <div id='config'>
            <Notifications
              color={`${ThemeColor.primary}`}
              size={20}
              title='Notifications'
              handleClickCloseSidebar={handleClickCloseSidebar}
            />
          </div>
          <div
            id='LoNav'
            className='sidenav'
            style={{ width: extended ? '100%' : '0' }}
          >
            <span
              className='closebtn'
              onClick={() => {
                handleClickCloseSidebar();
                closeNav();
              }}
            >
              &times;
            </span>
            <h1>User Profile</h1>
            <ProfileInfo />
          </div>

          <button
            onClick={() => {
              handleClickCloseSidebar();
              openNav();
            }}
            type='button'
            style={{
              background: `${ThemeColor.light}`,
              border: 'none',
              cursor: 'pointer'
            }}
          >
            <FiSettings size={25} color={`${ThemeColor.primary}`} />
          </button>
        </div>
      </AppContainer>
    </>
  );
};
