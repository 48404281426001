import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  MenuItem,
  Input
} from '@material-ui/core';

import styled from 'styled-components';
import { Theme } from 'src/styles/Theme';
import { useHistory, useParams } from 'react-router-dom';
import BasicMenu from '../UsersManagement/menu';
import { MenuStyle } from '../UsersManagement/styled';
import { FiToggleLeft } from 'react-icons/fi';
import { AiFillDelete } from 'react-icons/ai';
import { MdEdit, MdChangeHistory } from 'react-icons/md';
import { BeatLoader } from 'react-spinners';
import { FaExchangeAlt } from 'react-icons/fa';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from 'src/services/axios';
import TableX from './table';

const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Modal = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  background-color: #9f9f9fcc;
  div.content {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 500px;
    margin: 0 auto;
    background-color: #fff;
    height: 300px;
    border-radius: 14px;
    margin-top: 80px;
    padding: 0;
    .title {
      width: 100%;
      background-color: ${Theme.primary};
      color: white;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      padding: 10px;
    }
    p {
      margin: 10px;
      margin-top: 40px;
    }
    div.buttons-container {
      gap: 10px;

      padding: 10px;

      button {
        border: none;
        padding: 10px;
        cursor: pointer;
      }
      .closed {
        background-color: ${Theme.primary};
        color: white;
      }
      .delete {
        margin-left: 20px;
        background-color: red;
        color: white;
      }
    }
  }
`;
const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  color: ${Theme.primary};
  div.title-container {
    display: flex;
    button {
      border: none;
      background: none;
      cursor: pointer;
      margin-left: 6px;
    }
  }
  button.return {
    border: none;
    background-color: ${Theme.primary};
    color: ${Theme.secondary};
    cursor: pointer;
    padding: 12px;
    cursor: pointer;
    border-radius: 5px;
  }
`;

interface Category {
  id: string;
  name: string;
  slug: string;
  filename: string;
  path: string;
  url: string;
  created_at: string;
}

const TableCategories = () => {
  const { idParam } = useParams();
  const [category, setCategory] = useState<Category>();
  const [dataCategory, setDataCategory] = useState<Category[]>([]);
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [openModalRename, setOpenModalRename] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalNewRenameId, setModalNewRenameId] = useState(0);
  const [renameText, setRenameText] = useState('');
  const superAdmin = localStorage.getItem('group_id') === "128e5e58-3c0a-4e63-9be1-d0b6fc2f871e";
  useEffect(() => {
    setReload(false);
    api
      .get(`https://openai.fegsystem.com/categories/${idParam}`, { timeout: 0 })
      .then((r) => {
        setCategory(r.data);
        setLoading(false);
        api
          .get(`https://openai.fegsystem.com/documents?per_page=1000`, {
            params: { category_id: r.data.id },
            timeout: 0
          })
          .then((r) => {
            setDataCategory(r.data.data);
          });
      });
  }, [reload]);
  function deleteCategory() {
    api
      .delete(`https://openai.fegsystem.com/categories/${category?.id}`)
      .then((r) => {
        toast.success('delete category');
        setOpenModal(false);
        history.push('/repository');
      });
  }

  function deleteDocument(id: string) {
    api.delete(`https://openai.fegsystem.com/documents/${id}`).then((r) => {
      toast.success('delete document');
      setReload(true);
    });
  }
  function downloadDocument(id: string) {
    api
      .get(`https://openai.fegsystem.com/documents/${id}/download`)
      .then((r) => {
        toast.success('donwloa document');
      });
  }

  const columns = [
    {
      id: 'idUnique', // add unique id here
      Header: 'Id',
      accessor: 'id'
    },
    {
      Header: 'Filename',
      accessor: 'filename'
    },
    {
      Header: 'Url',
      accessor: 'url',
      // eslint-disable-next-line react/display-name, react/prop-types, @typescript-eslint/no-unused-vars
      Cell: ({ cell: { value } }: { cell: { value: string } }) => {
        return (
          <>
            <a href={value} target='_blank'>
              {value}
            </a>
          </>
        );
      }
    },
    {
      Header: 'Date',
      accessor: 'created_at'
    },
    {
      id: 'func', // add unique id here
      Header: 'Actions',
      accessor: 'id',
      // eslint-disable-next-line react/display-name, react/prop-types, @typescript-eslint/no-unused-vars
      Cell: ({ cell: { value } }: { cell: { value: string } }) => {
        return (
          <>
             {
                superAdmin && (
                  <BasicMenu>
           
                  <Divider />
                  <MenuItem>
                    <MenuStyle>
                      <button
                        title='Active/Disable User'
                        onClick={() => deleteDocument(value)}
                      >
                        <RiDeleteBin5Line color={`${Theme.primary}`} size={20} />
                        <p>Delete Document</p>
                      </button>
                    </MenuStyle>
                  </MenuItem>
                  <Divider />
                  <MenuItem>
                    <MenuStyle>
                      <button
                        title='Active/Disable User'
                        onClick={() => {
                          setModalNewRenameId(Number(value));
                          setOpenModalRename(true);
                        }}
                      >
                        <FaExchangeAlt color={`${Theme.primary}`} size={20} />
                        <p>Rename Document</p>
                      </button>
                    </MenuStyle>
                  </MenuItem>
                  <Divider />
                </BasicMenu>
                )
              }
       
          </>
        );
      }
    }
  ];

  function handleRename() {
    api
      .put(`https://openai.fegsystem.com/documents/${modalNewRenameId}`, {
        rename: renameText
      })
      .then((r) => {
        toast.success('rename document');
        setOpenModalRename(false);
        setReload(true);
        setRenameText('');
      })
      .catch((e) => console.log(e));
  }

  return (
    <Container>
      <ToastContainer />
      {openModal && (
        <Modal>
          <div className='content'>
            <div className='title'>
              <h1>{category?.name}</h1>
            </div>
            <p>
              Before you hit the {"'delete'"} button, remember that this action
              might be irreversible. Double-check that {"you're"} deleting the
              correct item and consider making a backup. Proceed with caution.
            </p>

            <div className='buttons-container'>
              <button className='closed' onClick={() => setOpenModal(false)}>
                Closed
              </button>
              {superAdmin && (
  <button className='delete' onClick={() => deleteCategory()}>
  Delete
</button>
              )}
            
            </div>
          </div>
        </Modal>
      )}
      {openModalRename && (
        <Modal>
          <div className='content'>
            <div className='title'>
              <h1>Rename Document</h1>
            </div>
            <p>
              Please enter the new name for your document below. After
              submitting, allow a few moments for the system to reupload the
              file to our Ask FEG database.
            </p>

            <Input
              placeholder='Enter rename'
              onChange={(e) => setRenameText(e.target.value)}
              value={renameText}
            />
            <div className='buttons-container'>
              <button
                className='delete'
                style={{ marginRight: 20 }}
                onClick={() => setOpenModalRename(false)}
              >
                Cancel
              </button>
              <button
                className='closed'
                onClick={() => {
                  handleRename();
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      )}

      <Header>
        <div className='title-container'>
          <h1>{category?.name}</h1>
          {superAdmin && (
              <button onClick={() => setOpenModal(true)}>
              <AiFillDelete color={`#909090`} size={30} />
            </button>
            )}
        
        </div>

        <button className='return' onClick={() => history.push('/repository')}>
          Return
        </button>
      </Header>

      <TableX columns={columns} data={dataCategory} />
      {loading && (
        <BeatLoader
          style={{ marginTop: 20 }}
          size={25}
          color={`${Theme.primary}`}
        />
      )}
    </Container>
  );
};

export default TableCategories;
