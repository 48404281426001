import React, { useEffect, useRef, useState } from 'react';
import axios from '../../services/axios';
import Monograma from '../../images/Monograma.png';
import Loader from 'src/components/Loader';
import Formatter from 'src/components/CompanyAnnouncements/Formatter';
import { useCookies } from 'react-cookie';

const MyProfile: React.FC = () => {
  const owner_id = localStorage.getItem('owner_id');
  const [userData, setUserData] = useState<any>({});
  const imageFileInput = useRef<any>(null);
  const [loaded, setLoaded] = useState<boolean>(true);
  const [activities, setActivities] = useState([]);
  const [imageFile, setImageFile] = useState({
    selectedFile: '',
    type: ''
  });
  const [, setCookie] = useCookies(['token']);

  const fileHandler = () => {
    imageFileInput.current.click();
  };

  const fileSelect = (props: any) => {
    if (props[0] === undefined) {
      setImageFile({
        selectedFile: '',
        type: ''
      });
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(props[0]);

    reader.onload = (e: any) => {
      setImageFile({
        selectedFile: e?.target?.result,
        type: props[0].type
      });
    };

    setUserData({ ...userData, url_image: URL.createObjectURL(props[0]) });
  };

  const fetchData = async () => {
    setLoaded(true);
    const res = await axios.get(`/api/v1/accounts/${owner_id}`);
    setUserData(res.data.results);
    const sortedActivities: any = res.data.results.activities.sort(function (
      a: any,
      b: any
    ) {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });
    setActivities(sortedActivities.reverse().slice(0, 10));
    setLoaded(false);
  };

  const filterActivities = (dateLimit: string) => {
    setLoaded(true);
    const sortedActivities: any = userData.activities.sort(function (
      a: any,
      b: any
    ) {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });
    const today = new Date();

    if (dateLimit === 'week') {
      setTimeout(() => {
        const dater = sortedActivities.filter(
          (activity: any) =>
            new Date(activity.createdAt).getTime() >
            new Date().setDate(today.getDate() - today.getDay() - 7)
        );
        setActivities(dater.reverse());
        setLoaded(false);
      }, 800);
    }

    if (dateLimit === 'month') {
      setTimeout(() => {
        const dater = sortedActivities.filter(
          (activity: any) =>
            new Date(activity.createdAt).getTime() >
            new Date().setDate(today.getDate() - today.getDay() - 30)
        );
        setActivities(dater.reverse());
        setLoaded(false);
      }, 800);
    }

    if (dateLimit === 'months') {
      setTimeout(() => {
        const dater = sortedActivities.filter(
          (activity: any) =>
            new Date(activity.createdAt).getTime() >
            new Date().setDate(today.getDate() - today.getDay() - 30 * 3)
        );
        setActivities(dater.reverse());
        setLoaded(false);
      }, 800);
    }
  };

  const submitProfileUpdates = async () => {
    if (userData.first_name === '') return alert("First name can't be blank.");
    if (userData.last_name === '') return alert("Last name can't be blank.");
    if (userData.phone_number === '')
      return alert("Phone number can't be blank.");

    const data = {
      first_name: userData.first_name,
      last_name: userData.last_name,
      phone_number: userData.phone_number,
      image: imageFile
    };

    try {
      setLoaded(true);
      const res = await axios.put(`/api/v1/accounts/${owner_id}`, data);
      await setCookie('token', res.data.token);
      localStorage.setItem('token', res.data.token);
      window.location.reload();
    } catch (error) {
      alert(
        'Failed updating your profile. Please, reload the page and try again'
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return loaded ? (
    <Loader />
  ) : (
    <div className='profileContainer'>
      <button
        style={{
          backgroundImage: `url(${
            userData.url_image ? userData.url_image : Monograma
          })`,
          backgroundSize: 'cover'
        }}
        onClick={fileHandler}
        className='profileImage'
      ></button>
      <input
        ref={imageFileInput}
        className='profileImageInput'
        type='file'
        accept='image/*'
        onChange={(event) => fileSelect(event.target.files)}
      />
      <p className='profileTitle'>My profile</p>
      <div className='profileInfo'>
        <div className='profileRow'>
          <label htmlFor='firstName'>
            First name:
            <input
              type='text'
              name='firstName'
              id='firstName'
              defaultValue={userData.first_name}
              onChange={(event) =>
                setUserData({ ...userData, first_name: event.target.value })
              }
            />
          </label>
          <label htmlFor='lastName'>
            Last name:
            <input
              type='text'
              name='lastName'
              id='lastName'
              defaultValue={userData.last_name}
              onChange={(event) =>
                setUserData({ ...userData, last_name: event.target.value })
              }
            />
          </label>
          <label htmlFor='email'>
            Email:
            <input
              type='email'
              name='email'
              id='email'
              defaultValue={userData.email}
              disabled
            />
          </label>
        </div>
        <div className='profileRow'>
          <label htmlFor='locationName'>
            Location name:
            <input
              type='text'
              name='locationName'
              id='locationName'
              defaultValue={userData.location_name}
              disabled
            />
          </label>
          <label htmlFor='phoneNumber'>
            Phone number:
            <input
              type='text'
              name='phoneNumber'
              id='phoneNumber'
              defaultValue={userData.phone_number}
              onChange={(event) =>
                setUserData({ ...userData, phone_number: event.target.value })
              }
            />
          </label>
          <label htmlFor='createdAt'>
            User created at:
            <p>
              {userData?.createdAt.split('').splice(0, 10).join('')}&nbsp;
              {Formatter(
                userData?.createdAt.split('').splice(11, 2).join(''),
                userData?.createdAt.split('').splice(13, 3).join('')
              )}
            </p>
          </label>
          <label htmlFor='lastLogin'>
            Last updated at:
            <p>
              {userData?.updatedAt.split('').splice(0, 10).join('')}&nbsp;
              {Formatter(
                userData?.updatedAt.split('').splice(11, 2).join(''),
                userData?.updatedAt.split('').splice(13, 3).join('')
              )}
            </p>
          </label>
        </div>
        <div className='profileRow'>
          <label htmlFor='address'>
            Address:
            <input
              type='text'
              name='address'
              id='address'
              defaultValue={userData.addresses.full}
              disabled
            />
          </label>
        </div>
        <div className='profileRowSubmit'>
          <button onClick={() => submitProfileUpdates()}>
            Update your profile
          </button>
        </div>
      </div>
      <p className='profileTitle'>Your last activities</p>
      <div className='profileInfo'>
        <div className='profileRowFilter'>
          <p className='dateFilter' onClick={() => filterActivities('months')}>
            Three months
          </p>
          <p className='dateFilter' onClick={() => filterActivities('month')}>
            One month
          </p>
          <p className='dateFilter' onClick={() => filterActivities('week')}>
            A week
          </p>
          <p style={{ fontWeight: 'bold' }}>Filter by:</p>
        </div>
      </div>
      <div className='profileActivitiesInfo'>
        {activities.length >= 1 ? (
          activities.map((activity: any, key: any) => {
            if (activity.announcement_created) {
              return (
                <div className='profileActivitiesRow' key={key}>
                  <p>
                    <b>{activity.createdAt}</b> You <b>created</b> an{' '}
                    <u>announcement</u>.
                  </p>
                </div>
              );
            }
            if (activity.announcement_benefits_created) {
              return (
                <div className='profileActivitiesRow' key={key}>
                  <p>
                    <b>{activity.createdAt}</b> You <b>created</b> a{' '}
                    <u>benefits target announcement</u>.
                  </p>
                </div>
              );
            }
            if (activity.event_created) {
              return (
                <div className='profileActivitiesRow' key={key}>
                  <p>
                    <b>
                      {new Date(activity.createdAt)
                        .toString()
                        .split('')
                        .splice(4, 17)
                        .join('')}
                    </b>{' '}
                    You <b>created</b> an <u>event</u>.
                  </p>
                </div>
              );
            }
            if (activity.event_commented) {
              return (
                <div className='profileActivitiesRow' key={key}>
                  <p>
                    <b>
                      {new Date(activity.createdAt)
                        .toString()
                        .split('')
                        .splice(4, 17)
                        .join('')}
                    </b>{' '}
                    You made a <b>comment</b> on a <u>special event</u>.
                  </p>
                </div>
              );
            }
          })
        ) : (
          <p className='profileTitle' style={{ color: 'black' }}>
            You haven&apos;t done any activities so far.
          </p>
        )}
      </div>
    </div>
  );
};

export default MyProfile;
