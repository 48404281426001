import React, {useEffect} from 'react';
import ReactGA from 'react-ga';
import Management from '../../components/UsersManagement';

const UsersManagement: React.FC = () => {

    useEffect(() => {
        ReactGA.initialize('UA-189380132-1')
        ReactGA.pageview(window.location.pathname + window.location.search)
      })

    return (
            <Management />
    )
}

export default UsersManagement;
