/* eslint-disable react/prop-types */
import { Button, Input, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import axios from '../../services/axios';
import { Md5 } from 'md5-typescript';

interface Props {
  setPasswordExpired: (value: boolean) => void;
}

const PasswordExpired: React.FC<Props> = ({ setPasswordExpired }) => {
  const [password, setPassword] = useState('');
  const [repetPassword, setRepetPassword] = useState('');
  const [message, setMessage] = useState('');

  function acceptTerm() {
    if (password !== repetPassword) {
      return setMessage('Passwords don’t match. Please try again');
    }
    if (password.length < 8) {
      return setMessage('Password must be at least 8 characters');
    }
    const customer_id = localStorage.getItem('id');
    //set  setPasswordExpired: (value: boolean) => void;
    axios
      .post('/api/v1/accounts/change/password_temporary', {
        id: customer_id,
        password: Md5.init(password)
      })
      .then((res) => setPasswordExpired(false))
      .catch((e) => console.log(e));
  }

  return (
    <div className='tooltip' style={{ padding: 48 }}>
      <h2>Update Password</h2>
      <h4 style={{ color: '#454545', margin: 8 }}>
        You must update your password to move forward
      </h4>
      <TextField
        id='standard-basic'
        label='Password'
        variant='standard'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        type='password'
      />
      <TextField
        id='standard-basic'
        label='Repeat Password'
        value={repetPassword}
        onChange={(e) => setRepetPassword(e.target.value)}
        variant='standard'
        type='password'
      />
      <div style={{ marginTop: 12 }}>
        <p style={{ color: 'rgb(223, 71, 89)' }}>{message}</p>
      </div>

      <div style={{ display: 'flex', gap: 12, marginTop: 22 }}>
        <Button variant='contained' color='primary' onClick={acceptTerm}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default PasswordExpired;
