import React, { useState } from 'react';
import Announcement from '../../components/NewAnnouncement/index';
import Paylocity from '../../components/NewAnnouncement/paylocity';
import Benefits from '../../components/NewAnnouncement/benefits';
import { Theme } from '../../styles/Theme';
import LatestNews from '../../components/LatestNews';

const Announcements: React.FC = () => {
  // const [options, setOptions] = useState(true)
  const [btn, setBtn] = useState({
    content: 1,
    btnCompany: Theme.primary,
    btnLocal: '',
    btnBenefits: '',
    btnLatestNews: ''
  });

  function changeContent() {
    if (btn.content === 1) {
      return <Announcement />;
    }
    if (btn.content === 2) {
      return <Paylocity />;
    }
    if (btn.content === 3) {
      return <Benefits />;
    }
    if (btn.content === 4) {
      return <LatestNews />;
    }
  }

  return (
    <div className='announcement-types'>
      <div className='types-sidebar'>
        <button
          className='btn-custom'
          style={{
            backgroundColor: btn.btnLatestNews,
            color: btn.btnLatestNews ? 'white' : ''
          }}
          onClick={() =>
            setBtn({
              content: 4,
              btnCompany: '',
              btnLocal: '',
              btnLatestNews: Theme.primary,
              btnBenefits: ''
            })
          }
        >
          Latest News
        </button>
        <button
          className='btn-custom'
          style={{
            backgroundColor: btn.btnCompany,
            color: btn.btnCompany ? 'white' : ''
          }}
          onClick={() =>
            setBtn({
              content: 1,
              btnCompany: Theme.primary,
              btnLocal: '',
              btnBenefits: '',
              btnLatestNews: ''
            })
          }
        >
          FEG Home Users
        </button>
        <button
          className='btn-custom'
          style={{
            backgroundColor: btn.btnLocal,
            color: btn.btnLocal ? 'white' : ''
          }}
          onClick={() =>
            setBtn({
              content: 2,
              btnCompany: '',
              btnLocal: Theme.primary,
              btnBenefits: '',
              btnLatestNews: ''
            })
          }
        >
          All Paylocity Contacts
        </button>
        <button
          className='btn-custom'
          style={{
            backgroundColor: btn.btnBenefits,
            color: btn.btnBenefits ? 'white' : ''
          }}
          onClick={() =>
            setBtn({
              content: 3,
              btnCompany: '',
              btnLocal: '',
              btnBenefits: Theme.primary,
              btnLatestNews: ''
            })
          }
        >
          Benefits Eligible Group
        </button>
      </div>
      <div className='types-content'>{changeContent()}</div>
    </div>
  );
};

export default Announcements;
