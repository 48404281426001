import { Theme } from 'src/styles/Theme';
import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 60px;
  left: 125px;
  background-color: white;
  display: flex;
  padding: 14px;
  flex-direction: column;
  width: 200px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
  z-index: 1000;
  svg {
    color: ${Theme.primary} !important;
  }

  div {
    display: flex;
    align-items: center;
    icon {
      color: black !important;
    }
    justify-content: space-between;
    padding: 6px 0;
    border-bottom: 1px solid #ccc;
    :first-of-type {
      border-top: 1px solid #ccc;
    }
  }
`;
