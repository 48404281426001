import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  border-radius: 8px;
  max-width: 500px;
  margin-left: 52px;
  div.title {
    display: flex;
    margin: 10px 18px;
    justify-content: space-between;
    align-items: center;
    button {
      cursor: pointer;
      border: none;
      border-radius: 50%;
      height: 30px;
      width: 30px;
    }
  }
  div.sources {
    margin: 18px;
    gap: 18px;
    display: flex;
    flex-direction: column;
    a {
      padding: 10px;
      background-color: #f5f5f5;
      border-radius: 8px;
    }
  }
`;
