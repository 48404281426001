import React, { useState, ReactNode } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Monograma from '../../images/Monograma.png';
import { Theme } from 'src/styles/Theme';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  div.trt {
    transition: 0.5s;
    opacity: 0;
  }
  background-color: ${Theme.grey45};
  color: ${Theme.grey};
  padding: 12px 14px;
  width: 80%;
  margin: 0 auto;
  transition: 0.5s;
  :hover {
    transition: 0.2s;
    cursor: pointer;
  }
  p.title {
    font-size: 18;
  }
  hr {
    color: white;
    height: 0.5px;
  }
`;
const ContainerBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
interface DrawerProps {
  open: boolean;
  title: string;
  setOpen: () => void;
  children: React.ReactNode;
}

const App: React.FC<DrawerProps> = ({
  open,
  title,
  setOpen,
  children
}: DrawerProps) => {
  return (
    <Container>
      <ContainerBar onClick={() => setOpen()}>
        <p className='title'>{title}</p>

        {open ? <FiChevronDown size={25} /> : <FiChevronUp size={25} />}
      </ContainerBar>
      <div
        className='trt'
        style={{
          opacity: `${open ? 1 : 0}`
        }}
      >
        {open && children}
      </div>
    </Container>
  );
};

export default App;
