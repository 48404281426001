// Se estiver usando Node.js
const moment = require('moment');

// Se estiver usando no navegador, o moment já estará disponível globalmente

export function semanasOuDiasPassados(dataStr: string) {
    // Converte a string de data para um objeto Moment com timezone UTC
    const data = moment(dataStr).utc();
    
    // Obtém a data e hora atual com timezone UTC
    const agora = moment().utc();
    
    // Calcula a diferença em dias
    const diferencaDias = agora.diff(data, 'days');
    
    // Verifica se a diferença é menor que 7 dias
    if (diferencaDias === 0) {
        return `Today`;
    }else if (diferencaDias < 7) {
        return `(${diferencaDias} days ago)`;
    } else if(diferencaDias > 7 && diferencaDias < 14){
        // Calcula a diferença em semanas
        const semanas = agora.diff(data, 'weeks');
        return `(${semanas} week ago)`;
    }
    else {
        // Calcula a diferença em semanas
        const semanas = agora.diff(data, 'weeks');
        return `(${semanas} weeks ago)`;
    }
}

// Exemplo de uso

