export function formaterTextSlug(texto: string) {
  // Remove acentos
  texto = texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  // Substitui espaços por hífens
  texto = texto.replace(/\s+/g, '-');

  // Converte para caixa baixa (minúsculas)
  texto = texto.toLowerCase();

  return texto;
}
