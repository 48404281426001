import styled from 'styled-components';

export const MenuStyle = styled.div`
  display: flex;
  button {
    color: none;
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    :hover {
      background-color: '#000';
    }
  }
`;
