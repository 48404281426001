import React, { useEffect, useState } from 'react';
import api from 'src/services/axios';
import { Link } from 'react-router-dom';
import Formatter from '../CompanyAnnouncements/Formatter';

import { FiTrash } from 'react-icons/fi';

export default function BenefitsComp(): React.ReactElement {
  const [annoucements, setAnnoucements] = useState<any>([]);
  function handleGetAnnoucements() {
    api.get('/api/v1/announcements/ab/all').then((r) => {
      setAnnoucements(r.data);
    });
  }

  useEffect(() => {
    handleGetAnnoucements();
  }, []);
  function handleDelete(id: String) {
    api.delete(`/api/v1/announcements/ab/delete/${id}`).then((r) => {
      handleGetAnnoucements();
      window.alert('success');
    });
  }

  return (
    <div className='company-announcements'>
      {annoucements.map((annoucment: any) => {
        return (
          <div className='announcements-card' key={Math.random()}>
            <div className='announcement-options'>
              <Link to={`/ab/${annoucment.id}`}>
                <h1>{annoucment.subject}</h1>
              </Link>
              <button>
                <FiTrash
                  size={22}
                  onClick={() => handleDelete(annoucment.id)}
                />
              </button>
            </div>
            <p>
              {annoucment.createdAt.split('').splice(0, 11).join('')}{' '}
              {Formatter(
                annoucment.createdAt.split('').splice(12, 2).join(''),
                annoucment.createdAt.split('').splice(14, 3).join('')
              )}
            </p>
          </div>
        );
      })}
    </div>
  );
}
