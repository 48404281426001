import { Pagination } from "@material-ui/lab";
import React from "react";


export interface PropsMetaData {
  first: number;
  before: number;
  current: number;
  next: number;
  last: number;
}
export interface Props {
  meta: PropsMetaData;
  setState: (prop : number) => void;
}

export function PaginationComp ({meta, setState}: Props): React.ReactElement {



    return (
     <Pagination count={meta.last} onChange={(e, p) => setState(p)} />
    )
}