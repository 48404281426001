import React from 'react';
import { CardContainer } from './styled';

export const CardTask = (props: any): JSX.Element => {
  return (
    <CardContainer>
      <div className='title'>
        <h1>Daily Trivia</h1>
      </div>
      <div className='to-dos'>
        <div className='to-do'>
          <p className='legend'>Coming Soon!</p>
          <button className='start'>Start</button>
        </div>
        <div className='to-do'>
          <p className='legend'>Coming Soon!</p>
          <button className='loading'>Loading</button>
        </div>
        <div className='to-do'>
          <p className='legend'>Coming Soon!</p>
          <button className='stop'>Stop</button>
        </div>

        <div className='view'>
          <p>View All</p>
        </div>
      </div>
    </CardContainer>
  );
};
