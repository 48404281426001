import styled from 'styled-components';
import { Theme } from '../../styles/Theme';

export const MapJobtitle = styled.div`
  width: 80%;
  align-items: center;
  margin: 30px auto;
  div.content-groups {
    display: flex;
  }
  button {
    margin: 10px;
  }
  div.div-content {
    margin: 0 auto;
    max-width: 900px;
    width: 100%;
    background-color: white;
    min-height: 800px;
    border-radius: 16px;
    @media (max-width: 1200px) {
      width: auto;
    }
    .title {
      display: flex;
      margin: 10px;
      justify-content: space-between;
      align-items: center;
    }

    h1 {
      color: ${Theme.primary};
      margin: 12px;
    }
    hr {
      color: ${Theme.primary};
      margin-top: 6px;
    }
    .groups-content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: space-between;
      align-items: center;
      margin: 10px 60px;
      @media (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
      }
      .MuiFormControl-root {
        width: 100%;
        max-width: 200px;
      }
      select {
        width: 400px;
      }
    }
  }
`;
