import React, { useMemo } from 'react';
import { useTable, useGlobalFilter } from 'react-table';
import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, react/prop-types

const Container = styled.div`
  /* Estilizando a tabela */
  width: 100%;
  input {
    padding: 6px;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    margin: 20px 0;
    font-family: Arial, sans-serif;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  /* Estilizando o cabeçalho da coluna */
  th {
    background-color: #f2f2f2;
    color: #333;
    font-weight: bold;
    text-align: left;
    padding: 10px;
  }

  /* Estilizando as células da tabela */
  td {
    padding: 10px;
    text-align: left;
  }

  /* Estilizando as linhas alternadas para melhor legibilidade */
  tr:nth-child(even) {
    background-color: #f5f5f5;
  }

  /* Estilizando as linhas ao passar o mouse */
  tr:hover {
    background-color: #e0e0e0;
  }
`;

const TableX = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { globalFilter },
    setGlobalFilter
  } = useTable(
    {
      columns,
      data
    },
    useGlobalFilter
  );

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
  };

  return (
    <Container>
      <input
        type='text'
        value={globalFilter || ''}
        onChange={handleFilterChange}
        placeholder='Filter...'
      />
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr key={Math.random()} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th key={Math.random()} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr key={Math.random()} {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td key={Math.random()} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Container>
  );
};

export default TableX;
