import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FiEdit, FiTrash, FiToggleLeft, FiUser } from 'react-icons/fi';
import { MdSyncDisabled } from 'react-icons/md';
import axios from '../../services/axios';
import { saveAs } from 'file-saver';
import { Md5 } from 'md5-typescript';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import { useCookies } from 'react-cookie';
import { groupsData } from '../../data';
import jwt from 'jsonwebtoken';
import Loader from '../../components/Loader';
import {
  Button,
  Checkbox,
  CheckboxProps,
  Dialog,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Tooltip,
  styled,
  withStyles,
  Menu,
  MenuItem,
  Divider
} from '@material-ui/core';
import excel from 'exceljs';
import BasicMenu from './menu';
import { MenuStyle } from './styled';
import { Theme } from 'src/styles/Theme';

const MyComponentDialog = styled('div')({
  color: 'darkslategray',
  backgroundColor: 'aliceblue',
  padding: 22,
  margin: 14,
  display: 'flex',
  gap: 10,
  borderRadius: 4
});

const GreenCheckbox = withStyles({
  root: {
    color: `${Theme.primary}`,
    '&$checked': {
      color: `${Theme.primary}`
    }
  },
  checked: {}
})((props: CheckboxProps) => <Checkbox color='default' {...props} />);

interface userInfo {
  company_name: string;
  createdAt: string;
  email: string;
  first_name: string;
  full_name: string;
  group_id: string;
  group_name: string;
  id: string;
  ip: string;
  last_name: string;
  location_id: string;
  location_name: string;
  password: string;
  phone_number: string;
  profile_id: string;
  profiles_name: string;
  status: string;
  updatedAt: string;
  url_image: string;
  permission_service_request_id: string;
  has_permission_service_request: boolean;
  card_related_ticket_permission: string;
  game_related_ticket_permission: string;
  user_status_value: string;
  password_is_expired: boolean;
}

interface usersArray extends Array<userInfo> {}

interface servicePermissions {
  card_related_ticket_permission: string | undefined;
  game_related_ticket_permission: string | undefined;
  has_permission_service_request: boolean | undefined;
}

interface profileData {
  value: string;
  label: string;
}

interface groupData {
  value: string;
  label: string;
  profile_id: string;
}

interface locationData {
  value: string;
  label: string;
}

const Management = () => {
  const [locationsData, setLocationsData] = useState<locationData[]>([]);
  const [profilesData, setProfilesData] = useState<
    { value: string; label: string }[]
  >([]);
  const [groupsData2, setGroupsData2] = useState<
    { value: string; label: string; profile_id: string }[]
  >([]);
  const [locationsFilter, setLocationsFilter] = useState<locationData[]>([]);
  const [profilesFilter, setProfilesFilter] = useState<profileData[]>([]);
  const [groupsFilter, setGroupsFilter] = useState<groupData[]>([]);
  const [isActive, setIsActive] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [cookies, setCookies, removeCookies] = useCookies(['token']);
  const token: any = cookies.token;
  const [users, setUsers] = useState<usersArray>([]);
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState<boolean[]>([]);

  const openDialog = (index: number) => {
    const newDialogOpen = [...dialogOpen];
    newDialogOpen[index] = true;
    setDialogOpen(newDialogOpen);
  };

  const closeDialog = (index: number) => {
    const newDialogOpen = [...dialogOpen];
    newDialogOpen[index] = false;
    setDialogOpen(newDialogOpen);
  };

  const [pages, setPages] = useState({
    limit: 5,
    nextPage: 'limit=5&offset=5',
    offset: 1,
    pageCount: 5,
    prevPage: '',
    total: '0'
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [modal, setModal] = useState(false);
  const [serviceRequest, setServiceRequest] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [switchConfirmation, setSwitchConfirmation] = useState(false);
  const [modalDate, setModalDate] = useState<any>();
  const [initialModalDate, setInitialModalDate] = useState<any>();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [location, setLocation] = useState('');
  const [profile, setProfile] = useState('');
  const [group, setGroup] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [permissions, setPermissions] = useState<boolean>();
  const [permissions2, setPermissions2] = useState({
    view: false,
    add: false,
    edit: false,
    delete: false
  });
  const [
    servicePermissions,
    setServicePermissions
  ] = useState<servicePermissions>();
  // {
  //     "has_permission_service_request": modalDate.has_permission_service_request,
  //     "card_related_ticket_permission": modalDate.card_related_ticket_permission,
  //     "game_related_ticket_permission": modalDate.game_related_ticket_permission,
  // }
  const [onSearch, setOnSearch] = useState<boolean>(false);
  const history = useHistory();
  const groupsDataFiltered = groupsData2.filter(
    (group) => group.profile_id === profile
  );
  useEffect(() => {
    axios.get(`/api/v1/locations`).then((locationsResponse) => {
      const formattedLocations: { value: string; label: string }[] = [];
      locationsResponse.data.forEach((locationRes: any) => {
        formattedLocations.push({
          value: locationRes.id,
          label: locationRes.display_name
        });
      });
      setLocationsData(formattedLocations);
    });

    axios.get(`/api/v1/profiles`).then((profilesResponse) => {
      const formattedProfiles: profileData[] = [];
      profilesResponse.data.forEach((profileRes: any) => {
        formattedProfiles.push({
          value: profileRes.id,
          label: profileRes.name
        });
      });
      setProfilesData(formattedProfiles);
    });

    axios.get(`api/v1/groups`).then((groupsResponse) => {
      console.log('g', groupsResponse.data);
      const formattedGroups: groupData[] = [];
      groupsResponse.data.forEach((groupRes: any) => {
        formattedGroups.push({
          value: groupRes.id,
          label: groupRes.name,
          profile_id: groupRes.profile_id
        });
      });
      setGroupsData2(formattedGroups);
    });
    // handleLoad()
    handlePermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function userExpiredPassword(id: string) {
    await axios
      .post('api/v1/accounts/recover_password_expired', { id })
      .then((r) => handleLoad())
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    const locations = locationsFilter.map((location) => location.value);
    const profiles = profilesFilter.map((profile) => profile.value);
    const groups = groupsFilter.map((group) => group.value);
    let data: any;

    if (
      locationsFilter?.length &&
      !profilesFilter?.length &&
      !groupsFilter?.length
    )
      data = { type: 'L', locations };
    if (
      !locationsFilter?.length &&
      profilesFilter?.length &&
      !groupsFilter?.length
    )
      data = { type: 'P', profiles };
    if (
      !locationsFilter?.length &&
      !profilesFilter?.length &&
      groupsFilter?.length
    )
      data = { type: 'G', groups };
    if (
      locationsFilter?.length &&
      profilesFilter?.length &&
      !groupsFilter?.length
    )
      data = { type: 'LP', locations, profiles };
    if (
      locationsFilter?.length &&
      !profilesFilter?.length &&
      groupsFilter?.length
    )
      data = { type: 'LG', locations, groups };
    if (
      !locationsFilter?.length &&
      profilesFilter?.length &&
      groupsFilter?.length
    )
      data = { type: 'PG', profiles, groups };
    if (
      locationsFilter?.length &&
      profilesFilter?.length &&
      groupsFilter?.length
    )
      data = { type: 'LPG', locations, profiles, groups };

    if (data || isActive || isExpired) handleFilterUsers(data);
    else handleLoad();
  }, [locationsFilter, profilesFilter, groupsFilter, isActive, isExpired]);

  async function handleFilterUsers(data: any) {
    try {
      const response: any = await axios.post(`/api/v1/accounts/filter/users`, {
        ...data,
        isActive,
        isExpired
      });
      setOnSearch(true);
      setUsers(response.data.results);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleLoad() {
    const response: any = await axios.get(`/api/v1/accounts/?limit=5&offset=0`);
    setUsers(response.data.results);
    setPages(response.data);
    setOnSearch(false);
    setLoading(false);
  }

  function handlePermissions() {
    if (localStorage.getItem('token')) {
      jwt.verify(
        localStorage.getItem('token')!.toString(),
        '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
        (err: any, decoded: any) => {
          if (err) {
            alert(err);
          } else {
            if (decoded.roles.user_management.view === false) {
              alert('Permission denied');
              if (process.env.REACT_APP_URL) {
                window.location.href = `${process.env.REACT_APP_URL}`;
              }
            } else {
              setPermissions(decoded.modules.users.write);
              setPermissions2(decoded.roles.user_management);
              handleLoad();
            }
          }
        }
      );
    } else {
      jwt.verify(
        token!.toString(),
        '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
        (err: any, decoded: any) => {
          if (err) {
            alert(err);
          } else {
            if (decoded.modules.users.read === false) {
              alert('Permission denied');
              if (process.env.REACT_APP_URL) {
                window.location.href = `${process.env.REACT_APP_URL}`;
              }
            } else {
              setPermissions(decoded.modules.users.write);
              setPermissions2(decoded.roles.user_management);
              handleLoad();
            }
          }
        }
      );
    }
  }

  async function handleSwitchPage(page: string) {
    setLoading(true);
    const response: any = await axios.get(`/api/v1/accounts/?${page}`);
    setUsers(response.data.results);
    setPages(response.data);
    setLoading(false);
  }

  async function handleDeleteUser(id: string) {
    try {
      const data = {
        status_name: 'blocked'
      };
      setModalLoading(true);
      await axios.put(`/api/v1/accounts/change_status/${id}`, data);
      setUsers(users?.filter((user) => user.id !== id));
      setConfirmation(false);
      alert('User deleted');
      setModalLoading(false);
    } catch (error) {
      alert(error);
      setModalLoading(false);
    }
  }

  async function handleImpersonate(id: string, status: string) {
    if (['INACTIVE', 'BLOCKED'].includes(status)) {
      return alert('Cannot impersonate Inactives users');
    }
    try {
      setModalLoading(true);
      let response = await axios.get(`/api/v1/accounts/impersonate/${id}`);
      // console.log(response.data);
      // localStorage.setItem('token', response.data.token);
      // removeCookies('token', {domain: 'fegsystem.com'}) // remove this line when update to production
      sessionStorage.setItem('originalToken', token);
      setCookies('token', response.data.token, { domain: '.systemfeg.com' });
      history.push('/');
      window.location.reload();
    } catch (error) {
      alert(error);
      setModalLoading(false);
    }
  }

  async function exportCSV(e: any) {
    const data = new Date().toISOString().split('').splice(5, 5).join('');
    if (e === 'excel') {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/accounts/export/${e}`, {
        method: 'GET'
      })
        .then(function (response) {
          return response.blob();
        })
        .then(function (blob) {
          saveAs(blob, `export${data}.xlsx`);
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/accounts/export/${e}`, {
        method: 'GET'
      })
        .then(function (response) {
          return response.blob();
        })
        .then(function (blob) {
          saveAs(blob, `export${data}.csv`);
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  function exportCSV2(type: string) {
    const date = new Date().toISOString().split('').splice(5, 5).join('');
    const info = users.map((user) => {
      return {
        First_name: user.first_name,
        Last_name: user.last_name,
        Location_name: user.location_name,
        Profiles_name: user.profiles_name,
        Group_name: user.group_name,
        Status: user.status,
        Email: user.email
      };
    });

    if (type === 'csv') {
      const csvString = [
        [
          'First_name',
          'Last_name',
          'Location_name',
          'Profiles_name',
          'Group_name',
          'Status',
          'Email'
        ],
        ...info.map((row) => [
          row.First_name,
          row.Last_name,
          row.Location_name,
          row.Profiles_name,
          row.Group_name,
          row.Status,
          row.Email
        ])
      ]
        .map((e) => e.join(','))
        .join('\n');
      const blob = new Blob([csvString]);
      saveAs(blob, `export${date}.csv`);
    } else if (type === 'excel') {
      const workbook = new excel.Workbook();
      const worksheet = workbook.addWorksheet('Report customers');
      worksheet.columns = [
        { header: 'First_name', key: 'first_name' },
        { header: 'Last_name', key: 'last_name' },
        { header: 'Location_name', key: 'location_name' },
        { header: 'Profiles_name', key: 'profiles_name' },
        { header: 'Group_name', key: 'group_name' },
        { header: 'Status', key: 'status' },
        { header: 'Email', key: 'email' }
      ];

      info.forEach((row) => {
        worksheet.addRow([
          row.First_name,
          row.Last_name,
          row.Location_name,
          row.Profiles_name,
          row.Group_name,
          row.Status,
          row.Email
        ]);
      });
      workbook.xlsx.writeBuffer().then((data) => {
        var blob = new Blob([data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        saveAs(blob, `export${date}.xlsx`);
      });
    }
  }

  function handleClearInputs() {
    setFirstName('');
    setLastName('');
    setEmail('');
    setLocation('');
    setProfile('');
    setGroup('');
    setPassword('');
  }

  async function handleUpdate(id: string) {
    if (firstName === '') {
      setFirstName(initialModalDate!.first_name);
    }
    if (lastName === '') {
      setLastName(initialModalDate!.lastName);
    }
    if (email === '') {
      setEmail(initialModalDate!.email);
    }
    if (location === '') {
      setLocation(initialModalDate!.location_id);
    }
    if (profile === '') {
      setProfile(initialModalDate!.profile_id);
    }
    if (group === '') {
      setGroup(initialModalDate!.group_id);
    }
    if (password) {
      if (password !== passwordConfirmation) {
        return alert(
          "Passwords don't match, please revisit them and try again."
        );
      } else if (password.length < 8) {
        return alert('Password must be at least 8 characters.');
      }
    }

    const data = {
      first_name: firstName ? firstName : initialModalDate.first_name,
      last_name: lastName ? lastName : initialModalDate.last_name,
      email: email ? email : initialModalDate.email,
      location_id: location ? location : initialModalDate.location_id,
      profile_id: profile ? profile : initialModalDate.profile_id,
      group_id: group ? group : initialModalDate.group_id,
      password: password ? Md5.init(password) : ''
    };
    try {
      setModalLoading(true);
      await axios.put(`/api/v1/accounts/${id}`, data);
      alert(`User updated`);
      setLoading(true);
      handleLoad();
      setModal(false);
      setModalLoading(false);
    } catch (error) {
      alert('Error updating user');
      setModalLoading(false);
    }
  }

  async function handleUpdateServiceRequest(id: string) {
    try {
      console.log(servicePermissions);
      setModalLoading(true);
      await axios.put(
        `/api/v1/accounts/${id}/permission-service-request`,
        servicePermissions
      );
      alert(`User updated`);
      setLoading(true);
      handleLoad();
      setModal(false);
      setModalLoading(false);
    } catch (error) {
      alert('Error updating user');
      setModalLoading(false);
    }
  }

  async function handleActived(
    user_status_value: string,
    id: string,
    group_id: string
  ) {
    if (user_status_value === 'ACTIVE') {
      const data = {
        status_name: 'inactive'
      };
      try {
        await axios.put(`/api/v1/accounts/change_status/${id}`, data);

        alert(`User inactivated`);
      } catch (error) {
        alert('Error inactivating user');
      }
      setLoading(true);
      handleLoad();
    } else {
      const data = {
        status_name: 'active',
        group_id
      };
      try {
        await axios.put(`/api/v1/accounts/change_status/${id}`, data);
        alert(`User activated`);
        setLoading(true);
        handleLoad();
      } catch (error) {
        alert('Error activating user');
      }
    }
  }

  async function handleSearchUser(value: string) {
    if (value.length < 1) {
      handleLoad();
    } else {
      try {
        const response: any = await axios.get(
          `/api/v1/accounts?filter=${value}`
        );
        setOnSearch(true);
        setUsers(response.data.results);
      } catch (error) {
        console.log(error);
      }
    }
  }

  function actualPage() {
    if (pages.offset === 1) {
      return 1;
    }
    if (pages.offset === pages.limit) {
      return 2;
    }
    if (pages.offset / pages.limit === 2) {
      return 3;
    }
    if (pages.offset !== pages.limit) {
      return pages.offset / pages.limit + 1;
    }
  }

  function parser(actived: string) {
    if (actived === 'ACTIVE') {
      return true;
    } else if (actived === 'INACTIVE') {
      return false;
    }
  }

  const handleSelectGroup = (event: any) => {
    setGroup(event.value);
  };

  const handleSelectLocation = (event: any) => {
    setLocation(event.value);
  };

  const handleSelectProfile = (event: any) => {
    setProfile(event.value);
  };

  window.onclick = function (event: any) {
    if (event.target.className === 'modal') {
      setModal(false);
      setConfirmation(false);
      setSwitchConfirmation(false);
    }
  };

  function handleLocationsFilter(locations: any) {
    if (locations) {
      setLocationsFilter(locations);
    } else setLocationsFilter([]);
  }
  function handleProfilesFilter(profiles: any) {
    if (profiles) setProfilesFilter(profiles);
    else setProfilesFilter([]);
  }
  function handleGroupsFilter(groups: any) {
    if (groups) setGroupsFilter(groups);
    else setGroupsFilter([]);
  }
  function handleIsActive(event: React.ChangeEvent<HTMLInputElement>) {
    setIsActive(event.target.checked);
  }
  function handleIsExpired(event: React.ChangeEvent<HTMLInputElement>) {
    setIsExpired(event.target.checked);
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openT = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  return loading ? (
    <Loader />
  ) : (
    <div className='management-content'>
      <div className='management-top'>
        <div className='top-left'>
          <h1>
            Users<b> {pages.total} total</b>
          </h1>
        </div>
      </div>

      <div className='users-management'>
        <div className='list-header'>
          <TextField
            // id="standard-basic"
            label='Search...'
            onChange={(e) => handleSearchUser(e.target.value)}
          />
          <h2>User Management</h2>
          <div className='export'>
            <select onChange={(e) => exportCSV2(e.target.value)} name=''>
              <option value=''>Export</option>
              <option value='csv'>CSV</option>
              <option value='excel'>Excel</option>
              {/* <option value="">Print</option> */}
            </select>
            {/* <button>New Record</button> */}
          </div>

          <div className='second-list-header'>
            <Select
              options={locationsData}
              value={locationsFilter}
              onChange={(locations) => {
                handleLocationsFilter(locations);
              }}
              placeholder='Location Filter'
              className='select-filter-user'
              isClearable
              isMulti
            />
            <Select
              options={profilesData}
              value={profilesFilter}
              onChange={(profiles) => {
                handleProfilesFilter(profiles);
              }}
              placeholder='Profiles Filter'
              className='select-filter-user'
              isClearable
              isMulti
            />
            <Select
              options={groupsData2}
              value={groupsFilter}
              onChange={(groups) => {
                handleGroupsFilter(groups);
              }}
              placeholder='Groups Filter'
              className='select-filter-user'
              isClearable
              isMulti
            />

            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={isActive}
                  onChange={handleIsActive}
                  name='checkedActive'
                />
              }
              label='Only Actives'
              className='align-vertical'
            />

            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={isExpired}
                  onChange={handleIsExpired}
                  name='checkedActive'
                />
              }
              label='Only Expireds'
              className='align-vertical'
            />
          </div>
        </div>

        <table className='users'>
          <thead>
            <tr>
              <th id='image'></th>
              <th>FIRST NAME</th>
              <th>LAST NAME</th>
              <th>EMAIL</th>
              <th>LOCATION</th>
              <th>PROFILES</th>
              <th>GROUP</th>
              <th>STATUS</th>
              <th style={{ display: permissions ? '' : 'none' }}>ACTIONS</th>
            </tr>
          </thead>

          <tbody>
            {users?.map((user, index) => (
              <tr key={user.id}>
                <td id='image'>
                  <img src={user.url_image} alt='user.png' />
                </td>
                <td>
                  <p className='label-mobile'>FIRST NAME:</p> {user.first_name}
                </td>
                <td>
                  <p className='label-mobile'>LAST NAME:</p> {user.last_name}
                </td>
                <td>
                  <p className='label-mobile'>EMAIL:</p> {user.email}
                </td>
                <td>
                  <p className='label-mobile'>LOCATION:</p> {user.location_name}
                </td>
                <td>
                  <p className='label-mobile'>PROFILE:</p> {user.profiles_name}
                </td>
                <td>
                  <p className='label-mobile'>GROUP:</p> {user.group_name}
                </td>
                <td>
                  <p className='label-mobile'>STATUS:</p>{' '}
                  <b
                    style={{
                      background: parser(user.user_status_value)
                        ? '#25ab9f'
                        : '#808080'
                    }}
                  >
                    {parser(user.user_status_value) ? 'ACTIVATED' : 'DISABLED'}
                  </b>
                </td>
                <td
                  style={{ display: permissions ? '' : 'none', width: '15%' }}
                >
                  <BasicMenu>
                    <Divider />
                    <MenuItem>
                      <MenuStyle>
                        <button
                          title='Active/Disable User'
                          style={{ display: permissions2.edit ? '' : 'none' }}
                          onClick={() => {
                            closeDialog(index);
                            setSwitchConfirmation(true);
                            setModalDate(user);
                          }}
                        >
                          <FiToggleLeft color={`${Theme.primary}`} size={20} />
                          <p>Disable User</p>
                        </button>
                      </MenuStyle>
                    </MenuItem>
                    <Divider />
                    <MenuItem className='menu-actions'>
                      <MenuStyle>
                        <button
                          title='Edit User'
                          style={{
                            display: permissions2.edit ? '' : 'none',
                            border: 'none'
                          }}
                          onClick={() => {
                            setModal(true);
                            closeDialog(index);
                            setModalDate(user);
                            setInitialModalDate(user);
                            console.log(user);
                            handleClearInputs();
                          }}
                        >
                          <FiEdit size={20} color={`${Theme.primary}`} />
                          <p>Edit User</p>
                        </button>
                      </MenuStyle>
                    </MenuItem>
                    <Divider />
                    <MenuItem>
                      <MenuStyle>
                        <button
                          title='Delete User'
                          style={{ display: permissions2.delete ? '' : 'none' }}
                          onClick={() => {
                            closeDialog(index);
                            setConfirmation(true);
                            setModalDate(user.id);
                          }}
                        >
                          <FiTrash color={`${Theme.primary}`} size={20} />
                          <p>Delete User</p>
                        </button>
                      </MenuStyle>
                    </MenuItem>
                    <Divider />
                    <MenuItem>
                      <MenuStyle>
                        <button
                          title='Impersonate'
                          style={{ display: permissions2.edit ? '' : 'none' }}
                          onClick={() => {
                            closeDialog(index);
                            handleImpersonate(user.id, user.user_status_value);
                          }}
                        >
                          <FiUser color={`${Theme.primary}`} size={20} />
                          <p>Impersonate</p>
                        </button>
                      </MenuStyle>
                    </MenuItem>
                    <Divider />

                    {user.password_is_expired && (
                      <>
                        <MenuItem>
                          <MenuStyle>
                            <button
                              title='Expired Account'
                              onClick={() => {
                                closeDialog(index);
                                userExpiredPassword(user.id);
                              }}
                            >
                              {' '}
                              <MdSyncDisabled color={`${Theme.primary}`} size={20} />
                              <p>Resend Password (Expired) </p>
                            </button>
                          </MenuStyle>
                        </MenuItem>
                        <Divider />
                      </>
                    )}
                  </BasicMenu>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='pagination' style={{ display: onSearch ? 'none' : '' }}>
          <button
            disabled={pages.prevPage === ''}
            onClick={() => {
              handleSwitchPage(pages.prevPage);
            }}
          >
            Prev Page
          </button>
          <h2>Page: {actualPage()}</h2>
          <button
            disabled={pages.nextPage === ''}
            onClick={() => {
              handleSwitchPage(pages.nextPage);
            }}
          >
            Next Page
          </button>
        </div>
        {/* Modal */}
        <div
          id='myModal'
          style={{ display: modal ? 'block' : 'none' }}
          className='modal'
        >
          <div className='modal-content'>
            <div className='modal-header'>
              <span onClick={() => setModal(false)} className='close'>
                &times;
              </span>
              <h2>Update User</h2>
            </div>
            <div>
              {/* <div className="modal-options" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <button disabled={serviceRequest ? false : true} onClick={() => setServiceRequest(false)} >FEG Home</button>
                                    <button disabled={serviceRequest ? true : false} onClick={() => setServiceRequest(true)}>Service Request</button>
                                </div> */}
              {serviceRequest ? (
                <form>
                  <br />
                  <div style={{ width: '100%' }}>
                    <p>Service Request Agent</p>
                    <Select
                      name='colors'
                      options={[
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' }
                      ]}
                      placeholder={
                        modalDate.has_permission_service_request ? 'Yes' : 'No'
                      }
                      className='basic-select'
                      classNamePrefix='select'
                      onChange={(e) =>
                        setServicePermissions({
                          card_related_ticket_permission:
                            modalDate.card_related_ticket_permission === ''
                              ? modalDate.card_related_ticket_permission
                              : servicePermissions?.card_related_ticket_permission,
                          game_related_ticket_permission:
                            modalDate.game_related_ticket_permission === ''
                              ? modalDate.game_related_ticket_permission
                              : servicePermissions?.game_related_ticket_permission,
                          has_permission_service_request: e?.value
                        })
                      }
                    />
                  </div>
                  <br />
                  <div style={{ width: '100%' }}>
                    <p>Card Related Ticket Permission</p>
                    <Select
                      name='colors'
                      options={[
                        {
                          value: 'AGENT_SUPER_ADMIN',
                          label: 'Agent Super Admin'
                        },
                        { value: 'STANDARD_AGENT', label: 'Standard Agent' },
                        { value: 'END_USER', label: 'End User' }
                      ]}
                      placeholder={modalDate.card_related_ticket_permission}
                      className='basic-select'
                      classNamePrefix='select'
                      onChange={(e) =>
                        setServicePermissions({
                          card_related_ticket_permission: e?.value,
                          game_related_ticket_permission:
                            modalDate.game_related_ticket_permission === ''
                              ? modalDate.game_related_ticket_permission
                              : servicePermissions?.game_related_ticket_permission,
                          has_permission_service_request:
                            modalDate.has_permission_service_request === ''
                              ? modalDate.has_permission_service_request
                              : servicePermissions?.has_permission_service_request
                        })
                      }
                    />
                  </div>
                  <br />
                  <div style={{ width: '100%' }}>
                    <p>Game Related Ticket Permission</p>
                    <Select
                      name='colors'
                      options={[
                        {
                          value: 'AGENT_SUPER_ADMIN',
                          label: 'Agent Super Admin'
                        },
                        { value: 'STANDARD_AGENT', label: 'Standard Agent' },
                        { value: 'END_USER', label: 'End User' }
                      ]}
                      placeholder={modalDate.game_related_ticket_permission}
                      className='basic-select'
                      classNamePrefix='select'
                      onChange={(e) =>
                        setServicePermissions({
                          card_related_ticket_permission:
                            modalDate.card_related_ticket_permission === ''
                              ? modalDate.card_related_ticket_permission
                              : servicePermissions?.card_related_ticket_permission,
                          game_related_ticket_permission: e?.value,
                          has_permission_service_request:
                            modalDate.has_permission_service_request === ''
                              ? modalDate.has_permission_service_request
                              : servicePermissions?.has_permission_service_request
                        })
                      }
                    />
                  </div>
                  {modalLoading ? (
                    <Loader />
                  ) : (
                    <button
                      className='button'
                      onClick={() => handleUpdateServiceRequest(modalDate.id)}
                      type='button'
                    >
                      Update
                    </button>
                  )}
                </form>
              ) : (
                <form>
                  <div className='modalRow'>
                    <TextField
                      label={'First name:'}
                      value={modalDate ? modalDate.first_name : ''}
                      placeholder='Enter the first name:'
                      onChange={(e) => {
                        setModalDate({
                          ...modalDate,
                          first_name: e.target.value
                        });
                        setFirstName(e.target.value);
                      }}
                      style={{ marginRight: '8px' }}
                    />
                    <TextField
                      label={'Last name:'}
                      value={modalDate ? modalDate.last_name : ''}
                      placeholder='Enter the last name:'
                      onChange={(e) => {
                        setModalDate({
                          ...modalDate,
                          last_name: e.target.value
                        });
                        setLastName(e.target.value);
                      }}
                    />
                  </div>
                  <div className='modalRow'>
                    <Select
                      name='colors'
                      options={locationsData}
                      placeholder={modalDate ? modalDate.location_name : ''}
                      className='basic-multi-select'
                      classNamePrefix='select'
                      onChange={handleSelectLocation}
                    />
                    <div
                      className='basic-multi-select'
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10
                      }}
                    >
                      <Select
                        name='colors'
                        options={profilesData}
                        placeholder={modalDate ? modalDate.profiles_name : ''}
                        className='basic-multi-select'
                        classNamePrefix='select'
                        onChange={handleSelectProfile}
                      />
                      <Select
                        name='colors'
                        options={groupsDataFiltered}
                        placeholder={modalDate ? modalDate.group_name : ''}
                        className='basic-multi-select'
                        classNamePrefix='select'
                        onChange={handleSelectGroup}
                      />
                    </div>
                  </div>
                  <div className='modalRow'>
                    <TextField
                      type='email'
                      label={'Email:'}
                      value={modalDate ? modalDate.email : ''}
                      placeholder='Enter the email:'
                      onChange={(e) => {
                        setModalDate({ ...modalDate, email: e.target.value });
                        setEmail(e.target.value);
                      }}
                      style={{ marginRight: '8px' }}
                    />
                    <TextField
                      type='password'
                      label={'Password:'}
                      placeholder='Enter the password:'
                      style={{ marginRight: '8px' }}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextField
                      type='password'
                      label={'Re-enter the same password:'}
                      placeholder='Re-enter the same password:'
                      onChange={(e) => setPasswordConfirmation(e.target.value)}
                    />
                  </div>
                  {modalLoading ? (
                    <Loader />
                  ) : (
                    <button
                      className='button'
                      onClick={() => handleUpdate(modalDate.id)}
                      type='button'
                    >
                      Update
                    </button>
                  )}
                </form>
              )}
            </div>
          </div>
        </div>
        {/* Modal Confirmations */}
        <div
          id='myModal'
          style={{ display: confirmation ? 'block' : 'none' }}
          className='modal'
        >
          <div className='modal-content'>
            <div className='modal-header'>
              {/* <span onClick={() => setConfirmation(false)} className="close">&times;</span> */}
              <h2>
                The user will be blocked from the system and their information
                will be deleted. Do you want to proceed?
              </h2>
            </div>
            <div className='modal-confirmation'>
              <form>
                {modalLoading ? (
                  <Loader />
                ) : (
                  <>
                      <button
                      type='button'
                      onClick={() => handleDeleteUser(modalDate)}
                      className='cancelbtn'
                    >
                      Yes
                    </button>
                    <button
                      type='button'
                      onClick={() => setConfirmation(false)}
                      className='deletebtn'
                    >
                      No
                    </button>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
        <div
          id='myModal'
          style={{ display: switchConfirmation ? 'block' : 'none' }}
          className='modal'
        >
          <div className='modal-content'>
            <div className='modal-header'>
              {/* <span onClick={() => setSwitchConfirmation(false)} className="close">&times;</span> */}
              <h2>Are you sure you want to activate/disable the user?</h2>
            </div>
            <div className='modal-confirmation'>
              <form>
              <button
                  type='button'
                  onClick={() => {
                    handleActived(
                      modalDate.user_status_value,
                      modalDate.id,
                      modalDate.group_id
                    );
                    setSwitchConfirmation(false);
                  }}
                  className='cancelbtn'
                >
                  Yes
                </button>
                <button
                  type='button'
                  onClick={() => {
                    setSwitchConfirmation(false);
                  }}
                  className='deletebtn'
                >
                  No
                </button>
        
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Management;
