import { Theme } from 'src/styles/Theme';
import styled from 'styled-components';

export const Container = styled.div`
  width: 86%;
  margin: 0 auto;
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const SubContainer = styled.div`
  border: 1px solid #ccc;
  .header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    svg {
      color: ${Theme.primary};
    }
  }
  label {
    color: ${Theme.primary};
    font-weight: bold;
  }
  padding: 10px;
`;

export const Btn = styled.button`
  background-color: ${Theme.primary};
  border: none;
  color: white;
  padding: 8px;
  cursor: pointer;
  margin-bottom: 10px;
`;
