import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiBell } from 'react-icons/fi';
import io from 'socket.io-client';
import axios from '../../services/axios';
import { AxiosResponse } from 'axios';

type AnnouncementData = {
  announcement_id: string;
  subject: string;
  info: string;
  is_for_everyone: boolean;
  by_location: boolean;
  by_group: boolean;
  createdAt: string;
  handleClickCloseSidebar?: () => void;
};
interface MessageNotify {
  id: string;
  id_remetente: string;
  id_location: string;
  store_id: string;
  content: string;
  createdAt: string;
  full_name_remetente: string;
  image_url?: string;
  id_location_messaging: string;
  id_remetente_messaging: string;
}

const Notifications = (props: any) => {
  const [announcementPreview, setAnnouncementPreview] = useState<
    AnnouncementData[]
  >([]);
  const [notifications, setNotifications] = useState<number>(0);
  const [modal, setModal] = useState<boolean>(false);
  const screen = window.screen.width;
  const history = useHistory();
  const idUser = localStorage.getItem('id');

  const email = localStorage.getItem('email');
  const [socket, setSocket] = useState<SocketIOClient.Socket>();
  const [messageNotify, setMessageNotify] = useState<MessageNotify[]>([]);

  useEffect(() => {
    notify();
    getMessageNotify();
  }, []);

  function getMessageNotify() {
    axios
      .get(`/api/v1/location-messaging/filter-notify/${email}`)
      .then((res) => {
        console.log(res.data);
        setMessageNotify(res.data);
      });
  }

  const sorter = (a: any, b: any) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];

    if (
      Number(a.createdAt.substring(7, 11)) !==
      Number(b.createdAt.substring(7, 11))
    ) {
      return (
        Number(b.createdAt.substring(7, 11)) -
        Number(a.createdAt.substring(7, 11))
      );
    } else if (a.createdAt.substring(4, 6) !== a.createdAt.substring(4, 6)) {
      return (
        months.indexOf(b.createdAt.substring(4, 6)) -
        months.indexOf(a.createdAt.substring(4, 6))
      );
    } else {
      return (
        Number(b.createdAt.substring(0, 4)) -
        Number(a.createdAt.substring(0, 4))
      );
    }
  };

  async function notify() {
    const notifications = sessionStorage.getItem('noti');
    if (!notifications) {
      await axios.get(`/api/v1/announcements/notify`).then((e) => {
        setNotifications(e.data.results.total);
        sessionStorage.setItem('notifications', e.data.results.total);
        const announcementsData: [] = e.data.results.announcementIds;
        const previewAnnouncements: AnnouncementData[] = [];
        announcementsData.forEach(async (announcement: any) => {
          await axios
            .get(`/api/v1/announcements/${announcement.id}?notopen=true`)
            .then((r) => {
              previewAnnouncements.push({
                announcement_id: r.data.results.id,
                subject: r.data.results.subject,
                info: r.data.results.info,
                is_for_everyone: r.data.results.is_for_everyone,
                by_location: r.data.results.by_location,
                by_group: r.data.results.by_group,
                createdAt: r.data.results.createdAt
              });
            });
        });
        previewAnnouncements.sort(sorter);
        setAnnouncementPreview(previewAnnouncements);
      });
    } else if (notifications) {
      setNotifications(Number(notifications));
      await axios.get(`/api/v1/announcements/notify`).then((e) => {
        sessionStorage.setItem('notifications', e.data.results.total);
        if (e.data.results.total !== notifications) {
          setNotifications(e.data.results.total);
          const announcementsData: [] = e.data.results.announcementIds;
          const previewAnnouncements: AnnouncementData[] = [];
          announcementsData.forEach(async (announcement: any) => {
            await axios
              .get(`/api/v1/announcements/${announcement.id}?notopen=true`)
              .then((r) => {
                previewAnnouncements.push({
                  announcement_id: r.data.results.id,
                  subject: r.data.results.subject,
                  info: r.data.results.info,
                  is_for_everyone: r.data.results.is_for_everyone,
                  by_location: r.data.results.by_location,
                  by_group: r.data.results.by_group,
                  createdAt: r.data.results.createdAt
                });
              });
          });
          previewAnnouncements.sort(sorter);
          setAnnouncementPreview(previewAnnouncements);
        }
      });
    }
  }

  window.onclick = function (event: any) {
    if (event.target.className === 'modal') {
      setModal(false);
    }
  };
  function handleClearNotifyMessaging() {
    axios
      .get(`/api/v1/location-messaging/filter-notify/clear/${email}`)
      .then((res) => {
        setMessageNotify([]);
      });
  }

  function handleClearNotifications() {
    handleClearNotifyMessaging();
    announcementPreview.map((announcement) => {
      axios
        .get(`api/v1/announcements/${announcement.announcement_id}`)
        .then(() => {
          notify();
        });
    });
  }

  function dateTime(time: string) {
    let hour = time.substring(12, 14);
    const minute = time.substring(15, 17);
    let period;

    if (Number(hour) >= 12) {
      period = ' PM';
      hour = (Number(hour) - 12).toString().padStart(2, '0');
    } else {
      period = ' AM';
    }

    return time.substring(0, 12) + hour + ':' + minute + period;
  }

  const myModal2 = (
    <div
      id='myModal2'
      style={{ display: modal ? 'flex' : 'none' }}
      className='modal2'
    >
      <span onClick={() => setModal(false)} className='close'>
        &times;
      </span>
      {notifications <= 0 && messageNotify.length <= 0 ? (
        <h2>You don't have new announcements</h2>
      ) : (
        <>
          {announcementPreview.map((announcement) => (
            <div
              key={announcement.announcement_id}
              className='notificationRow'
              onClick={() => {
                axios
                  .get(`api/v1/announcements/${announcement.announcement_id}`)
                  .then(() => {
                    notify();
                  });
                history.replace(
                  `/announcements/${
                    announcement.is_for_everyone
                      ? 'company'
                      : announcement.by_location
                      ? 'local'
                      : announcement.by_group
                      ? 'group'
                      : 'others'
                  }/${announcement.announcement_id}`
                );
                setModal(false);
                window.location.reload();
              }}
            >
              <h3>{announcement.subject}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    announcement.info.length > 100
                      ? announcement.info.substring(0, 100) + '...'
                      : announcement.info
                }}
              />
              <span>{dateTime(announcement.createdAt)}</span>
            </div>
          ))}
          {messageNotify.map((message) => {
            return (
              <>
                <div className='notificationRow' key={message.id}>
                  <h3>
                    {message.full_name_remetente} | Location: {message.store_id}
                  </h3>
                  <p>{message.content}</p>
                  <span>{dateTime(message.createdAt)}</span>
                </div>
              </>
            );
          })}
        </>
      )}
      <button
        onClick={handleClearNotifications}
        style={{
          display:
            notifications <= 0 && messageNotify.length <= 0 ? 'none' : 'block'
        }}
      >
        Clear All
      </button>
    </div>
  );

  useEffect(() => {
    // Connect to the socket server
    const newsocket = io(`${process.env.REACT_APP_API_URL}`, {
      transports: ['websocket', 'polling', 'flashsocket'],
      upgrade: true
    });
    setSocket(newsocket);
    // Event listener for when the connection is established
    newsocket.on('connect', () => {
      console.log('Connected to the socket server');
    });

    // Event listener for custom events from the server
    newsocket.on('newMessage', (message: any) => {
      getMessageNotify();
    });

    // Clean up the socket connection when the component is unmounted
    return () => {
      newsocket.disconnect();
    };
  }, []);

  return (
    <div className='notifications-button'>
      {/*
      <h1
        style={{
          display: notifications <= 0 && messageNotify.length <= 0 ? 'none' : ''
        }}
      >
        {notifications >= 1 ? '+' : ''}
        {notifications + messageNotify.length}
      </h1>     
      */}

      <button
        onClick={() => {
          if (screen < 700) {
            props.handleClickCloseSidebar();
          }

          setModal(!modal);
        }}
        className={
          notifications >= 1 || messageNotify.length > 0 ? 'image' : ''
        }
        title='Notifications'
      >
        <FiBell color={props.color} size={25} />
      </button>

      {myModal2}
    </div>
  );
};
export default Notifications;
