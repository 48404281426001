import React from 'react';

import Profile from '../../../components/UserValidation/Profile';

const UserProfile: React.FC = () => {

    return (
            <Profile />
    )
}

export default UserProfile;
