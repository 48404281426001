import React, { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default function LatestNewsPage(): ReactElement {
  const idParams = useParams<{ id: string }>();

  useEffect(() => {
    console.log("testtttt")
    console.log(idParams.id);
  }, []);

  return (
    <div>
      <h1>LatestNews</h1>
    </div>
  );
}
