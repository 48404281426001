/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Container, GridShortcut } from './styled';
import { FaHeart, FaRegHeart } from 'react-icons/fa';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, react/prop-types
const ListaOrdenada = ({
  dados,
  formatString,
  favoriteShortCurt,
  checkFav
}) => {
  // Ordena os dados por alguma propriedade, por exemplo, 'nome'
  // eslint-disable-next-line react/prop-types
  const dadosOrdenados = dados.sort((a, b) =>
    a.display_name1.localeCompare(b.display_name1)
  );

  // Cria um objeto para armazenar os dados separados por letra
  const dadosSeparadosPorLetra = {};

  // Itera sobre os dados ordenados e os agrupa por letra
  dadosOrdenados.forEach((item) => {
    const primeiraLetra = item.display_name1.charAt(0).toUpperCase();
    if (!dadosSeparadosPorLetra[primeiraLetra]) {
      dadosSeparadosPorLetra[primeiraLetra] = [];
    }
    dadosSeparadosPorLetra[primeiraLetra].push(item);
  });
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleHover = (index) => {
    setHoveredIndex(index);
  };

  // Gera a renderização com as listas separadas por letra
  return (
    <div>
      {Object.keys(dadosSeparadosPorLetra).map((letra, indexKey) => (
        <div key={letra}>
          <h2>{letra}</h2>
          <Container>
            {dadosSeparadosPorLetra[letra].map((shortcut, index) => (
              <GridShortcut
                key={Math.random()}
                onMouseEnter={() =>
                  handleHover({ index: index, indexKey: indexKey })
                }
                onMouseLeave={() => handleHover(null)}
                className={`
                  ${hoveredIndex?.index === index &&
                  hoveredIndex?.indexKey === indexKey &&
                  'active'
                  }           
                `}
              >
                <img src={shortcut.desktop_image} alt='' />
                <div className='legend'>
                  <div className='legend-title'>
                    <p> {shortcut.display_name1}</p>
                    <a href={shortcut.link_desktop} target='_blanck'>
                      {hoveredIndex?.index === index &&
                        hoveredIndex?.indexKey === indexKey
                        ? shortcut.display_name2
                        : formatString(shortcut.display_name2)}
                    </a>
                  </div>
                </div>
                <div
                  className='div-heart'
                  onClick={() => favoriteShortCurt(shortcut)}
                >
                  {checkFav(shortcut.id) ? (
                    <FaHeart color='purple' />
                  ) : (
                    <FaRegHeart color='purple' />
                  )}
                </div>
              </GridShortcut>
            ))}
          </Container>
        </div>
      ))}
    </div>
  );
};

export default ListaOrdenada;
