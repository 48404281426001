import React, { useEffect, useState } from 'react';
import {
  Container,
  ListLocations,
  LocationGroup,
  HeaderChat,
  ChatUserLocation,
  FilterContainer,
  Informations,
  InformationsContent
} from './styled';
import axios from 'src/services/axios';

import { useHistory, useParams } from 'react-router-dom';

import UserMessagesChat from 'src/components/userMessagesChat';

import { Avatar, Button, MenuItem, Select } from '@material-ui/core';

interface User {
  user: string;
  id: string;
  location: string[];
  first_name: string;
  last_name: string;
  email: string;
  user_email?: string;
}
interface Location {
  id: string;
  location_name: string;
  users: User[];
}

const UserLocationChat: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<any>();
  const [currentMessage, setCurrentMessage] = useState<User>();
  const [currentLocation, setCurrentLocation] = useState<Location>();
  const [locationName, setLocationName] = useState('');
  const [informationOpen, setInformationOpen] = useState(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const email = localStorage.getItem('email');

  const [admin, setAdmin] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setCurrentLocation(locations[0]);
    const locationId = localStorage.getItem('location_id');
    axios
      .get(`/api/v1/location-messaging/users/${email}/${locationId}`, {
        timeout: 0
      })
      .then((res) => {
        setAdmin(res.data.admin);
        setLocations(res.data.locations);
      });
  }, []);

  useEffect(() => {
    if (id === '0') {
    } else {
      setLocationName(id === '0' ? locations[0].id : id);
      setCurrentLocation(
        id === '0'
          ? locations[0]
          : locations.find((location) => `${location.id}` === id)
      );
      console.log(locations[0]);
      axios
        .get(
          `/api/v1/location-messaging/location/find/${
            id === '0'
              ? locations[0].id
              : locations?.find((location) => `${location.id}` === id)?.id
          }`,
          {
            timeout: 0
          }
        )
        .then((res) => {
          setUsers(res.data);
        });
    }
  }, [id]);

  useEffect(() => {
    if (locations.length > 0) {
      setLocationName(id === '0' ? locations[0].id : id);

      setCurrentLocation(
        id === '0'
          ? locations[0]
          : locations.find((location) => `${location.id}` === id)
      );

      axios
        .get(
          `/api/v1/location-messaging/location/find/${
            id === '0'
              ? locations[0].id
              : locations?.find((location) => `${location.id}` === id)?.id
          }`,
          {
            timeout: 0
          }
        )
        .then((res) => {
          setUsers(res.data);
        });
    }
  }, [locations]);

  const handleSelectChange = async (event: any) => {
    const target = locations.find(
      (locationTarget) => locationTarget.id === event.target.value
    );
    history.push(`/locations/chat/${event.target.value}`);
  };

  return (
    <Container>
      <ListLocations>
        <FilterContainer>
          <Select
            variant='filled'
            value={locationName}
            style={{ width: '100%', background: 'white'}}
            onChange={handleSelectChange}
            
          >
            {locations?.map((item) => (
              <MenuItem key={Math.random()} value={item.id}>
                {item.location_name}: {item.id}
              </MenuItem>
            ))}
          </Select>
        </FilterContainer>
  
        {currentLocation?.users
          ?.filter(
            (user: any) => user.active === 1 || user.user_status === 'ACTIVE'
          )
          ?.map((user: any) => {
            return (
              <LocationGroup
                key={Math.random()}
                onClick={() => {
                  setCurrentMessage(user);
                  setInformationOpen(!informationOpen);
                }}
              >
              <Avatar style={{marginRight: 10, backgroundColor:"#336699"}} >{user.first_name[0]}</Avatar>
                <div>
                  <p>
                    {user.first_name} {user.last_name}
                  </p>
                </div>
              </LocationGroup>
            );
          })}

        {users
          ?.filter(
            (user: any) => user.active === 1 || user.user_status === 'ACTIVE'
          )
          ?.map((user: any) => {
            return (
              <LocationGroup
                key={Math.random()}
                onClick={() => {
                  setCurrentMessage(user);
                  setInformationOpen(!informationOpen);
                }}
              >
                 <Avatar style={{marginRight: 10, backgroundColor:"#336699"}}>{user.first_name[0]}</Avatar>
               
                <div>
                  <p>
                    {user.first_name} {user.last_name}
                  </p>
                </div>
              </LocationGroup>
            );
          })}
      </ListLocations>

      <ChatUserLocation>
        <HeaderChat>
          <h1>{currentLocation?.location_name}</h1>
          {/*<Input type='text' placeholder='find message...' />*/}
        </HeaderChat>
        <UserMessagesChat
          id={currentLocation?.id}
          location={currentLocation}
          admin={admin}
        />
      </ChatUserLocation>
      {informationOpen && (
        <Informations>
          <InformationsContent>
            <div className='user-description'>
            <Avatar style={{backgroundColor:"#336699"}}>{currentMessage?.first_name[0]&& currentMessage?.first_name[0]}</Avatar>
              <h1>{currentMessage?.first_name}</h1>
              <div>
                <p>Location: {currentLocation?.location_name}</p>
                <p>
                  Email: {currentMessage?.email || currentMessage?.user_email}
                </p>
              </div>
            </div>
          </InformationsContent>
          <Button color='secondary' onClick={() => setInformationOpen(false)}>
            CLOSE
          </Button>
        </Informations>
      )}
    </Container>
  );
};

export default UserLocationChat;
