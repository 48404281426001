import styled from 'styled-components';
import { Theme } from '../../styles/Theme';

export const DivContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin: 10px;
  button {
    padding: 14px;
    border: none;
    background-color: ${Theme.secondary};
    color: ${Theme.grey};
    cursor: pointer;
    border-radius: 6px;
  }
  #active {
    background-color: ${Theme.primary};
    color: ${Theme.secondary};
  }
`;
