import * as React from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { BsCaretDownFill } from 'react-icons/bs';

interface BasicMenuProps {
  children: React.ReactNode;
}

export default function BasicMenu({ children }: BasicMenuProps): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        style={{ background: '#25ab9f' }}
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <p style={{ color: '#fff' }}>MANAGE</p>{' '}
        <BsCaretDownFill size={20} style={{ marginLeft: 4 }} color={'#fff'} />
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        style={{ margin: 0, padding: 0 }}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {children}
      </Menu>
    </div>
  );
}
