import React, { useEffect, useState } from 'react';
import axios from '../../services/axios';
import jwt from 'jsonwebtoken';
import { useCookies } from 'react-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { FiBookOpen, FiCalendar, FiFolder, FiHome, FiMapPin, FiMenu, FiMonitor, FiPieChart, FiPlusSquare, FiShield, FiUserCheck, FiUsers } from 'react-icons/fi';
import { Container } from './styled';
import { MdApps, MdInfo, MdMessage } from 'react-icons/md';
import { WorkOutlineOutlined } from '@material-ui/icons';
import { FaSearch } from 'react-icons/fa';
import { RiChat4Line, RiChatSettingsLine } from 'react-icons/ri';


export default function Search(props: any) {
  const [cookies, ,] = useCookies(['token']);
  const location = useLocation();
  const token: any = cookies.token;
  const [permissions2, setPermissions2] = useState({
    home_page: { view: false, add: false, edit: false, delete: false },
    announcements: { view: false, add: false, edit: false, delete: false },
    user_validation: { view: false, add: false, edit: false, delete: false },
    user_management: { view: false, add: false, edit: false, delete: false },
    location: { view: false, add: false, edit: false, delete: false },
    events: { view: false, add: false, edit: false, delete: false },
    analytics: { view: false, add: false, edit: false, delete: false },
    shortcuts: { view: false, add: false, edit: false, delete: false },
    permissions_management: {
      view: false,
      add: false,
      edit: false,
      delete: false
    },
    map_jobtitle: { view: false, add: false, edit: false, delete: false },
    chat_feg: { view: false, add: false, edit: false, delete: false },
    chat_feg_docs: { view: false, add: false, edit: false, delete: false },
    chat_feg_logs: { view: false, add: false, edit: false, delete: false },
    chat_feg_repository: { view: false, add: false, edit: false, delete: false },
  });
  const [superAdmin, setSuperAdmin] = useState(false);
  const nav = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState<any>();
  
  const handleNav = (url: any) => {
    setSearchTerm("")
    nav.push(url);
  };


  const items = [
    {name:"Home", component: 
    <div
      onClick={() => handleNav('/new-home')}
      className={`${location.pathname === '/new-home' ? 'active opt' : 'opt'}`}
    >  
      <p>Home</p>
      <FiHome size={20} /> 
    </div>},
    {
      name: "Shortcut", component:     <div
      onClick={() => handleNav('/list-shortcuts')}
      className={`${location.pathname === '/list-shortcuts' ? 'active opt' : 'opt'}`}
    >   
      <p>Shortcut</p>
      <MdApps size={20} /> 
    </div>
    },
    {name: "Company Announ...", component:   
    <div
      onClick={() => handleNav('/announcements')}
   
    >
      <p>Company Announ...</p>
       <FiBookOpen size={20} />
    </div>
    },
    {
      name: "Users Validations", component: 
      <div
        onClick={() => handleNav('/user')}
        className={`${location.pathname === '/user' ? 'active opt' : ''}`}
        style={{
          display: permissions2.user_validation.view ? 'flex' : 'none'
        }}
      >    
        <p>Users Validations</p>
          <FiUserCheck size={20} /> 
      </div>
    },
    {
      name: "Users Management", component:           <div
      onClick={() => handleNav('/management')}
      className={` ${location.pathname === '/management' ? 'active opt' : ''}`}
      style={{
        display: permissions2.user_management.view ? 'flex' : 'none'
      }}
    >
      <p>Users Management</p>
      <FiUsers size={20} /> 
    </div>
    },
    {
      name: "New Announcement", component: 
      <div
        style={{ display: permissions2.announcements.add ? 'flex' : 'none' }}
        onClick={() => handleNav('/announcements/new')}
        className={`${location.pathname === '/announcements/new' ? 'active opt' : ''}`}
      >   
        <p>New Announcement</p>
       <FiPlusSquare size={20} /> 
      </div>
    },
    {
      name: "Location Management", component:    <div
      style={{ display: permissions2.location.view ? 'flex' : 'none' }}
      onClick={() => handleNav('/locations')}
      className={` ${location.pathname === '/locations' ? 'active opt' : ''}`}
    >     
      <p>Location Management</p>
      <FiMapPin size={20} /> 
    </div>
    },
    {
      name:"Company Calendar", component:           <div
      onClick={() => handleNav('/calendar')}
    >    
      <p>Company Calendar</p>
      <FiCalendar size={20} /> 
    </div>
    },
    {
      name: "Analytics", component:      <div
      onClick={() => handleNav('/analytics')}
      className={`${location.pathname === '/analytics' ? 'active opt' : ''}`}
      style={{ display: permissions2.analytics.view ? 'flex' : 'none' }}
    >    
      <p>Analytics</p>
     <FiPieChart size={20} /> 
    </div>
    },
    {
      name:"Shortcuts config", component: 
      <div
        onClick={() => handleNav('/shortcuts')}
        className={` ${location.pathname === '/shortcuts' ? 'active opt' : ''}`}
        style={{ display: permissions2.shortcuts.view ? 'flex' : 'none' }}
      >    
        <p>Shortcuts config</p>
      <FiMonitor size={20} />
      </div>
    },
    {
      name:"JobTitle", component:  superAdmin && (
        <div
          onClick={() => handleNav('/mapJobtitle')}
          className={`${location.pathname === '/mapJobtitle' ? 'active opt' : ''}`}
        >   
          <p>JobTitle</p>
        <WorkOutlineOutlined /> 
        </div>
      )
    },
    
    {
      name: "Location Messaging", component: 
      
        <div
          onClick={() => window.open('https://www.systemfeg.com/locations/chat/0', "_blank")}
          className={` ${location.pathname === '/locations/chat/0' ? 'active opt' : ''}`}
          
        > 
          <p>Location Messaging</p>
        <MdMessage size={20}/> 
        </div>
      
    },
    {
      name: "Permissions", component:
      <div
        onClick={() => handleNav('/permissions')}
        className={`${location.pathname === '/permissions' ? 'active opt' : ''}`}
        style={{
          display: permissions2.permissions_management.view ? 'flex' : 'none'
        }}
      >   
        <p>Permissions</p>
        <FiShield size={20} />
      </div> 
    },
    { 
      name: "ASK FEG", component: 
      <div
        onClick={() => handleNav('/chat-fegpt')}
        className={`short ${location.pathname === '/chat-fegpt' ? 'active opt down' : 'down'}`}
        style={{
          display: permissions2.chat_feg.view ? 'flex' : 'none'
        }}
      >     
        <p>Chat</p>
        <RiChat4Line size={20} /> 
      </div>
    },
    {
      name:"ASK FEG (UPLOAD DOCUMENT)", component:  <div
      onClick={() => handleNav('/settings-fegpt')}
      className={`short ${location.pathname === '/settings-fegpt' ? 'active opt down' : 'down'}`}
      style={{
        display: permissions2.chat_feg_docs.view ? 'flex' : 'none'
      }}
    >     
      <p>Upload Document</p>
      <RiChatSettingsLine size={20} /> 
    </div>
    },
    {
      name: "Ask FEG (REPOSITORY)", component:   <div
      onClick={() => handleNav('/repository')}
      className={`short ${location.pathname === '/repository' ? 'active opt down' : 'down'}`}
      style={{
        display: permissions2.chat_feg_repository.view ? 'flex' : 'none'
      }}
    >
         
      <p>Repository</p>
      <FiFolder size={20} /> 
    </div>
    }, 
    {
      name:"Ask FEG (CHAT ANALYTICS)", component:  <div
      onClick={() => handleNav('/chat/logs')}
      className={`short ${location.pathname === '/chat/logs' ? 'active opt down' : 'down'}`}
      style={{
        display: permissions2.chat_feg_logs.view ? 'flex' : 'none'
      }}
    >
      <p>Chat Analytics</p>
      <MdInfo size={20} /> 
    </div>
    }
  ]

  const handleSearch = (event: any) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    const filteredItems = items.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredItems(filteredItems);
  };

  useEffect(() => {
    handlePermissions()
  }, []);
  function handlePermissions() {
    if (localStorage.getItem('token')) {
      jwt.verify(
        localStorage.getItem('token')!.toString(),
        '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
        async (err: any, decoded: any) => {
          if (err) {
            alert(err);
          } else {
            setPermissions2(decoded.roles);

            const customer_id = localStorage.getItem('id');
            const response = await axios
              .get(`api/v1/accounts/${customer_id}`)
              .then((response) => response.data.results);
            if (response.group_id === '128e5e58-3c0a-4e63-9be1-d0b6fc2f871e') {
              setSuperAdmin(true);
            }

          }
        }
      );
      return;
    }
    jwt.verify(
      token!.toString(),
      '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
      async (err: any, decoded: any) => {
        if (err) {
          alert(err);
        } else {
          setPermissions2(decoded.roles);
        }
      }
    );
  }

  return (
    <>
    
      <input type="text" placeholder='Type in to search.' value={searchTerm} onChange={(e) => handleSearch(e)}/>
      <div><FaSearch size={16}/></div>
      {
        searchTerm.length> 0 && (
          <Container>
          {
              filteredItems && 
                filteredItems.map((item:any) => {
                  return item.component
                })    
          }
          
        </Container>
        )
      }
    </>
  );
}
