export const locationsData = [
  { value: '70b9a3aa-6c68-4ccd-8bd3-4405d7b1acad', label: 'Carowinds' },
  { value: 'c252c155-900e-4546-b07e-269df1695f18', label: 'Build-A-Bear' },
  { value: '02090eab-a29f-4e77-b0bd-6c5298a21fc7', label: 'Kings Island' },
  { value: '22976513-3990-4b53-ae38-44ab168c9739', label: 'Dorney Park' },
  {
    value: '3a6fa071-0d6a-4053-8a22-69aad139357f',
    label: 'Michigan Adventures'
  },
  { value: 'a889178a-0530-4819-8157-181ed81bcbf8', label: 'World of Fun' },
  { value: '04da96a0-5801-4aa2-b812-c8815d4105b1', label: 'Breakers Hotel' },
  { value: 'fff705e0-0bcd-4bce-927f-edc8e68e565d', label: 'Ingleside Hotel' },
  { value: '8fda4f4d-7c8c-4544-8f74-ae7fd03cb036', label: 'Castaway Bay' },
  { value: 'aea7aed2-a174-44cc-83a8-a3b4fbd21a58', label: 'Camelback Resort' },
  { value: '33f7b7d7-9e39-4ba5-ab9e-f55276f64ef7', label: 'Cedar Point' },
  { value: 'b840348e-cca1-4d88-bc46-5b39ffa59d55', label: 'Freedom Station' },
  { value: '4298650a-fdaf-4192-8fa1-30996c41b7be', label: 'FEG Office - Ohio' },
  {
    value: '93334557-a92e-4eb3-a295-79a8a203f40e',
    label: 'FEG Illinois Office'
  },
  { value: '9ec9f837-14e2-496b-89e2-6a15ab403127', label: 'Funtrackers' },
  {
    value: 'b86afad0-e3e7-487c-932a-fad0ebbafe3f',
    label: 'Going Bonkers Columbia'
  },
  {
    value: 'c5b99a6a-d0f2-447d-8217-60016313c2df',
    label: 'Going Bonkers - Quincy'
  },
  {
    value: '7ae32fc7-2e46-43df-8628-8a1758765f24',
    label: 'Going Bonkers - Topeka'
  },
  { value: 'e28df6a1-10b6-4f6b-a78a-df47c3604b55', label: 'Grand Sierra' },
  {
    value: 'e615ccfc-026d-49bf-9de1-c11c40454930',
    label: 'Grand Traverse Resort'
  },
  {
    value: '960e3d67-5756-423f-9ffe-f55de0e4057c',
    label: 'Great Wolf Lodge - Concord'
  },
  {
    value: '41dff3f1-c4f0-47a5-a483-81b89b66e4ce',
    label: 'Great Wolf Lodge - Grand Mound'
  },
  {
    value: '951067df-6bea-463e-9ef7-d2cfefe00061',
    label: 'Great Wolf Lodge - Grapevine'
  },
  {
    value: '4b6460b8-ce09-40eb-9346-662306926b2a',
    label: 'Great Wolf Lodge - Kansas City'
  },
  {
    value: '17812261-87f5-4fa7-9459-5192ee20844d',
    label: 'Great Wolf Lodge - Mason'
  },
  {
    value: '440b1553-c642-4b37-9dd3-81aa5188f680',
    label: 'Great Wolf Lodge - Poconos'
  },
  {
    value: '4a2d5450-84d3-4d68-8fdd-a019266c4760',
    label: 'Great Wolf Lodge - Sandusky'
  },
  {
    value: '142bba35-c12d-4656-9797-a98ecf597b33',
    label: 'Great Wolf Lodge - Traverse City'
  },
  {
    value: '5731fbfe-462c-4f8b-8754-c24fc56694a8',
    label: 'Great Wolf Lodge - Williamsburg'
  },
  {
    value: '268072bc-64c6-45de-bef5-9ea5518a43bb',
    label: 'Great Wolf Lodge - Wisconsin Dells'
  },
  {
    value: 'fcf7c8dd-b0ca-450a-be00-42e6e75f4935',
    label: 'Great Wolf Lodge - New England'
  },
  {
    value: 'c97b3ccf-ce65-4ef3-8dcc-75d09951c8b7',
    label: 'Great Wolf Lodge - Garden Grove'
  },
  {
    value: '096853d6-b0cc-45a4-9d20-72494667f638',
    label: 'Great Wolf Lodge - Colorado Springs'
  },
  {
    value: '2fab773c-4488-424f-8da7-78cbe888f514',
    label: 'Great Wolf Lodge - Bloomington'
  },
  {
    value: 'a1471991-f064-4473-b400-a571b1c78dcb',
    label: 'Great Wolf Lodge - LaGrange'
  },
  {
    value: '2a7bf72d-f863-411a-bd1d-961839df06e7',
    label: 'Great Wolf Lodge - Gurnee'
  },
  {
    value: 'f3ea17b9-1037-4ce4-949b-2c19187accf7',
    label: 'Great Wolf Lodge - Scottsdale'
  },
  {
    value: 'b9b60cf3-e295-42e1-8488-19f50127b367',
    label: 'Great Wolf Lodge - Manteca'
  },
  { value: 'c4033a3e-c296-47bf-8b3f-025c5cb4d696', label: 'Hollywood Park' },
  {
    value: '5e82baf8-1e44-46ad-9cee-20bc51dde60b',
    label: 'In The Game - American Dream'
  },
  {
    value: 'b5c7c21b-e74d-49da-a253-130587d7f134',
    label: 'In The Game - ICON Park'
  },
  {
    value: 'b7b15473-9ba0-4e6d-84c3-2d30f90ca6d6',
    label: 'In The Game Peabody'
  },
  {
    value: 'faf21cd4-6c60-4ad3-a2cb-a63fcea302a2',
    label: 'In the Game - Pier 39'
  },
  { value: '469c0a91-9f65-42f5-a19a-7dbc8ad595a1', label: 'Johnny Rockets' },
  {
    value: 'fdcdd096-34cf-44ef-b479-a8791670fdb7',
    label: 'Kalahari Dells - BGR'
  },
  {
    value: '347134a9-0035-4135-8455-405274fe2cde',
    label: 'Kalahari Dells - ITP'
  },
  {
    value: 'fc7bd9b4-b71c-4f39-917b-6d7fdc779ff3',
    label: 'Kalahari - Poconos'
  },
  {
    value: '4fd32427-6371-4b23-9717-fae51c94cb8c',
    label: 'Kalahari Round Rock'
  },
  { value: '78898734-f305-4892-b89e-e5ea4e06eccb', label: 'Kalahari Sandusky' },
  { value: '9799cee8-5315-4d53-93f9-bb025a62fbb9', label: 'Kartrite' },
  { value: 'ba580dea-ecc2-4fed-a88c-6281f72d458f', label: 'Knuckleheads' },
  { value: 'd70bbfdb-829f-4800-a17a-e6d872cfb280', label: 'Rain Waterpark' },
  { value: 'bfb6b0c7-6bb4-47db-be47-8766adc85e88', label: 'Swings N Things' },
  {
    value: '83107167-8a56-4646-b05f-e64a2619fb6a',
    label: 'Sawgrass Mills Gameroom'
  },
  {
    value: 'f50a367a-2748-4ee0-bcbb-524a197b038c',
    label: 'Renaissance Esmerelda Resort'
  },
  { value: 'ce70da3a-c0d7-448b-b8d4-b6e361ed4f33', label: 'The Grove' },
  { value: 'cc772abb-33b3-4719-a3a4-477c1b23206c', label: 'Timber Ridge' },
  { value: '57edf6d0-4500-4af2-99fc-43f6ecf981b6', label: 'Waldameer Park' },
  { value: '8b46c53b-de45-4964-89a2-640e8887d041', label: 'Walthers' },
  { value: '23bf2f53-c5fa-4ea2-a8d2-513f6c57877f', label: 'Zehnders' },
  { value: '4fa6480a-aa17-49aa-bec7-6022b731195c', label: 'Merchandise Office' }
];

export const groupsData = [
  { value: 'cef0ac62-0cda-42cf-9472-a80a64f806dc', label: 'Assistant GMs' },
  { value: 'd0b9de48-da9c-4071-aebc-67c28d67e2d1', label: 'District Manager' },
  { value: '46a9135d-7617-495a-a477-b98d2685970d', label: 'Equipment Office' },
  { value: 'fb006748-92f7-4756-8e55-a53764741e99', label: 'General Manager' },
  { value: '5c48e49d-4a8a-47ed-8e7f-bac2a4672036', label: 'Graphics Office' },
  { value: '270c8457-837d-4a79-af4c-1005948b0e2e', label: 'Great Wolf Lodge' },
  {
    value: '66817e9a-e53c-413a-b5a1-0985afdc95c0',
    label: 'Merchandise Manager'
  },
  {
    value: '008e07d7-ffa0-4461-8685-aed3c917deb3',
    label: 'Merchandise Office'
  },
  { value: '60c9e68c-c351-414a-9021-441fe07bb5c4', label: 'Office' },
  {
    value: 'f455720f-b1d2-4fe9-9010-170c837535f3',
    label: 'Read Only (Partners)'
  },
  {
    value: '408ac245-fc8f-48e7-b5e8-f5ff5adca51f',
    label: 'Regional Director/VP'
  },
  {
    value: 'bbbfbf18-0ab7-4533-b63a-cdf41e91b201',
    label: 'Sacoa/Embed/CenterEdge'
  },
  { value: 'c7229861-fef2-487a-b77f-8d86913078b3', label: 'Supervisor' },
  { value: '128e5e58-3c0a-4e63-9be1-d0b6fc2f871e', label: 'Super Admin' },
  { value: '333d091e-5179-4470-b229-dfe97009d6c8', label: 'SVP' },
  { value: '6efcdeb0-0292-4a5f-bc5a-64078b5b9c49', label: 'Technical' }
];

export const mockEventsData = [
  {
    address: 'Special Street, 123, Los Angeles - CA',
    allDay: false,
    backgroundColor: '#4fdb89',
    description:
      'Los Angeles DS location opening, to be realized along with shows and food shops.',
    endStr: '2021-03-02T11:30:00.000Z',
    full_name: 'Bemax Group Developer',
    id: '11111111-f96f-494b-8bd8-2f8d105a63e0',
    isForEveryone: false,
    isForMe: false,
    startStr: '2021-03-02T11:00:00.000Z',
    title: 'Los Angeles DS',
    type: 'Location Opening'
  },
  {
    address: 'Amazing Street, 123, San Antonio - TX',
    allDay: false,
    backgroundColor: '#4fdb89',
    description:
      'San Antonio Like location opening, to be realized along with special events.',
    endStr: '2021-03-02T11:30:00.000Z',
    full_name: 'Bemax Group Developer',
    id: '22222222-f96f-494b-8bd8-2f8d105a63e0',
    isForEveryone: false,
    isForMe: false,
    startStr: '2021-03-02T11:00:00.000Z',
    title: 'San Antonio Like',
    type: 'Location Opening'
  },
  {
    address: 'Special Street, 123, Los Angeles - CA',
    allDay: false,
    backgroundColor: '#4f5bdb',
    description: 'LA project, to be realized along with shows and food shops.',
    endStr: '2021-03-02T11:30:00.000Z',
    full_name: 'Bemax Group Developer',
    id: '33333333-f96f-494b-8bd8-2f8d105a63e0',
    isForEveryone: false,
    isForMe: false,
    startStr: '2021-03-02T11:00:00.000Z',
    title: 'LA first project',
    type: 'Project'
  },
  {
    address: 'Amazing Street, 123, San Antonio - TX',
    allDay: false,
    backgroundColor: '#4f5bdb',
    description:
      'San Antonio project, to be realized along with special events.',
    endStr: '2021-03-02T11:30:00.000Z',
    full_name: 'Bemax Group Developer',
    id: '44444444-f96f-494b-8bd8-2f8d105a63e0',
    isForEveryone: false,
    isForMe: false,
    startStr: '2021-03-02T11:00:00.000Z',
    title: 'San Antonio first project',
    type: 'Project'
  }
];
