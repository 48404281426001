import { Theme } from 'src/styles/Theme';
import styled from 'styled-components';

export const PreviewContent = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  padding: 0;
  h1 {
    margin: 0;
    padding: 0;
    color: ${Theme.primary};
  }
  h1.primary {
    color: ${Theme.grey};
  };
`;
export const LoadingContainer = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.5rem;
  color: ${Theme.primary};
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  p{
    margin-bottom: 20px;
  }
`;