import React from 'react';
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import * as ROUTES from './routerList';
import DefaultPage from '../../pages/DefaultPage';
import PaylocityAnnouncement from '../../pages/PaylocityAnnouncement';
import BenefitsAnnouncement from '../../pages/BenefitsAnnouncement';

const SignRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path={ROUTES.ANNOUNCEMENTPAYLOCITY}
          component={PaylocityAnnouncement}
        />
        <Route
          path={ROUTES.ANNOUNCEMENTBENEFITS}
          component={BenefitsAnnouncement}
        />
        <Route path={ROUTES.DEFAULT} component={DefaultPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default SignRoutes;
