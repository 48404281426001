import axios from '../../services/axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Monograma from '../../images/Monograma.png';

const PaylocityAnnouncement: React.FC = () => {
  type DataType = {
    info: string;
    subject: string;
    createdAt: string;
    url_image: string;
  };

  type ParamTypes = {
    id: string;
    userId: string;
  };

  const { id, userId } = useParams<ParamTypes>();

  const [data, setData] = useState<DataType>({
    info: '',
    subject: '',
    createdAt: '',
    url_image: ''
  });
  const [author, setAuthor] = useState('');

  useEffect(() => {
    const registerVisit = async () => {
      if (id && userId) {
        try {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const req = await axios.get(
            `/api/v1/analytics/viewed/announcements/${id}/paylocity/${userId}`
          );
        } catch (error) {
          window.location.href = `${process.env.REACT_APP_URL}`;
        }
      } else {
        window.location.href = `${process.env.REACT_APP_URL}`;
      }
    };
    const fetchAnnouncement = async () => {
      try {
        const req = await axios.get(`/api/v1/announcements/ap/${id}`);
        setData(req.data);
        setAuthor(req.data.created_by.full_name);
      } catch (error) {
        window.location.href = `${process.env.REACT_APP_URL}`;
      }
    };
    registerVisit();
    fetchAnnouncement();
  }, []);

  return (
    <div className='paylocity-mainContainer'>
      <img className='monograma' src={Monograma} alt='FEG logo' />
      <div className='container'>
        <div className='header'>
          <p>{data.createdAt}</p>
          <h3>Author: {author}</h3>
          <h2>{data.subject}</h2>
        </div>
        <div dangerouslySetInnerHTML={{ __html: `${data.info}` }} />
        {data.url_image.includes('1x1-00000000.png') ? null : (
          <img
            src={data.url_image}
            alt='Announcement'
            style={{ width: '256px' }}
          />
        )}
      </div>
    </div>
  );
};

export default PaylocityAnnouncement;
