import { Theme } from 'src/styles/Theme';
import styled from 'styled-components';

export const ContainerTarget = styled.div`
  margin: 0 auto;
  background-color: ${Theme['secondary-light']};
  padding: 12px;
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h1.text {
    color: #454545;
    text-align: center;
  }
  p.text {
    color: gray;
    text-align: center;
  }
  .full-legend{
    line-height: 10;
    color: red;
  }
  img {
    width: 300px;
    height: 300px;
  }
  gap: 8px;
  button {
    padding: 10px;
    background-color: ${Theme.primary};
    border: none;
    border-radius: 3px;
    color: white;
    cursor: pointer;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  video{
    width: 100%;
    max-height: 300px;
  }
  &.t {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: 0 auto;
    margin-bottom: 20px;

    .demo-wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-self: center;
    }
    div.edit-btn {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      gap: 10px;
    }
  }
`;

export const Grid = styled.div`
  display: flex;
  background-color: #f9f9f9f9;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .header {
    padding: 10px;
    background-color: #f1f1f1f1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
    button {
      padding: 6px;
      border: none;
      background-color: red;
      border-radius: 3px;
      color: white;
      cursor: pointer;
    }
    button.btn-edit {
      background-color: ${Theme.primary};
      color: white;
      margin-left: 8px;
    }
    button.active {
      background-color: green;
    }
  }
  .content {
    padding: 12px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h1.header-title{
      color: #303030;
      margin-bottom: 0 10px;
    }
    h1 {
      color: ${Theme.primary};
      text-align: center;
    }
    p.text {
      color: gray;
      text-align: center;
    }
    img {
      max-width: 100%;
      height: auto;
      max-height: 300px;
      object-fit: cover;
      border-radius: 12px;
      &.t {
        max-width: 100%;
        height: auto;
        max-height: 500px;
      }
    }
    .comments {
      textarea {
        width: 100%;
        height: 100px;
        background-color: #cfcfcf;
        padding: 10px;
        border-radius: 4px;
        border: none;
        font-family: sans-serif;
      }
      button.post{
        align-items: end;
        justify-self: end;
        align-self: flex-end;
        margin: 10px 0;
      }
      .comment-edit{
        display: flex;
        align-items: center;
        justify-content: center;
        small{
          margin: 0;
          padding: 0;
        }
      }
      width: 100%;
      .title-comments{
        margin-top: 4px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        div{
          display: flex;
          gap: 10px;
        }
        p{
          font-weight: bold;
        }
        .active{
          font-weight: bold;
          color: #b100cd;
        }
      }
      .comment-area{
        margin: 18px 0;
        padding: 10px 0;
        
        div{
          display: flex;
          gap: 14px;
          justify-content: space-between;
          label{
            display: flex;
            
            align-items: center;
          }
          small{
            
            font-weight: bold;
            color: #454545;
          }
          .main-comment{
            display: flex;
            justify-content: space-between;
            .icon-area{
              display: flex;
              flex-direction: row;
              gap: 4px;
              justify-content: center;
              align-items: center;
              width: 40px;
              margin: 0;
              padding: 0;
            }
            .icon-red{
              color: red;
            }
            .icon-gray{
              color: gray;
            }
            div{
              display: flex;
              flex-direction: column;
            }
            img{
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }
            display: flex;
          }
        
        }
      }
    }
    gap: 8px;
    button {
      padding: 10px;
      background-color: ${Theme.primary};
      border: none;
      border-radius: 3px;
      color: white;
      cursor: pointer;
    }
  }
`;
