import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Shortcut from '../../components/Home/Shortcuts';
import CompanyAnnoun from '../../components/Home/CompanyAnnoun';
import Calendar from '../../components/Home/Calendar';
import axios, { api } from '../../services/axios';
import Loader from 'src/components/Loader';
import { FaHeart, FaRegHeart } from "react-icons/fa";


import {
  ArrowLeft,
  ArrowLeftSharp,
  ArrowRight,
  ArrowRightSharp,
  Edit,
  Reorder
} from '@material-ui/icons';
import jwt from 'jsonwebtoken'
import { useCookies } from 'react-cookie'
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, Droppable, DroppableProvided, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { withStyles } from '@material-ui/styles';
import { Theme } from 'src/styles/Theme';
import { Container, GridShortcut, TitleShortcuts } from './styled';
import ListaOrdenada from './list';

const bgColor = Theme.primary
const textColor = '#808285';

const TableHeadContainer = withStyles(() => ({
  root: {
    backgroundColor: bgColor
  }
}))(TableHead);

const TableHeaderCell = withStyles(() => ({
  root: {
    color: 'white'
  }
}))(TableCell);

const TableBodyCell = withStyles(() => ({
  root: {
    color: textColor
  }
}))(TableCell);

interface ShortcutInterface {
  id: string;
  name: string;
  display_name1: string;
  display_name2: string;
  desktop_image: string;
  mobile_image: string;
  link_desktop: string;
  link_mobile: string;
  createdAt: string;
  updatedAt?: Date;
  order: number;
  group_ids: string[];
  favorite: boolean
}

const Shortcuts: React.FC = () => {
  const [cookies,] = useCookies(['token'])
  const [loaded, setLoaded] = useState(false);
  const [shortcuts, setShortcuts] = useState<ShortcutInterface[]>([]);
  const [favShortcut, setFavShortcut] = useState<string[]>([]);
  const [shortcutsOrdered, setShortcutsOrdered] = useState<ShortcutInterface[]>([]);
  const [limit, setLimit] = useState(0);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [customerId, setCustomerId] = useState('')
  const [userGroupId, setUserGroupId] = useState('')
  const [editOrder, setEditOrder] = useState<boolean>(false)
  const [shortcutOrder, setShortcutOrder] = useState<number[]>([])
  const [firstAccess, setFirstAccess] = useState(false)
  const token = cookies.token
  const [heart, setHeart] = useState(false);
  const [currentLetr, setCurrentLetr] = useState(0);
  

  // useEffect(() => {
  //   ReactGA.initialize('UA-189380132-1');
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  //   handlePermissions()
  // });
  function orderForName(objetos: ShortcutInterface[] | undefined) {

    if(objetos) {
      return objetos.sort((a, b) => {
        const nameA = a.display_name2.toUpperCase();
        const nameB = b.display_name2.toUpperCase();
        
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    } else {
      return []
    }
  }

  function checkFav(id: string){
    if(!favShortcut){
      return 
    }
    if(favShortcut === null || favShortcut === undefined){
      return 
    }
    if(favShortcut.length > 0){
      return favShortcut.find(item => item === id)
    }
    
  }
  function handlePermissions() {
    if (localStorage.getItem('token')) {
      jwt.verify(
        localStorage.getItem('token')!.toString(),
        '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
        async (err: any, decoded: any) => {
          if (err) {
            alert(err)
          } else {
            /*if (decoded.roles.home_page.view === false) {
              alert('Permission denied')
              if (process.env.REACT_APP_URL) {
                window.location.href = `${process.env.REACT_APP_URL}`
              }
            } else {*/
              setUserGroupId(decoded.group_id)
              setCustomerId(decoded.id)

              axios.get(`/api/v1/shortcuts/personalOrder/${decoded.id}`).then(response => {
                setShortcutOrder(response.data.shortcuts_order)
                const customer_id = localStorage.getItem('id');
                axios.get(`/api/v1/accounts/favorite_shortcut/${customer_id}`).then((shortcutsResponse) => {
                  setFavShortcut(shortcutsResponse.data)
                })

                axios.get(`/api/v1/shortcuts`).then((shortcutsResponse) => {
                  setShortcuts(shortcutsResponse.data);
                  changeShortcutOrder(shortcutsResponse.data, response.data.shortcuts_order, decoded.group_id)
                  setLoaded(true)
                })
              })
            /*}*/
          }
        }
      )
    } else {
      jwt.verify(
        token!.toString(),
        '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
        async (err: any, decoded: any) => {
          if (err) {
            alert(err)
          } else {
            if (decoded.roles.home_page.view === false) {
              alert('Permission denied');
              if (process.env.REACT_APP_URL) {
                window.location.href = `${process.env.REACT_APP_URL}`
              }
            } else {
              setUserGroupId(decoded.group_id)
              setCustomerId(decoded.id)

              axios.get(`/api/v1/shortcuts/personalOrder/${decoded.id}`).then(response => {
                setShortcutOrder(response.data.shortcuts_order)

                axios.get(`/api/v1/shortcuts`).then((shortcutsResponse) => {
                  setShortcuts(shortcutsResponse.data);
                  changeShortcutOrder(shortcutsResponse.data, response.data.shortcuts_order, decoded.group_id)
                  setLoaded(true)
                })
              })
            }
          }
        }
      )
    }
  }

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };


  function verifyOrder(table_order: number[], personal_order: number[]) {
    let newOrder: number[] = []
    let result = ''

    if (personal_order) {

      table_order.map((number, index) => {
        if (!personal_order.includes(number)) {
          result = 'table order have more card'
          // add card in the end of personal order
          newOrder = personal_order
          newOrder.push(number)
        }
      })

      personal_order.map((number, index) => {
        if (!table_order.includes(number)) {
          result = 'personal order have more card'
          // remove card from the personal order
          newOrder = personal_order
          newOrder.splice(index, 1)
        }
      })

      if (result === '') newOrder = personal_order

    } else {
      newOrder = table_order
    }

    return newOrder
  }

  function changeShortcutOrder(shortcuts: ShortcutInterface[], order: number[], group_id: string) {
    const shortcuts_filtered = shortcuts.filter((shortcut) => shortcut.group_ids?.includes(group_id))
    let orderedShorts: ShortcutInterface[] = []

    verifyOrder(shortcuts_filtered.map(shortcut => shortcut.order), order)

    if (order && order.length > 0) {
      order.map(order => {
        shortcuts.forEach((shortcut) => {
          if (shortcut.order === order) {
            orderedShorts.push(shortcut)
          }
        })
      })
      setShortcutsOrdered(orderedShorts)
      setShortcutOrder(order)
    } else {
      setShortcutsOrdered(shortcuts_filtered)
    }
  }



  const handleSearch = (e: string) => {

    if (e.length >= 3) {
      setLoaded(false);
      axios.get(`/api/v1/shortcuts/byName/${e}`).then((shortcutsResponse) => {
        setLimit(0);
        setShortcuts((shortcutsResponse.data));
        setLoaded(true);
      });
    } else if (e.length === 0) {
      setLoaded(false);
      axios.get(`/api/v1/shortcuts`).then((shortcutsResponse) => {
        setShortcuts(shortcutsResponse.data);
        setLoaded(true);
      });
    }
  };

  const handleLimit = (newLimit: number) => {
    setLoaded(false);
    setTimeout(() => {
      setLimit(newLimit);
      setLoaded(true);
    }, 800);
  };

  useEffect(() => {
    ReactGA.initialize('UA-189380132-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    handlePermissions()
    // handleLoad();
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const displayShortcuts = () => {
    if (shortcutsOrdered.length >= 6) {
      return (
        <>
          <Grid container spacing={3}>
            {shortcutsOrdered
              .slice(limit, limit + 10)
              .map((shortcut: ShortcutInterface, key) => {
                if (shortcuts.indexOf(shortcut) === 3) {
                  return (
                    <>
                      <Grid item lg={4} md={6} xs={4} key={key}>
                        <Shortcut
                          analytics={shortcut.name}
                          href={width > 768 ? shortcut.link_desktop  : shortcut.link_mobile}
                          images={
                            width > 768
                              ? /(jpg|gif|png|JPG|GIF|PNG|JPEG|jpeg)$/.test(
                                shortcut.desktop_image
                              )
                                ? shortcut.desktop_image
                                : 'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/static/feghome.com.webp'
                              : /(jpg|gif|png|JPG|GIF|PNG|JPEG|jpeg)$/.test(
                                shortcut.mobile_image
                              )
                                ? shortcut.mobile_image
                                : 'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/static/feghome.com.webp'
                          }
                          alt={shortcut.name}
                          actions={shortcut.display_name1}
                          title={shortcut.display_name2}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={4}>
                        <Calendar />
                      </Grid>
                    </>
                  );
                } else if (shortcutsOrdered.indexOf(shortcut) === 5) {
                  return (
                    <>
                      <Grid item lg={4} md={6} xs={4} key={key}>
                        <Shortcut
                          analytics={shortcut.name}
                          href={width > 768 ? shortcut.link_desktop  : shortcut.link_mobile}
                          images={
                            width > 768
                              ? /(jpg|gif|png|JPG|GIF|PNG|JPEG|jpeg)$/.test(
                                shortcut.desktop_image
                              )
                                ? shortcut.desktop_image
                                : 'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/static/feghome.com.webp'
                              : /(jpg|gif|png|JPG|GIF|PNG|JPEG|jpeg)$/.test(
                                shortcut.mobile_image
                              )
                                ? shortcut.mobile_image
                                : 'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/static/feghome.com.webp'
                          }
                          alt={shortcut.name}
                          actions={shortcut.display_name1}
                          title={shortcut.display_name2}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={4}>
                        <CompanyAnnoun />
                      </Grid>
                    </>
                  );
                } else {
                  return (
                    <Grid item lg={4} md={6} xs={4} key={key}>
                      <Shortcut
                        analytics={shortcut.name}
                        href={width > 768 ? shortcut.link_desktop  : shortcut.link_mobile}
                        images={
                          width > 768
                            ? /(jpg|gif|png|JPG|GIF|PNG|JPEG|jpeg)$/.test(
                              shortcut.desktop_image
                            )
                              ? shortcut.desktop_image
                              : 'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/static/feghome.com.webp'
                            : /(jpg|gif|png|JPG|GIF|PNG|JPEG|jpeg)$/.test(
                              shortcut.mobile_image
                            )
                              ? shortcut.mobile_image
                              : 'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/static/feghome.com.webp'
                        }
                        alt={shortcut.name}
                        actions={shortcut.display_name1}
                        title={shortcut.display_name2}
                      />
                    </Grid>
                  );
                }
              })}
          </Grid>
          <div className='paginationSection'>
            <div className='paginationButtons'>
              <IconButton
                aria-label='first_page'
                size='medium'
                onClick={() => handleLimit(0)}
                disabled={limit === 0}
              >
                <ArrowLeftSharp />
              </IconButton>
              <IconButton
                aria-label='previous_page'
                size='medium'
                onClick={() => handleLimit(limit - 10)}
                disabled={limit === 0}
              >
                <ArrowLeft />
              </IconButton>
              <IconButton
                aria-label='next_page'
                size='medium'
                onClick={() => handleLimit(limit + 10)}
                disabled={limit >= shortcuts.length - 10}
              >
                <ArrowRight />
              </IconButton>
              <IconButton
                aria-label='lasts_page'
                size='medium'
                onClick={() => handleLimit(shortcuts.length - 10)}
                disabled={limit >= shortcuts.length - 10}
              >
                <ArrowRightSharp />
              </IconButton>
            </div>
          </div>
        </>
      );
    } else if (shortcutsOrdered.length >= 1 && shortcutsOrdered.length < 6) {
      return (
        <Grid container spacing={3}>
          {shortcutsOrdered
            .slice(limit, limit + 10)
            .map((shortcut: ShortcutInterface, key) => {
              return (
                <>
                  <Grid item lg={4} md={6} xs={4} key={key}>
                    <Shortcut
                      analytics={shortcut.name}
                      href={width > 768 ? shortcut.link_desktop  : shortcut.link_mobile}
                      images={
                        /(jpg|gif|png|JPG|GIF|PNG|JPEG|jpeg)$/.test(
                          shortcut.desktop_image
                        )
                          ? shortcut.desktop_image
                          : 'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/static/feghome.com.webp'
                      }
                      alt={shortcut.name}
                      actions={shortcut.display_name1}
                      title={shortcut.display_name2}
                    />
                  </Grid>
                </>
              );
            })}
          <Grid item lg={4} md={6} xs={4}>
            <Calendar />
          </Grid>
          <Grid item lg={4} md={6} xs={4}>
            <CompanyAnnoun />
          </Grid>
        </Grid>
      );
    } else if (shortcutsOrdered.length === 0) {
      return (
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={4}>
            <Calendar />
          </Grid>
          <Grid item lg={4} md={6} xs={4}>
            <CompanyAnnoun />
          </Grid>
        </Grid>
      );
    }
  };

  function moveArrayElement(arr: any, from: any, to: any) {
    var el = arr[from];
    arr.splice(from, 1);
    arr.splice(to, 0, el);
  };

  const handleDragEnd = async (result: DropResult, provided?: ResponderProvided) => {
    if (!result.destination) return
    if (result.destination.index === result.source.index) return

    let order: number[] = shortcutsOrdered.map(shortcut => shortcut.order)
    const old_order = [...order]
    moveArrayElement(order, result.source.index, result.destination?.index)

    changeShortcutOrder(shortcutsOrdered, order, userGroupId)
    await axios.put(`api/v1/shortcuts/personalOrder/${customerId}`, { order })
      .then(r => { })
      .catch(error => {
        changeShortcutOrder(shortcutsOrdered, old_order, userGroupId)
      })
  }
  const [hoveredIndex, setHoveredIndex] = useState(0);

  const handleHover = (index: any) => {
    setHoveredIndex(index);
  };


  const orderModal = (
    <div style={{ display: editOrder ? 'block' : 'none' }} className='modal'>
      <div className='modal-content'>
        <div className='modal-header'>
          <span className='close' onClick={() => setEditOrder(false)}>&times;</span>

          <h3>Personal order</h3>
        </div>

        <div className='tableSection' style={{ padding: 20 }}>
          <TableContainer component={Paper}>
            <Table size='small' aria-label='Shortcuts table'>
              <TableHeadContainer>
                <TableRow>
                  <TableHeaderCell align='center'>&nbsp;</TableHeaderCell>
                  <TableHeaderCell align='center'>Shortcut Name</TableHeaderCell>
                  <TableHeaderCell align='center'>Display Name 01</TableHeaderCell>
                  <TableHeaderCell align='center'>Display Name 02</TableHeaderCell>
                  <TableHeaderCell align='center'>Order</TableHeaderCell>
                </TableRow>
              </TableHeadContainer>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId='droppable' direction='vertical'>
                  {(droppableProvided: DroppableProvided) => (
                    <TableBody
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                    >
                      {shortcutsOrdered.map((shortcut, index: number) => (
                        <Draggable
                          key={shortcut.id}
                          draggableId={shortcut.id}
                          index={index}
                        >
                          {(
                            draggableProvided: DraggableProvided,
                            snapshot: DraggableStateSnapshot
                          ) => {
                            return (
                              <TableRow
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                style={{
                                  ...draggableProvided.draggableProps.style,
                                  background: snapshot.isDragging
                                    ? 'rgba(245,245,245, 0.75)'
                                    : 'none',
                                }}
                              >
                                <TableCell align='center'>
                                  <div {...draggableProvided.dragHandleProps}>
                                    <Reorder />
                                  </div>
                                </TableCell>
                                <TableBodyCell align='center'>{shortcut.name}</TableBodyCell>
                                <TableBodyCell align='center'>{shortcut.display_name1}</TableBodyCell>
                                <TableBodyCell align='center'>{shortcut.display_name2}</TableBodyCell>
                                <TableBodyCell align='center'>{shortcut.order}</TableBodyCell>
                              </TableRow>
                            );
                          }}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  )
  function formatString(str: string) {
    if(str.length > 18){
      return`${str.slice(0, 18)}...`
    } else {
      return str
    }
  }
  function favoriteShortCurt (short: ShortcutInterface) {
    let target: any = shortcuts.find( shortcut =>  shortcut.id === short.id )
    

    if(target) {
      target.mobile_image = {
        "selectedFile": "",
        "type": ""
      } 
      target.desktop_image = {
        "selectedFile": "",
      "type": ""
      }
      const customer_id = localStorage.getItem('id');
      api.put(`/api/v1/accounts/${customer_id}/favorite_shortcut`, target).then(res => {
          axios.get(`/api/v1/shortcuts`).then((shortcutsResponse) => {
          setShortcuts(shortcutsResponse.data);
        })
        axios.get(`/api/v1/accounts/favorite_shortcut/${customer_id}`).then((shortcutsResponse) => {
          setFavShortcut(shortcutsResponse.data)
        })

      }
      
        ).catch(e => console.log(e))
    }
    
  }

  function renderFav() {
    console.log(favShortcut?.length)
    console.log(favShortcut)
    if(!favShortcut){
      return []
    }
    if(favShortcut === null || favShortcut === undefined){
      return []
    }
    if(shortcuts.length > 0 && favShortcut?.length > 0){
      const result = shortcuts.filter((element) =>
      favShortcut.includes(element.id)
    )
    return result;
    } else {
      return []
    }


   
  }
  function removeFav() {
    if(!favShortcut){
      return shortcuts
    }
    if(favShortcut === null || favShortcut === undefined){
      return shortcuts
    }
    if(shortcuts.length > 0 && favShortcut.length > 0){
      const result = shortcuts.filter(objeto => !favShortcut.includes(objeto.id));
      return result;
    } else if (shortcuts.length > 0) {
      console.log('aqui')
      return shortcuts
    }

  }
  return (
    <div className='HomeContent'>
      <div className=''>
      
        <TitleShortcuts>
          <h1>FEG Approved Apps</h1>
          <div>
            <input
              type='text'
              name='search'
              placeholder='Search..'
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
        </TitleShortcuts>

        {loaded ?(
          <div>
            <h2 style={{color:`${Theme.grey}`, fontSize: 24}}>Favorites</h2>
            <Container>
              {/* order for name and remove non favorites */}
              {orderForName(renderFav()).map((shortcut, index) => {
                return(
                    <GridShortcut key={Math.random()}
                    onMouseEnter={() =>
                      handleHover(index + 1)
                    }
                    onMouseLeave={() => handleHover(0)}
                    className={`
                      ${hoveredIndex === index + 1 &&
                      'active'
                      }           
                    `}>                
                        <img src={shortcut.desktop_image} alt="" />
                        <div className="legend">
                          <div className="legend-title">
                   
                          <p> {shortcut.display_name1}</p>
                          <a href={shortcut.link_desktop} target='_blanck' >{shortcut.display_name2}</a>         
                          </div>
                        </div>
                        <div className='div-heart' onClick={() => favoriteShortCurt(shortcut)}>
                          {checkFav(shortcut.id) ? <FaHeart color='purple' />: <FaRegHeart color='purple' />}
                        </div> 
                    </GridShortcut>
                  )     
                })  
              }
            </Container>
            {<ListaOrdenada dados={orderForName(removeFav())} checkFav={checkFav} favoriteShortCurt={favoriteShortCurt} formatString={formatString}/>}
          </div>
        )
        
        : <Loader />}
      </div>
      {orderModal}
    </div>
  );
};

export default Shortcuts;
