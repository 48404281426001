import React, { ChangeEvent, useEffect, useState } from 'react';
import useWindowWith from 'src/utils/width';
import axios from '../../services/axios';
import * as S from './styled';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';

interface jobsTitleProps {
  group_title: string | unknown;
  paylocity_jobtitle: string;
}

interface Group {
  id: string;
  name: string;
  profile_id: string;
}
interface SubmitProps {
  newJobtitle: boolean;
  index: number;
}

const MapJobtitle: React.FC = () => {
  const [jobTitles, setJobTitles] = useState<jobsTitleProps[]>([]);
  const [newJobtitles, setNewJobtitles] = useState<jobsTitleProps[]>([]);
  const [nav, setNav] = useState(true);
  const [groups, setGroups] = useState<Group[]>([]);
  const [relod, setRelod] = useState(true);
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
  const width = useWindowWith();

  useEffect(() => {
    axios.get(`api/v1/groups`).then((res) => {
      setGroups(res.data);
      console.log('groups', res.data);
    });
    axios.get(`api/v1/groups/unexpected`).then((res) => {
      console.log(res.data);
      setNewJobtitles(res.data);
    });
    axios.get(`api/v1/groups/mapjobtitle`).then((res) => {
      console.log(res.data);
      setJobTitles(res.data);
    });
    setRelod(false);
  }, [relod]);

  const changeElement = ({ value }: { value: unknown | string }) => {
    const newArray = [...jobTitles];
    newArray[0].group_title = value;
    setJobTitles(newArray);
  };

  const submitPutGroup = ({ index, newJobtitle }: SubmitProps): any => {
    if (!selectedGroups[index]) {
      return;
    }
    const data = {
      paylocity_jobtitle: newJobtitle
        ? newJobtitles[index].paylocity_jobtitle
        : jobTitles[index].paylocity_jobtitle,
      group_title: selectedGroups[index]
    };
    console.log(data);
    axios
      .post('api/v1/groups/new/group_title', { ...data })
      .then((res) => setRelod(true))
      .catch((e) => console.log(e));
  };

  return (
    <S.MapJobtitle>
      <div className='content-groups'>
        {nav ? (
          <div className='div-content'>
            <div className='title'>
              <h1>All Paylocity JobTitles</h1>
              <Button
                color='primary'
                variant='contained'
                onClick={() => setNav(false)}
              >
                New Paylocity JobTitles
              </Button>
            </div>
            <hr />

            <div>
              {jobTitles.map((job, index) => (
                <div className='groups-content'>
                  {width > 1200 && <p>{job.paylocity_jobtitle}</p>}
                  <FormControl>
                    <InputLabel id='demo-simple-select-label'>
                      {job.paylocity_jobtitle}
                    </InputLabel>
                    <Select
                      value={selectedGroups[index] || job.group_title}
                      onChange={(e: ChangeEvent<{ value: unknown }>) => {
                        const updatedGroups = [...selectedGroups];
                        updatedGroups[index] = e.target.value as Group;
                        setSelectedGroups(updatedGroups);
                      }}
                    >
                      {groups?.map((item) => (
                        <MenuItem value={item.name}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() =>
                      submitPutGroup({ index, newJobtitle: false })
                    }
                  >
                    Update
                  </Button>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className='div-content'>
            <div className='title'>
              <h1>New Paylocity JobTitles</h1>
              <Button
                color='primary'
                variant='contained'
                onClick={() => setNav(true)}
              >
                Paylocity JobTitles
              </Button>
            </div>
            <hr />

            <div>
              {newJobtitles.map((job, index) => (
                <div className='groups-content'>
                  {width > 1200 && <p>{job.paylocity_jobtitle}</p>}
                  <FormControl>
                    <InputLabel id='demo-simple-select-label'>
                      {job.paylocity_jobtitle}
                    </InputLabel>
                    <Select
                      value={selectedGroups[index] || job.group_title}
                      onChange={(e: ChangeEvent<{ value: unknown }>) => {
                        const updatedGroups = [...selectedGroups];
                        updatedGroups[index] = e.target.value as Group;
                        setSelectedGroups(updatedGroups);
                      }}
                    >
                      {groups?.map((item) => (
                        <MenuItem value={item.name}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => submitPutGroup({ index, newJobtitle: true })}
                  >
                    Update
                  </Button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </S.MapJobtitle>
  );
};

export default MapJobtitle;
