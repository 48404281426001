import { Theme } from 'src/styles/Theme';
import styled from 'styled-components';

export const Container = styled.div`
  width: 86%;
  margin: 0 auto;
  height: 500px;
  display: grid;
  grid-template-columns: 1fr 4fr auto;
  border: 2px solid ${Theme.secondary};
  background-color: ${Theme.secondary};
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
  h1 {
    margin-bottom: 16px;
  }
`;
export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Theme.light};
  border-bottom: 3px solid ${Theme.secondary};
  max-height: 50px;
  display: flex;

  div {
    color: ${Theme.primary};
    box-shadow: none;
    font-size: 26px;
    font-weight: bold;
    max-height: 47px;
    height: 100%;
    border-radius: 0;
  }

  align-items: center;
  input {
    border: 1px solid ${Theme.grey};
    padding: 6px;
  }
`;

export const ListLocations = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Theme.light};
  color: ${Theme.grey};
  overflow-y: scroll;
`;
export const Header = styled.div`
  width: 100%;
  border-bottom: 3px solid ${Theme.secondary};
  background-color: ${Theme.primary};

  color: ${Theme.secondary};
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  max-height: 50px;
  height: 100%;
  h1 {
    margin: 0;
    font-size: 20px;
  }
`;

export const LocationGroup = styled.div`
  border-bottom: 3px solid ${Theme.secondary};
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: 1px solid ${Theme.primary};
    padding: 2px;
    margin-right: 8px;
  }

  background-color: ${Theme.light};
  svg {
    color: ${Theme.primary};
  }
  :hover {
    background: #003366;
    color: white;
  }
`;
export const ChatUserLocation = styled.div`
  background-color: ${Theme.light};
  border-left: 3px solid ${Theme.secondary};
  max-height: 500px;
`;

export const HeaderChat = styled.div`
  background-color: white;
  border-bottom: 3px solid ${Theme.secondary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 50px;
  height: 100%;
  padding: 0 16px;
  gap: 12px;
  h1 {
    margin: 0;
    color: ${Theme.primary};
  }
  img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: 1px solid ${Theme.light};
    padding: 2px;
  }
`;

export const Informations = styled.div`
  width: 100%;
  min-width: 200px;
  background-color: ${Theme.light};
  border-left: 3px solid ${Theme.secondary};
  max-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const InformationsContent = styled.div`
  width: 100%;
  background-color: ${Theme.light};
  padding: 10px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-top: 12px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    border: 1px solid ${Theme.light};
    padding: 2px;
    margin-right: 8px;
  }
  div.user-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
      color: ${Theme.primary};
    }
  }
`;
