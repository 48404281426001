import React, { FC, useEffect, useState } from 'react'
import { createStyles, FormControl, InputLabel, makeStyles, MenuItem, Select, Theme, Button, withStyles } from '@material-ui/core'
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, Checkbox } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie'
import axios from '../../services/axios'
import jwt from 'jsonwebtoken'

const bgColor = '#7f43f5'
const hoverColor = '#6535e9'
const textColor = '#fff'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    table: {
      minWidth: 650,
    },
    header: {
      display: 'flex',
      justifyContent: "space-between",
      alignItems: 'center'
    },
    tableHeader: {
      backgroundColor: bgColor
    }
  }),
);

const TableHeaderCell = withStyles(() => ({
  root: {
    color: textColor
  }
}))(TableCell)

const SaveButton = withStyles(() => ({
  root: {
    backgroundColor: bgColor,
    color: textColor,
    '&:hover': { backgroundColor: hoverColor }
  }
}))(Button)

type Profile = {
  id: string
  name: string
}

type Group = {
  id: string
  name: string
  profile_id: string
}

type LevelAccess = {
  group_id: string
  namegroup: string
  modules: any
  roles: any
}

type Roles = {
  name: string
  roles: {
    view: boolean,
    add: boolean,
    edit: boolean,
    delete: boolean
  }
}

const Permission: FC = () => {
  const classes = useStyles()
  const [groups, setGroups] = useState<Group[]>([])
  const [profiles, setProfiles] = useState<Profile[]>([])
  const [selectGroup, setSelectGroup] = useState<string>('')
  const [selectProfile, setSelectProfile] = useState<string>('')
  const [newRoles, setNewRoles] = useState<Roles[]>()
  const [permissions, setPermissions] = useState({
    view: false,
    add: false,
    edit: false,
    delete: false
  })

  const [loadingChecks, setLoadingChecks] = useState(true)
  const [loadingSave, setLoadingSave] = useState(false)

  const [homeView, sethomeView] = useState(false)
  const [homeAdd, sethomeAdd] = useState(false)
  const [homeEdit, sethomeEdit] = useState(false)
  const [homeDelete, sethomeDelete] = useState(false)

  const [announcementsView, setannouncementsView] = useState(false)
  const [announcementsAdd, setannouncementsAdd] = useState(false)
  const [announcementsEdit, setannouncementsEdit] = useState(false)
  const [announcementsDelete, setannouncementsDelete] = useState(false)

  const [userValidationView, setuserValidationView] = useState(false)
  const [userValidationAdd, setuserValidationAdd] = useState(false)
  const [userValidationEdit, setuserValidationEdit] = useState(false)
  const [userValidationDelete, setuserValidationDelete] = useState(false)

  const [userManagementView, setuserManagementView] = useState(false)
  const [userManagementAdd, setuserManagementAdd] = useState(false)
  const [userManagementEdit, setuserManagementEdit] = useState(false)
  const [userManagementDelete, setuserManagementDelete] = useState(false)

  const [locationView, setlocationView] = useState(false)
  const [locationAdd, setlocationAdd] = useState(false)
  const [locationEdit, setlocationEdit] = useState(false)
  const [locationDelete, setlocationDelete] = useState(false)

  const [eventsView, seteventsView] = useState(false)
  const [eventsAdd, seteventsAdd] = useState(false)
  const [eventsEdit, seteventsEdit] = useState(false)
  const [eventsDelete, seteventsDelete] = useState(false)

  const [analyticsView, setanalyticsView] = useState(false)
  const [analyticsAdd, setanalyticsAdd] = useState(false)
  const [analyticsEdit, setanalyticsEdit] = useState(false)
  const [analyticsDelete, setanalyticsDelete] = useState(false)

  const [shortcutsView, setshortcutsView] = useState(false)
  const [shortcutsAdd, setshortcutsAdd] = useState(false)
  const [shortcutsEdit, setshortcutsEdit] = useState(false)
  const [shortcutsDelete, setshortcutsDelete] = useState(false)

  const [permissionManagementView, setpermissionManagementView] = useState(false)
  const [permissionManagementAdd, setpermissionManagementAdd] = useState(false)
  const [permissionManagementEdit, setpermissionManagementEdit] = useState(false)
  const [permissionManagementDelete, setpermissionManagementDelete] = useState(false)

  const [askFegView, setAskFegView] = useState(false)
  const [askFegAdd, setAskFegAdd] = useState(false)
  const [askFegEdit, setAskFegEdit] = useState(false)
  const [askFegDelete, setAskFegDelete] = useState(false)

  const [askRepositoryView, setAskRepositoryView] = useState(false);
  const [askRepositoryAdd, setAskRepositoryAdd] = useState(false);
  const [askRepositoryDelete, setAskRepositoryDelete] = useState(false);
  const [askRepositoryEdit, setAskRepositoryEdit] = useState(false);


  const [askLogsView, setAskLogsView] = useState(false);
  const [askLogsAdd, setAskLogsAdd] = useState(false);
  const [askLogsEdit, setAskLogsEdit] = useState(false);
  const [askLogsDelete, setAskLogsDelete] = useState(false);

  const [askDocsView, setAskDocsView] = useState(false);
  const [askDocsAdd, setAskDocsAdd] = useState(false);
  const [askDocsEdit, setAskDocsEdit] = useState(false);
  const [askDocsDelete, setAskDocsDelete] = useState(false);


  const [cookies, ,] = useCookies(['token']);
  const token = cookies.token
  const history = useHistory()

  const PurpleCheckbox = withStyles(() => ({
    root: {
      color: bgColor
    }
  }))(Checkbox)

  useEffect(() => {
    handlePermissions()
    handleLoad()
  }, [])

  function handlePermissions() {
    if (localStorage.getItem('token')) {
      jwt.verify(
        localStorage.getItem('token')!.toString(),
        '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
        (err: any, decoded: any) => {
          if (err) {
            alert(err)
          } else {
            if (decoded.roles.permissions_management.view === false) {
              alert('Permission denied')
              if (process.env.REACT_APP_URL) {
                window.location.href = `${process.env.REACT_APP_URL}`
              }
            } else {
              setPermissions(decoded.roles.permissions_management)
            }
          }
        }
      )
    } else {
      jwt.verify(
        token!.toString(),
        '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
        (err: any, decoded: any) => {
          if (err) {
            alert(err)
          } else {
            if (decoded.roles.permissions_management.view === false) {
              alert('Permission denied');
              if (process.env.REACT_APP_URL) {
                window.location.href = `${process.env.REACT_APP_URL}`
              }
            } else {
              setPermissions(decoded.roles.permissions_management)
            }
          }
        }
      )
    }
  }

  function handleLoad() {
    axios.get('api/v1/profiles').then((response) => {
      const profiles: Profile[] = response.data
      const profiles_filtered = profiles.filter((profile: Profile) => profile.id === '2f305f43-c236-4a93-8a1c-c365f296190d' || profile.id === 'c843021e-0c33-40cd-a0d2-0ec36fa1bb7a')
      setProfiles(profiles_filtered)
    })
  }

  function handleProfileSelect(profile_id: any) {
    if (profile_id !== selectProfile) {
      setSelectGroup('')
      setLoadingChecks(true)
      setAllChecksToFalse()
      setSelectProfile(profile_id)
      axios.get('api/v1/groups').then((response) => {
        const groups = response.data
        setGroups(groups.filter((group: Group) => group.profile_id === profile_id))
      })
    } else {
      setSelectProfile('')
      setGroups([])
      setLoadingChecks(true)
      setAllChecksToFalse()
    }
  }

  function handleGroupSelect(group_id: any) {
    if (group_id !== selectGroup && group_id !== '') {
      setAllChecksToFalse()
      setLoadingChecks(true)
      setSelectGroup(group_id)
      axios.get('api/v1/permissions').then((response) => {
        const allAccess: LevelAccess[] = response.data
        let newRoles: Roles[] = []
        let oneAccess = allAccess.filter((data: LevelAccess) => data.group_id === group_id)[0]
        for (let page in oneAccess.roles) {
          newRoles.push({
            name: page,
            roles: {
              view: oneAccess.roles[page].view,
              add: oneAccess.roles[page].add,
              edit: oneAccess.roles[page].edit,
              delete: oneAccess.roles[page].delete,
            }
          })
        }
        setNewRoles(newRoles)
        loadChecks(newRoles)
      })
    } else {
      setSelectGroup('')
      setLoadingChecks(true)
      setAllChecksToFalse()
    }
  }

  function setAllChecksToFalse() {
    sethomeView(false)
    sethomeAdd(false)
    sethomeEdit(false)
    sethomeDelete(false)
    setannouncementsView(false)
    setannouncementsAdd(false)
    setannouncementsEdit(false)
    setannouncementsDelete(false)
    setuserValidationView(false)
    setuserValidationAdd(false)
    setuserValidationEdit(false)
    setuserValidationDelete(false)
    setuserManagementView(false)
    setuserManagementAdd(false)
    setuserManagementEdit(false)
    setuserManagementDelete(false)
    setlocationView(false)
    setlocationAdd(false)
    setlocationEdit(false)
    setlocationDelete(false)
    seteventsView(false)
    seteventsAdd(false)
    seteventsEdit(false)
    seteventsDelete(false)
    setanalyticsView(false)
    setanalyticsAdd(false)
    setanalyticsEdit(false)
    setanalyticsDelete(false)
    setshortcutsView(false)
    setshortcutsAdd(false)
    setshortcutsEdit(false)
    setshortcutsDelete(false)
    setpermissionManagementView(false)
    setpermissionManagementAdd(false)
    setpermissionManagementEdit(false)
    setpermissionManagementDelete(false)
    setAskFegView(false)
    setAskFegAdd(false)
    setAskFegEdit(false)
    setAskFegDelete(false)
    setAskRepositoryAdd(false)
    setAskRepositoryView(false)
    setAskRepositoryEdit(false)
    setAskRepositoryDelete(false)
    setAskLogsAdd(false)
    setAskLogsDelete(false)
    setAskLogsView(false)
    setAskLogsEdit(false)
    setAskDocsView(false)
    setAskDocsDelete(false)
    setAskDocsEdit(false)
    setAskDocsAdd(false)
  }

  function loadChecks(roles: Roles[]) {
    roles.map((oneRoles) => {
      if (oneRoles.name === 'home_page') {
        sethomeView(oneRoles.roles.view)
        sethomeAdd(oneRoles.roles.add)
        sethomeEdit(oneRoles.roles.edit)
        sethomeDelete(oneRoles.roles.delete)
      }

      if (oneRoles.name === 'announcements') {
        setannouncementsView(oneRoles.roles.view)
        setannouncementsAdd(oneRoles.roles.add)
        setannouncementsEdit(oneRoles.roles.edit)
        setannouncementsDelete(oneRoles.roles.delete)
      }

      if (oneRoles.name === 'user_validation') {
        setuserValidationView(oneRoles.roles.view)
        setuserValidationAdd(oneRoles.roles.add)
        setuserValidationEdit(oneRoles.roles.edit)
        setuserValidationDelete(oneRoles.roles.delete)
      }

      if (oneRoles.name === 'user_management') {
        setuserManagementView(oneRoles.roles.view)
        setuserManagementAdd(oneRoles.roles.add)
        setuserManagementEdit(oneRoles.roles.edit)
        setuserManagementDelete(oneRoles.roles.delete)
      }

      if (oneRoles.name === 'location') {
        setlocationView(oneRoles.roles.view)
        setlocationAdd(oneRoles.roles.add)
        setlocationEdit(oneRoles.roles.edit)
        setlocationDelete(oneRoles.roles.delete)
      }

      if (oneRoles.name === 'events') {
        seteventsView(oneRoles.roles.view)
        seteventsAdd(oneRoles.roles.add)
        seteventsEdit(oneRoles.roles.edit)
        seteventsDelete(oneRoles.roles.delete)
      }

      if (oneRoles.name === 'analytics') {
        setanalyticsView(oneRoles.roles.view)
        setanalyticsAdd(oneRoles.roles.add)
        setanalyticsEdit(oneRoles.roles.edit)
        setanalyticsDelete(oneRoles.roles.delete)
      }

      if (oneRoles.name === 'shortcuts') {
        setshortcutsView(oneRoles.roles.view)
        setshortcutsAdd(oneRoles.roles.add)
        setshortcutsEdit(oneRoles.roles.edit)
        setshortcutsDelete(oneRoles.roles.delete)
      }

      if (oneRoles.name === 'permissions_management') {
        setpermissionManagementView(oneRoles.roles.view)
        setpermissionManagementAdd(oneRoles.roles.add)
        setpermissionManagementEdit(oneRoles.roles.edit)
        setpermissionManagementDelete(oneRoles.roles.delete)
      }
      if (oneRoles.name === 'chat_feg') {
        setAskFegView(oneRoles.roles.view)
        setAskFegAdd(oneRoles.roles.add)
        setAskFegEdit(oneRoles.roles.edit)
        setAskFegDelete(oneRoles.roles.delete)
      }
      if (oneRoles.name === 'chat_feg_repository') {
        setAskRepositoryView(oneRoles.roles.view)
        setAskRepositoryAdd(oneRoles.roles.add)
        setAskRepositoryEdit(oneRoles.roles.edit)
        setAskRepositoryDelete(oneRoles.roles.delete)
      }
      if (oneRoles.name === 'chat_feg_logs') {
        setAskLogsView(oneRoles.roles.view)
        setAskLogsAdd(oneRoles.roles.add)
        setAskLogsEdit(oneRoles.roles.edit)
        setAskLogsDelete(oneRoles.roles.delete)
      }
      if (oneRoles.name === 'chat_feg_docs') {
        setAskDocsView(oneRoles.roles.view)
        setAskDocsAdd(oneRoles.roles.add)
        setAskDocsEdit(oneRoles.roles.edit)
        setAskDocsDelete(oneRoles.roles.delete)
      }
    })

    setLoadingChecks(false)
  }

  function handleSave() {
    setLoadingSave(true)
    setLoadingChecks(true)
    const data = {
      home_page: {
        view: homeView,
        add: homeAdd,
        edit: homeEdit,
        delete: homeDelete
      },
      announcements: {
        view: announcementsView,
        add: announcementsAdd,
        edit: announcementsEdit,
        delete: announcementsDelete
      },
      user_validation: {
        view: userValidationView,
        add: userValidationAdd,
        edit: userValidationEdit,
        delete: userValidationDelete
      },
      user_management: {
        view: userManagementView,
        add: userManagementAdd,
        edit: userManagementEdit,
        delete: userManagementDelete
      },
      location: {
        view: locationView,
        add: locationAdd,
        edit: locationEdit,
        delete: locationDelete
      },
      events: {
        view: eventsView,
        add: eventsAdd,
        edit: eventsEdit,
        delete: eventsDelete
      },
      analytics: {
        view: analyticsView,
        add: analyticsAdd,
        edit: analyticsEdit,
        delete: analyticsDelete
      },
      shortcuts: {
        view: shortcutsView,
        add: shortcutsAdd,
        edit: shortcutsEdit,
        delete: shortcutsDelete
      },
      permissions_management: {
        view: permissionManagementView,
        add: permissionManagementAdd,
        edit: permissionManagementEdit,
        delete: permissionManagementDelete
      },
      chat_feg: {
        view: askFegView,
        add: askFegAdd,
        edit: askFegEdit,
        delete: askFegDelete
      },
      chat_feg_repository: {
        view: askFegView,
        add: askFegAdd,
        edit: askFegEdit,
        delete: askFegDelete
      },
      chat_feg_logs: {
        view: askLogsView,
        add: askLogsAdd,
        edit: askLogsEdit,
        delete: askLogsDelete
      },
      chat_feg_docs: {
        view: askDocsView,
        add: askDocsAdd,
        edit: askDocsEdit,
        delete: askDocsDelete
      }
    }

    axios.put(`api/v1/permissions/${selectGroup}`, data).then(() => {
      setLoadingSave(false)
      setLoadingChecks(false)
    })
  }

  return (
    <div className='background'>
      <div className='container'>
        <h2>Permission Management</h2>

        <div className={classes.header}>
          <div>
            <FormControl className={classes.formControl}>
              <InputLabel id='profile_label'>User Profile</InputLabel>
              <Select labelId='profile_label' id='profile_name' value={selectProfile} onChange={(e) => { handleProfileSelect(e.target.value) }}>
                <MenuItem value={''}> <em>None</em> </MenuItem>
                {profiles.map((profile) => (
                  <MenuItem value={profile.id} key={profile.id}>{profile.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel id='group_label'>User Group</InputLabel>
              <Select labelId='group_label' id='group_name' value={selectGroup} onChange={(e) => { handleGroupSelect(e.target.value) }}>
                <MenuItem value={''}> <em>None</em> </MenuItem>
                {groups.map((group) => (
                  <MenuItem value={group.id} key={group.id}>{group.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div style={{display: permissions.edit ? '' : 'none'}}>
            <FormControl className={classes.formControl}>
              <SaveButton variant="contained" onClick={handleSave} disabled={loadingChecks}>Save</SaveButton>
            </FormControl>
          </div>
        </div>


        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableHeaderCell>Module Name</TableHeaderCell>
                <TableHeaderCell>View</TableHeaderCell>
                <TableHeaderCell>Add</TableHeaderCell>
                <TableHeaderCell>Edit</TableHeaderCell>
                <TableHeaderCell>Delete</TableHeaderCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell>Home Page</TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={homeView} onChange={(e) => { sethomeView(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={homeAdd} onChange={(e) => { sethomeAdd(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={homeEdit} onChange={(e) => { sethomeEdit(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={homeDelete} onChange={(e) => { sethomeDelete(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Announcements</TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={announcementsView} onChange={(e) => { setannouncementsView(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={announcementsAdd} onChange={(e) => { setannouncementsAdd(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={announcementsEdit} onChange={(e) => { setannouncementsEdit(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={announcementsDelete} onChange={(e) => { setannouncementsDelete(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>User Validation</TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={userValidationView} onChange={(e) => { setuserValidationView(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={userValidationAdd} onChange={(e) => { setuserValidationAdd(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={userValidationEdit} onChange={(e) => { setuserValidationEdit(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={userValidationDelete} onChange={(e) => { setuserValidationDelete(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>User Management</TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={userManagementView} onChange={(e) => { setuserManagementView(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={userManagementAdd} onChange={(e) => { setuserManagementAdd(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={userManagementEdit} onChange={(e) => { setuserManagementEdit(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={userManagementDelete} onChange={(e) => { setuserManagementDelete(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Location</TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={locationView} onChange={(e) => { setlocationView(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={locationAdd} onChange={(e) => { setlocationAdd(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={locationEdit} onChange={(e) => { setlocationEdit(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={locationDelete} onChange={(e) => { setlocationDelete(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Events</TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={eventsView} onChange={(e) => { seteventsView(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={eventsAdd} onChange={(e) => { seteventsAdd(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={eventsEdit} onChange={(e) => { seteventsEdit(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={eventsDelete} onChange={(e) => { seteventsDelete(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Analytics</TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={analyticsView} onChange={(e) => { setanalyticsView(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={analyticsAdd} onChange={(e) => { setanalyticsAdd(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={analyticsEdit} onChange={(e) => { setanalyticsEdit(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={analyticsDelete} onChange={(e) => { setanalyticsDelete(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Shortcuts</TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={shortcutsView} onChange={(e) => { setshortcutsView(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={shortcutsAdd} onChange={(e) => { setshortcutsAdd(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={shortcutsEdit} onChange={(e) => { setshortcutsEdit(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={shortcutsDelete} onChange={(e) => { setshortcutsDelete(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Permissions Management</TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={permissionManagementView} onChange={(e) => { setpermissionManagementView(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={permissionManagementAdd} onChange={(e) => { setpermissionManagementAdd(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={permissionManagementEdit} onChange={(e) => { setpermissionManagementEdit(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={permissionManagementDelete} onChange={(e) => { setpermissionManagementDelete(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Ask FEG</TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={askFegView} onChange={(e) => { setAskFegView(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={askFegAdd} onChange={(e) => { setAskFegAdd(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={askFegEdit} onChange={(e) => { setAskFegEdit(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={askFegDelete} onChange={(e) => { setAskFegDelete(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Ask FEG Repository</TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={askRepositoryView} onChange={(e) => { setAskRepositoryView(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={askRepositoryAdd} onChange={(e) => { setAskRepositoryAdd(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={askRepositoryEdit} onChange={(e) => { setAskRepositoryEdit(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={askRepositoryDelete} onChange={(e) => { setAskRepositoryDelete(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Ask FEG Logs</TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={askLogsView} onChange={(e) => { setAskLogsView(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={askLogsAdd} onChange={(e) => { setAskLogsAdd(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={askLogsEdit} onChange={(e) => { setAskLogsEdit(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={askLogsDelete} onChange={(e) => { setAskLogsDelete(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Ask FEG Docs</TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={askDocsView} onChange={(e) => { setAskDocsView(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={askDocsAdd} onChange={(e) => { setAskDocsAdd(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={askDocsEdit} onChange={(e) => { setAskDocsEdit(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
                <TableCell>
                  <PurpleCheckbox color='default' checked={askDocsDelete} onChange={(e) => { setAskDocsDelete(e.target.checked) }} disabled={loadingChecks || !permissions.edit}></PurpleCheckbox>
                </TableCell>
              </TableRow>
              


            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}
export default Permission