import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from '../Loader';

import axios from '../../services/axios';
import { useCookies } from 'react-cookie';
import jwt from 'jsonwebtoken';
import Select from 'react-select'
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';

interface AccountupgradeData {
  user_email: string
  business_email: string
  customer_id: string
}

interface GroupData {
  id: string
  name: string
  profile_id: string
}

const id_profile_feg_email = 'c843021e-0c33-40cd-a0d2-0ec36fa1bb7a'

const Register = () => {
  const [cookies, ,] = useCookies(['token']);
  const token: any = cookies.token;
  const [loaded, setLoaded] = useState<boolean>(true);
  const [accountsToBeUpgrade, setAccountsToBeUpgrade] = useState<AccountupgradeData[]>([])
  const [accountSelected, setAccountSelected] = useState<AccountupgradeData>()
  const [openModalUpdate, setOpenModalUpdate] = useState(false)
  const [groups, setGroups] = useState<GroupData[]>([])
  const [groupSelected, setGroupSelected] = useState<{value: string; label:string}>()
  const [isUpgradeSaving, setIsUpgradeSaving] = useState(false)
  const [openAlertError, setOpenAlertError] = useState(false)
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState<'exist' | 'default'>('default')
  const optionsGroup = groups.filter((group) => group.profile_id == id_profile_feg_email).map((group) => {return {value: group.id, label: group.name}})
  const [permissions2, setPermissions2] = useState({
    view: false,
    add: false,
    edit: false,
    delete: false
  })
  const [requests, setRequests] = useState({
    Employee: [
      {
        id: '',
        firstName: '',
        lastName: '',
        location_name: '',
        location_id: '',
        email: '',
        group_name: ''
      }
    ],
    "Users With FEG Email": [
      {
        id: '',
        firstName: '',
        lastName: '',
        location_name: '',
        location_id: '',
        email: '',
        group_name: ''
      }
    ],
    Partner: [
      {
        id: '',
        firstName: '',
        lastName: '',
        location_name: '',
        location_id: '',
        email: '',
        group_name: ''
      }
    ],
    "Users Non-FEG Email": [
      {
        id: '',
        firstName: '',
        lastName: '',
        location_name: '',
        location_id: '',
        email: '',
        group_name: ''
      }
    ]
  });

  const history = useHistory();

  useEffect(() => {
    handlePermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadRequests() {
    await axios
      .get('/api/v1/accounts?user_status=unverified')
      .then((response) => {
        setRequests(response.data.results);
        // setLoaded(false);
      });
  }

  async function loadGroups() {
    await axios.get('/api/v1/groups')
    .then((response) => {
      setGroups(response.data)
    })
  }

  async function loadUpgrades() {
    await axios.get('/api/v1/accounts/upgrade/list')
    .then((response) => {
      setAccountsToBeUpgrade(response.data.result)
      setLoaded(false)
    })
  }

  function handlePermissions() {
    jwt.verify(
      token!.toString(),
      '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
      (err: any, decoded: any) => {
        if (err) {
          alert(err);
        } else {
          if (decoded.roles.user_validation.view === false) {
            alert('Permission denied');
            window.location.href = `${process.env.REACT_APP_URL}`;
          } else {
            loadRequests();
            setPermissions2(decoded.roles.user_validation)
            // setUserCustomerId(decoded.customer_id)
            loadGroups()
            loadUpgrades()
          }
        }
      }
    );
  }

  function handleValidate(id: string) {
    history.push(`/user/${id}`);
  }

  function handleModalUpgrade(account: AccountupgradeData) {
    setGroupSelected(undefined)
    setOpenModalUpdate(true)
    setAccountSelected(account)
  }

  async function handleSaveUpgrade() {
    try {
      setIsUpgradeSaving(true)

      const data = {
        business_email: accountSelected?.business_email,
        personal_email: accountSelected?.user_email,
        groupId: groupSelected?.value,
        profile_id: id_profile_feg_email
      }
      if(!data.business_email) throw new Error('Need a fegllc email to be upgraded')
      if(!data.groupId) throw new Error('Need to select a group.')

      await axios.put(`/api/v1/accounts/upgrade/${accountSelected?.customer_id}`, data)
      .then((response) => {
        setOpenAlertSuccess(true)
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      })
      .catch((err) => {
        setOpenAlertError(true)
        setErrorMessage('exist')
      })

    } catch (error) {
      // alert('Error saving the upgrade, please try again later.')
      setOpenAlertError(true)
      setErrorMessage('default')
      setOpenModalUpdate(false)
      setIsUpgradeSaving(false)
    } finally {
      setIsUpgradeSaving(false)
      setOpenModalUpdate(false)
    }
  }

  const handleCloseAlertError = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return
    setOpenAlertError(false)
  }

  const handleCloseAlertSuccess = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return
    setOpenAlertSuccess(false)
  }

  const alertErrorMessage = (
    <Snackbar
      open={openAlertError}
      autoHideDuration={5000}
      onClose={handleCloseAlertError}
      anchorOrigin={{horizontal: 'center', vertical: 'top'}}
    >
      <Alert onClose={handleCloseAlertError} severity='error'>
        {errorMessage === 'exist' ? 
          'This business email already exist!' 
          : 'Error saving the upgrade, please try again later.'
        }
      </Alert>
    </Snackbar>
  )

  const alertSuccessMessage = (
    <Snackbar
      open={openAlertSuccess}
      autoHideDuration={5000}
      onClose={handleCloseAlertSuccess}
      anchorOrigin={{horizontal: 'center', vertical: 'top'}}
    >
      <Alert onClose={handleCloseAlertSuccess} severity='success'>
        Account upgraded!
      </Alert>
    </Snackbar>
  )

  const modalUpdate = (
    <div className='containerUpgradeAccount'>
        <div className='boxUpgradeModal'>
          <h1 className='header'>
            <div></div>
            Account Upgrade 
            <button onClick={() => setOpenModalUpdate(false)}>X</button>
          </h1>

          <div className='boxUpgradeForm'>
            <label htmlFor="email">Personal Email <span>(current email)</span></label>
            <input type="text" name="email" id="email" value={accountSelected?.user_email} disabled />

            <label htmlFor="email">Business Email <span>(updated email)</span></label>
            <input type="text" name="email" id="email" value={accountSelected?.business_email} disabled />

            <label htmlFor="group">Group</label>
            <Select 
              options={optionsGroup}
              maxMenuHeight={250}
              isClearable
              value={groupSelected}
              onChange={(option: any) => setGroupSelected(option)}
            />

            <button
              onClick={handleSaveUpgrade}
              disabled={isUpgradeSaving}
              style={{backgroundColor: isUpgradeSaving ? 'gray' : ''}}
            >
              {isUpgradeSaving ? '...' : 'Save'}
            </button>
          </div>
        </div>
      </div>
  )

  return loaded ? (
    <Loader />
  ) : (
    <>
    <div className='requests-container'>
      <div className='thiscontent'>
        <h3>FEG Employees</h3>
        {requests.Employee ? (
          <ul>
            {requests.Employee.map((request) => (
              <li key={request.id}>
                <h1>
                  {request.email}
                  <button style={{ display: permissions2.add ? "" : "none" }} onClick={() => handleValidate(request.id)}>
                    Validate user
                  </button>
                </h1>
              </li>
            ))}
          </ul>
        ) : (
          ''
        )}

        {requests['Users With FEG Email'] ? (
          <ul>
            {requests['Users With FEG Email'].map((request) => (
              <li key={request.id}>
                <h1>
                  {request.email}
                  <button style={{ display: permissions2.add ? "" : "none" }} onClick={() => handleValidate(request.id)}>
                    Validate user
                  </button>
                </h1>
              </li>
            ))}
          </ul>
        ) : (
          ''
        )}

        {requests.Employee || requests['Users With FEG Email'] ? null : <h1>0 Employee to confirm</h1>}

        <h3>FEG Partners</h3>
        {requests.Partner ? (
          <ul>
            {requests.Partner.map((request) => (
              <li key={request.id}>
                <h1>
                  {request.email}
                  <button style={{ display: permissions2.add ? "" : "none" }} onClick={() => handleValidate(request.id)}>
                    Validate user
                  </button>
                </h1>
              </li>
            ))}
          </ul>
        ) : (
          ''
        )}

        {requests['Users Non-FEG Email'] ? (
          <ul>
            {requests['Users Non-FEG Email'].map((request) => (
              <li key={request.id}>
                <h1>
                  {request.email}
                  <button style={{ display: permissions2.add ? "" : "none" }} onClick={() => handleValidate(request.id)}>
                    Validate user
                  </button>
                </h1>
              </li>
            ))}
          </ul>
        ) : (
          ''
        )}

        {requests.Partner || requests['Users Non-FEG Email'] ? null : <h1>0 Partner to confirm</h1>}


        <h3>Account Upgrade</h3>

        {accountsToBeUpgrade.length > 0 ? (
          <ul>
            {accountsToBeUpgrade.map((user, index) => (
              <li key={index}>
                <h1>
                  {user.user_email}
                  <button style={{display: permissions2.edit ? "" : "none"}} onClick={() => handleModalUpgrade(user)}>Upgrade</button>
                </h1>
              </li>
            ))}
          </ul>
        ) : (
          <h1>0 Account Upgrades to confirm</h1>
        )}
      </div>
    </div>
    {openModalUpdate && modalUpdate}
    {alertErrorMessage}
    {alertSuccessMessage}
    </>
  );
};

export default Register;
