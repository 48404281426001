/* eslint-disable react/prop-types */
import { Avatar, Button } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'src/services/axios';
import { Theme } from 'src/styles/Theme';
import styled from 'styled-components';
import io from 'socket.io-client';
import moment from 'moment';
import { IoMdSend } from "react-icons/io";

const ChatLog = styled.div`
  padding: 10px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
  ::-webkit-scrollbar {
    width: 0; /* Esconde a barra de rolagem do navegador Chrome/Safari */
  }

  word-wrap: break-word;
  .loader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ChatMessage = styled.div`
  display: flex;
  word-wrap: break-word;
  width: 100%;
  margin: 10px;
  .active {
    margin: 0 0 0 auto;
    div.msg {
      background-color: ${(props) =>
        props.role === 'active' ? `${Theme.primary}` : '#fff'};
      color: ${Theme.secondary};
      flex-direction: row-reverse;
    }
  }

  div {
    display: flex;

  
    gap: 7px;
    word-wrap: break-word;
    flex-direction: ${(props) =>
      props.role === 'active' ? 'initial' : 'row-reverse'};
    div.msg {
      display: flex;
      width: 100%;
      margin: 0 0;
      word-wrap: break-word;
      max-width: max-content;
      background-color: ${Theme.secondary};
      padding: 6px;
      border-radius: ${(props) =>
        props.role === 'active'
          ? '10px 10px 0 10px'
          : props.role === 'activeT'
          ? '0'
          : '10px 10px 10px 0px'};
    }
    img {
      max-width: 300px;
      max-height: 250px;
    }
    img.logo-img {
      width: 45px;
      height: 45px;
      padding: 2px;
      border-radius: 50%;
      border: 2px solid ${Theme.secondary};
    }
  }
  .activeT {
    margin: 0 auto;
    padding: 2px;
    div {
      background-color: white;
      color: ${Theme.primary};
    }
  }
`;

const UserInput = styled.div`
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 10px;
`;

const TextInput = styled.input`
  flex-grow: 1;
  padding: 8px;
  width: 100%;
  border: none;
  border-radius: 5px;
`;

const SendButton = styled.button`
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  gap: 7px;
  background-color: ${Theme.primary};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    opacity: 0.9;
  }
`;

const CloseButton = styled.button`
  padding: 8px 16px;
  background-color: red;
  color: #fff;
  border: none;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    opacity: 0.9;
  }
`;

const ChatSubContainer = styled.div`
  display: flex;
  width: 100%;
  height: 442px;
  @media (max-width: 600px) {
    height: 100%;
    max-height: calc(90vh - 100px);
    min-height: calc(90vh - 100px);
  }
  div.log-message {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  div.history-message {
    background-color: #f5f5f5;
    width: 185px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 502px;

    transition: 1s;
    button {
      margin: 10px 105px;
      width: 90%;
      height: max-content;
      text-overflow: clip;
      padding: 10px;
      cursor: pointer;
      font-weight: bold;
      border: none;

      border-radius: 9px;
      background-color: white;
      color: ${Theme.primary};
      :last-of-type {
        margin-bottom: 12px;
      }
    }
  }
`;
interface Location {
  id: string;
  location_name: string;
  visible?: boolean;
}

interface Props {
  user?: string;
  id?: string;
  location?: Location;
  admin?: boolean;
  //socket: SocketIOClient.Socket
}
interface Message {
  id: string;
  id_remetente: string;
  id_location: string;
  content: string;
  createdAt: string;
  full_name_remetente: string;
  image_url?: string;
}
type MessageFilter = Record<string, Message>;

const UserMessagesChat: React.FC<Props> = ({
  id,
  location,
  admin,
  user /*socket*/
}) => {
  const image = localStorage.getItem('image_url');
  const [data, setData] = useState<any>();
  const contentRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(0);
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const email = localStorage.getItem('email');
  const idUser = localStorage.getItem('id');
  const firstName = localStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName');
  const [socket, setSocket] = useState<SocketIOClient.Socket>();

  const [previewMobile, setPreviewMobile] = useState('');
  const [imageMobileName, setImageMobileName] = useState('');
  const [imageMobileFile, setImageMobileFile] = useState({
    selectedFile: '',
    type: ''
  });
  useEffect(() => {
    disableZoom();
  }, []);

  const disableZoom = () => {
    const viewportMetaTag = document.querySelector('meta[name="viewport"]');
    if (viewportMetaTag) {
      viewportMetaTag.setAttribute(
        'content',
        'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'
      );
    }
  };
  function fileSelectMobile(props: any) {
    if (props[0] === undefined) {
      setImageMobileName('');
      setImageMobileFile({
        selectedFile: '',
        type: ''
      });
      return;
    }
    setPreviewMobile(URL.createObjectURL(props[0]));
    setImageMobileName(props[0].name);
    const reader = new FileReader();
    reader.readAsDataURL(props[0]);

    reader.onload = (e: any) => {
      setImageMobileFile({
        selectedFile: e?.target?.result,
        type: props[0].type
      });
    };
  }
  useEffect(() => {
    const intervalId = setInterval(() => {
    handleMessaging();
    }, 7000);

    // Retorne uma função de limpeza para limpar o intervalo quando o componente é desmontado
    return () => clearInterval(intervalId);
  }, [id]);
  useEffect(() => {
    handleMessaging();
  }, [id]);

  const messageDateFilter = () => {
    const dadosSeparadosPorLetra: any = {};
    data.forEach((item: any) => {
      const primeiraLetra = item.createdAt.slice(0, 10);
      if (!dadosSeparadosPorLetra[primeiraLetra]) {
        dadosSeparadosPorLetra[primeiraLetra] = [];
      }
      dadosSeparadosPorLetra[primeiraLetra].push(item);
    });
    return dadosSeparadosPorLetra;
  };

  function handleMessaging() {
    axios.get(`/api/v1/location-messaging/filter/${id}`).then((res) => {
      setData(res.data);
     
    });
  }

  useEffect(() => {
    // Connect to the socket server
    const newsocket = io(`${process.env.REACT_APP_API_URL}`, {
      transports: ['websocket', 'polling', 'flashsocket'],
      upgrade: true
    });
    setSocket(newsocket);
    // Event listener for when the connection is established
    newsocket.on('connect', () => {
      console.log('Connected to the socket server');
    });

    // Event listener for custom events from the server
    newsocket.on('newMessage', (data: any) => {
      setData((prevState: any) => [...prevState, data]);
      scrollToDiv();
    });

    // Send a message to the server
    const messageToSend = 'Hello, Server!';
    newsocket.emit('client-event', messageToSend);

    // Clean up the socket connection when the component is unmounted
    return () => {
      newsocket.disconnect();
    };
  }, []);
  function dateFormat(date: any) {
    const dateMomemnt = moment(date);
    const today = moment();
    if (dateMomemnt.isSame(today, 'day')) {
      return 'Today';
    } else {
      return date;
    }
  }

  function sendMessage() {
    if (socket) {
      if (imageMobileName) {
        socket.emit('sendMessage', {
          id: id,
          user: idUser,
          room: 'test',
          email: email,
          location: location,
          content: '',
          type: 'IMAGE',
          full_name: `${firstName} ${lastName}`,
          image_url: imageMobileFile
        });
      }
      if (!imageMobileName) {
        socket.emit('sendMessage', {
          id: id,
          user: idUser,
          room: 'test',
          location: location,
          email: email,
          content: text,
          type: 'TEXT',
          full_name: `${firstName} ${lastName}`
        });
      }
    }

    setText('');
    setImageMobileName('');
    setPreviewMobile('');
    setImageMobileFile({
      selectedFile: '',
      type: ''
    });
  }
  const scrollToDiv = () => {
    const elemento = contentRef.current;
    if (elemento) {
      elemento.scrollTo({
        top: Number.MAX_SAFE_INTEGER,
        behavior: 'smooth'
      });
    }
  };

  return (
    <ChatSubContainer>
      <div className='log-message'>
        <ChatLog
          ref={contentRef}
          id={'suaDiv'}
          //onScroll={() => handleScroll()}
          style={{ scrollBehavior: 'smooth' }}
        >
          {/*loading && <div className='loader'><BeatLoader color={`${Theme.primary}`}/></div>*/}

          {data && (
            <>
              {Object.keys(messageDateFilter()).map((dataT) => (
                <div key={Math.random()}>
                  <ChatMessage key={Math.random()} role={'activeT'}>
                    <div className={`activeT`}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          whiteSpace: 'pre-line'
                        }}
                      >
                        <p> {dateFormat(dataT.slice(0, 10))}</p>
                      </div>
                    </div>
                  </ChatMessage>
                  {messageDateFilter()[dataT].map((message: Message) => (
                    <>
                      <ChatMessage
                        key={Math.random()}
                        role={
                          idUser === message.id_remetente ? 'active' : 'false'
                        }
                        id={message.id}
                      >
                        <div
                          className={`${
                            message.id_remetente !== idUser ? 'ME1' : 'active'
                          } `}
                        >
                          <div
                          className='msg'
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              whiteSpace: 'pre-line'
                            }}
                          >
                            {' '}
                            {message.image_url && (
                              <img src={message.image_url} alt='' />
                            )}
                            
                              <small style={{fontSize: 10}}>
                              {message?.full_name_remetente}
                            </small>
                            <p>{message.content}</p>
                            <small style={{fontSize: 10}}>
                              {moment(message.createdAt).format(
                                'hh:mm A'
                              )}
                            </small>
                          </div>
                          {message.id_remetente !== 'ME1' && (
                            <Avatar style={{marginRight: 10, backgroundColor:"#336699"}}>{message?.full_name_remetente[0]}</Avatar>
                          )}
                       
                          {message.id_remetente === 'ME1' && (
                          <Avatar style={{marginRight: 10, backgroundColor:"#336699"}}>{message?.full_name_remetente[0]}</Avatar>
                          )}
                        </div>
                      </ChatMessage>
                    </>
                  ))}
                  <div id='display-none' style={{ display: 'none' }}></div>
                </div>
              ))}
            </>
          )}
        </ChatLog>
        {(!admin || email=== 'fegmaster@fegllc.com') && (
          <UserInput>
            <div>
              <input
                id='imageDesktop'
                type='file'
                accept='.jpg,.png'
                onChange={(event) => fileSelectMobile(event.target.files)}
                style={{ display: `${imageMobileName ? 'initial' : 'none'}` }}
              />
              <label htmlFor='imageDesktop'>
                <Button variant='contained' color='default' component='span'>
                  +
                </Button>
              </label>
            </div>
            {imageMobileName && (
              <CloseButton
                onClick={() => {
                  setImageMobileName('');
                  setPreviewMobile('');
                  setImageMobileFile({
                    selectedFile: '',
                    type: ''
                  });
                }}
              >
                CANCEL
              </CloseButton>
            )}
            {!imageMobileName && (
              <>
                <TextInput
                  type='text'
                  placeholder='Type your question here...'
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      sendMessage();
                    }
                  }}
                />
              </>
            )}
            <SendButton onClick={() => sendMessage()}>Send <IoMdSend size={15}/>
            </SendButton>
          </UserInput>
        )}
      </div>
    </ChatSubContainer>
  );
};

export default UserMessagesChat;
