import React, { useState } from 'react';
import { Container } from './styled';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const DocumentSources = (props) => {
  const [open, setOpen] = useState(true);

  return (
    <Container>
      <div className='title'>
        <p>Documents Sources</p>{' '}
        <button onClick={() => setOpen(!open)}>
          {open ? <FaChevronDown /> : <FaChevronUp />}
        </button>
      </div>
      {open && (
        <>
          <div className='sources'>
            {props.sources.map((source) => {
              return (
                <a
                  key={Math.random()}
                  href={source.url}
                  target='_blank'
                  rel={'noreferrer'}
                >
                  {source.filename}
                </a>
              );
            })}
            {props.children}
          </div>
        </>
      )}
    </Container>
  );
};
export default DocumentSources;
