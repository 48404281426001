import React, {useEffect} from 'react';
import ReactGA from 'react-ga';
import Announcement from '../../components/CompanyAnnouncements';

const Announcements: React.FC = () => {

    useEffect(() => {
        ReactGA.initialize('UA-189380132-1')
        ReactGA.pageview(window.location.pathname + window.location.search)
      })

    return (
            <Announcement />
    )
}

export default Announcements;
