import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton
} from '@material-ui/core';
import {
  Delete,
  Edit,
  ArrowRight,
  ArrowRightSharp,
  ArrowLeft,
  ArrowLeftSharp,
  ToggleOff
} from '@material-ui/icons';
import jwt from 'jsonwebtoken';
import axios from '../../services/axios';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import Loader from 'src/components/Loader';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { withStyles } from '@material-ui/core/styles';
import { Theme } from 'src/styles/Theme';

const LocationManagement: React.FC = () => {
  const [value, ,] = useState();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [defaultSearch, setDefaultSearch] = useState('');
  const [locations, setLocations] = useState([]);
  const [locationTarget, setLocationTarget] = useState<{
    id?: string;
    name?: string;
    display_name?: string;
    store_id?: string;
    address?: string;
    phone?: string;
  }>({});
  const [cookies, ,] = useCookies(['token']);
  const [triggerModal, setTriggerModal] = useState<string | boolean>(false);
  const [triggerDeleteModal, setTriggerDeleteModal] = useState<boolean>(false);
  const [limit, setLimit] = useState(0);
  const [ordered, setOrdered] = useState('');
  const [permissions, setPermissions] = useState({
    view: false,
    add: false,
    edit: false,
    delete: false
  });

  const token = cookies.token;
  const history = useHistory();

  const TableHeadContainer = withStyles(() => ({
    root: {
      backgroundColor: Theme.primary
    }
  }))(TableHead);

  const TableHeaderCell = withStyles(() => ({
    root: {
      color: 'white',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }))(TableCell);

  const TableBodyCell = withStyles(() => ({
    root: {
      color: '#808285'
    }
  }))(TableCell);

  const handlePermissions = () => {
    if (token) {
      jwt.verify(
        token.toString(),
        '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
        (err: any, decoded: any) => {
          if (err) {
            history.push('/');
          } else {
            if (decoded.roles.location.view !== true) {
              history.push('/');
            } else {
              setPermissions(decoded.roles.location);
            }
          }
        }
      );
    }
  };

  const handleLoad = () => {
    setLoaded(false);
    axios.get(`/api/v1/locations?active=both`).then((locationsResponse) => {
      setLocations(locationsResponse.data);
      setLoaded(true);
    });
  };

  const handleSearchLocation = (name: string) => {
    setDefaultSearch(name);
    if (name.length > 3) {
      axios
        .get(`/api/v1/locations?search_for=${name}`)
        .then((locationsResponse) => {
          setLocations(locationsResponse.data);
          setLimit(0);
        });
    } else if (name.length === 0) {
      axios.get(`/api/v1/locations?active=both`).then((locationsResponse) => {
        setLocations(locationsResponse.data);
        setLimit(0);
      });
    }
  };

  const handleDeleteLocation = (id?: string) => {
    setLoaded(true);
    try {
      axios.delete(`/api/v1/locations/${id}`).then(() => {
        setTriggerDeleteModal(false);
        handleLoad();
      });
    } catch (error) {
      alert('Error deleting a location, please try again.');
      handleLoad();
    }
  };

  const handleOrderBy = (columnKey: string) => {
    setLoaded(false);
    setTimeout(() => {
      let orderedLocations = locations;
      if (ordered === columnKey) {
        orderedLocations = orderedLocations.reverse();
        setLocations(orderedLocations);
        setLoaded(true);
      } else {
        orderedLocations = locations.sort((a, b) =>
          a[columnKey] > b[columnKey] ? 1 : b[columnKey] > a[columnKey] ? -1 : 0
        );
        setOrdered(columnKey);
        setLocations(orderedLocations);
        setLoaded(true);
      }
    }, 200);
  };

  const makeLocationAction = () => {
    const data = {
      name: locationTarget.name,
      display_name: locationTarget.display_name,
      store_id: locationTarget.store_id,
      address: locationTarget.address,
      phone: locationTarget.phone
    };
    if (triggerModal === 'create') {
      axios
        .post(`/api/v1/locations`, data)
        .then((response) => {
          handleLoad();
          setTriggerModal(false);
        })
        .catch((error) => {
          if (error.response.data.code === '23505') {
            const regExp = /\(([^)]+)\)/;
            const errorMessage: any = regExp.exec(error.response.data.detail);
            alert(
              `The ${errorMessage[1].replace(
                '_',
                ' '
              )} value set already exists.`
            );
          } else {
            alert(
              'Error while creating a new location, please refresh the page and try again.'
            );
          }
        });
    }
    if (triggerModal === 'edit') {
      axios
        .put(`/api/v1/locations/${locationTarget.id}`, data)
        .then((response) => {
          handleLoad();
          setTriggerModal(false);
        })
        .catch((error) => {
          if (error.response.data.code === '23505') {
            const regExp = /\(([^)]+)\)/;
            const errorMessage: any = regExp.exec(error.response.data.detail);
            alert(
              `The ${errorMessage[1].replace(
                '_',
                ' '
              )} value set already exists.`
            );
          } else {
            alert(
              'Error while updating a new location, please refresh the page and try again.'
            );
          }
        });
    }
  };

  const handleLimit = (newLimit: number) => {
    setLoaded(false);
    setTimeout(() => {
      setLimit(newLimit);
      setLoaded(true);
    }, 800);
  };

  useEffect(() => {
    handlePermissions();
    handleLoad();
  }, []);

  const locationModal = (
    <div style={{ display: triggerModal ? 'block' : 'none' }} className='modal'>
      <div className='modal-content'>
        <div className='modal-header'>
          <span onClick={() => setTriggerModal(false)} className='close'>
            &times;
          </span>
          <h3>
            <b>FEG Home</b> Locations
          </h3>
        </div>
        <div className='modal-form'>
          {!loaded ? (
            <Loader />
          ) : (
            <>
              <div className='modal-row'>
                <TextField
                  className='textField'
                  size='medium'
                  label={'Name'}
                  value={locationTarget.name ? locationTarget.name : ''}
                  onChange={(e) => {
                    setLocationTarget({
                      ...locationTarget,
                      name: e.target.value
                    });
                  }}
                  placeholder='Enter the name:'
                  inputProps={{ maxLength: 80 }}
                />
                <TextField
                  className='textField'
                  size='medium'
                  label={'Display Name'}
                  value={
                    locationTarget.display_name
                      ? locationTarget.display_name
                      : ''
                  }
                  onChange={(e) => {
                    setLocationTarget({
                      ...locationTarget,
                      display_name: e.target.value
                    });
                  }}
                  placeholder='Enter the display name:'
                  inputProps={{ maxLength: 80 }}
                />
              </div>
              <div className='modal-row'>
                <div className='googleMapsInput'>
                  <TextField
                    className='textField'
                    size='medium'
                    label={'Store ID'}
                    value={
                      locationTarget.store_id ? locationTarget.store_id : ''
                    }
                    onChange={(e) => {
                      setLocationTarget({
                        ...locationTarget,
                        store_id: e.target.value.replace(/\D/g, '')
                      });
                    }}
                    placeholder='Enter the store ID:'
                    inputProps={{ maxLength: 4 }}
                  />
                </div>
                <div className='googleMapsInput'>
                  <GooglePlacesAutocomplete
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ['us']
                      }
                    }}
                    selectProps={{
                      placeholder: 'Address',
                      value,
                      onChange: (e: any) => {
                        setLocationTarget({
                          ...locationTarget,
                          address: e.label
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className='modal-row'>
                <TextField
                  className='textField'
                  size='medium'
                  label={'Phone'}
                  value={locationTarget.phone ? locationTarget.phone : ''}
                  onChange={(e) => {
                    setLocationTarget({
                      ...locationTarget,
                      phone: e.target.value
                        .replace(/\D+/g, '')
                        .replace(/(\d{3})(\d)/, '($1) $2')
                        .replace(/(\d{3})(\d)/, '$1-$2')
                        .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
                        .replace(/(-\d{4})\d+?$/, '$1')
                    });
                  }}
                  placeholder='Enter the phone:'
                  inputProps={{ maxLength: 20 }}
                />
              </div>
              <div className='modal-submit'>
                <Button
                  disabled={
                    !locationTarget.name ||
                    !locationTarget.display_name ||
                    !locationTarget.store_id
                    // !locationTarget.address
                  }
                  size='small'
                  variant='contained'
                  color='primary'
                  onClick={() => makeLocationAction()}
                >
                  SAVE
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );

  const deleteLocationModal = (
    <div
      style={{ display: triggerDeleteModal ? 'block' : 'none' }}
      className='modal'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <span onClick={() => setTriggerDeleteModal(false)} className='close'>
            &times;
          </span>
          <h3>
            <b>FEG Home</b> Locations
          </h3>
        </div>
        <div className='modal-form'>
          {!loaded ? (
            <Loader />
          ) : (
            <>
              <div style={{ width: '80%', margin: '1rem auto 0' }}>
                <h2>
                  You are deleting a location from the system. This action
                  cannot be reversed. Do you want to proceed?
                </h2>
              </div>
              <div
                className='modal-submit'
                style={{
                  justifyContent: 'space-around',
                  width: '50%',
                  marginTop: '0',
                  marginBottom: '0'
                }}
              >
                <Button
                  size='small'
                  variant='contained'
                  color='primary'
                  onClick={() => handleDeleteLocation(locationTarget.id)}
                >
                  YES
                </Button>
                <Button
                  size='small'
                  variant='contained'
                  color='primary'
                  onClick={() => setTriggerDeleteModal(false)}
                >
                  NO
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );

  return !loaded ? (
    <Loader />
  ) : (
    <div className='background'>
      <div className='container'>
        <h2>FEG Home Locations</h2>
        <div className='searchAndAddRow'>
          <TextField
            variant='outlined'
            size='small'
            label='Search...'
            defaultValue={defaultSearch}
            onChange={(e) => handleSearchLocation(e.target.value)}
            className='searchLocation'
          />
          <Button
            style={{ display: permissions.add ? '' : 'none' }}
            size='medium'
            variant='contained'
            color='primary'
            onClick={() => {
              setLocationTarget({});
              setTriggerModal('create');
            }}
          >
            Add New
          </Button>
        </div>
        <div className='tableSection'>
          <TableContainer component={Paper}>
            <Table size='medium' aria-label='Locations table'>
              <TableHeadContainer>
                <TableRow>
                  <TableHeaderCell
                    align='center'
                    onClick={() => handleOrderBy('name')}
                  >
                    Name
                  </TableHeaderCell>
                  <TableHeaderCell
                    align='center'
                    onClick={() => handleOrderBy('display_name')}
                  >
                    Display Name
                  </TableHeaderCell>
                  <TableHeaderCell
                    align='center'
                    onClick={() => handleOrderBy('store_id')}
                  >
                    Store ID
                  </TableHeaderCell>
                  <TableHeaderCell
                    align='center'
                    onClick={() => handleOrderBy('address')}
                  >
                    Address
                  </TableHeaderCell>
                  <TableHeaderCell
                    align='center'
                    onClick={() => handleOrderBy('phone')}
                  >
                    Phone
                  </TableHeaderCell>
                  <TableHeaderCell
                    align='center'
                    onClick={() => handleOrderBy('status')}
                  >
                    Status
                  </TableHeaderCell>
                  <TableHeaderCell align='center'></TableHeaderCell>
                </TableRow>
              </TableHeadContainer>
              <TableBody>
                {locations
                  .slice(limit, limit + 10)
                  .map((location: any, key) => (
                    <TableRow key={key}>
                      <TableBodyCell component='th' scope='row' align='center'>
                        {location.name}
                      </TableBodyCell>
                      <TableBodyCell align='center'>
                        {location.display_name}
                      </TableBodyCell>
                      <TableBodyCell align='center'>
                        {location.store_id}
                      </TableBodyCell>
                      <TableBodyCell align='center'>
                        {location.address}
                      </TableBodyCell>
                      <TableBodyCell align='center'>
                        {location.phone}
                      </TableBodyCell>
                      <TableBodyCell align='center'>
                        {location.active === true ? 'Activated' : 'Deactivated'}
                      </TableBodyCell>
                      <TableCell align='center'>
                        <IconButton
                          style={{ display: permissions.edit ? '' : 'none' }}
                          onClick={() => {
                            setLoaded(true);
                            try {
                              axios
                                .post(`/api/v1/locations/${location.id}/toggle`)
                                .then(() => {
                                  handleLoad();
                                });
                            } catch (error) {
                              alert(
                                'Error activating/deactivating a location, please try again.'
                              );
                              handleLoad();
                            }
                          }}
                          aria-label='Activate/Deactivate Location'
                        >
                          <ToggleOff />
                        </IconButton>
                        <IconButton
                          style={{ display: permissions.edit ? '' : 'none' }}
                          onClick={() => {
                            setLocationTarget(location);
                            setTriggerModal('edit');
                          }}
                          aria-label='edit'
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          style={{ display: permissions.delete ? '' : 'none' }}
                          onClick={() => {
                            setLocationTarget(location);
                            setTriggerDeleteModal(true);
                          }}
                          aria-label='delete'
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className='paginationSection'>
          <div className='paginationDetails'>
            <p>
              {limit} to{' '}
              {limit < locations.length - 10 ? limit + 10 : locations.length},
              total of {locations.length} locations
            </p>
          </div>
          <div className='paginationButtons'>
            <IconButton
              aria-label='first_page'
              size='medium'
              onClick={() => handleLimit(0)}
              disabled={limit === 0}
            >
              <ArrowLeftSharp />
            </IconButton>
            <IconButton
              aria-label='previous_page'
              size='medium'
              onClick={() => handleLimit(limit - 10)}
              disabled={limit === 0}
            >
              <ArrowLeft />
            </IconButton>
            <IconButton
              aria-label='next_page'
              size='medium'
              onClick={() => handleLimit(limit + 10)}
              disabled={limit >= locations.length - 10}
            >
              <ArrowRight />
            </IconButton>
            <IconButton
              aria-label='lasts_page'
              size='medium'
              onClick={() => handleLimit(locations.length - 10)}
              disabled={limit >= locations.length - 10}
            >
              <ArrowRightSharp />
            </IconButton>
          </div>
        </div>
      </div>
      {locationModal}
      {deleteLocationModal}
    </div>
  );
};

export default LocationManagement;
