import React, { useEffect, useState } from 'react';
import { Btn, Container } from './styled';
import api from 'src/services/axios';
import { Theme } from 'src/styles/Theme';
import styled from 'styled-components';
import TableX from './table';
import { BeatLoader } from 'react-spinners';
import { PaginationComp, Props, PropsMetaData } from 'src/components/Pagination';
const image = localStorage.getItem('image_url');

const ChatLog = styled.div`
  height: 450px;
  padding: 10px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
  word-wrap: break-word;
  display: none;
  &.active {
    display: flex;
  }
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
`;
interface DataLogs {
  content: string;
  conversation: Conversation;
  id: string;
  created_at: string;
}
interface Messages {
  role_id: number;
  content: string;
}

interface Conversation {
  name: string;
  messages: Messages[];
}
type PropsStyle = {
  role: any;
};

const ChatMessage = styled.div`
  display: flex;
  word-wrap: break-word;
  width: 100%;
  .active {
    margin: 0 0 0 auto;
    div {
      background-color: ${(props: PropsStyle) =>
        props.role === 1 ? `${Theme.primary}` : '#fff'};
      color: ${Theme.secondary};
      flex-direction: row-reverse;
    }
  }
  div {
    display: flex;
    gap: 20px;
    word-wrap: break-word;
    flex-direction: ${(props: PropsStyle) =>
      props.role === 1 ? 'initial' : 'row-reverse'};
    div {
      display: flex;
      background-color: #e53935;
      width: 100%;
      margin: 10px 0;
      word-wrap: break-word;
      max-width: max-content;
      background-color: ${Theme.light};
      padding: 10px;
      border-radius: ${(props) =>
        props.role === 1 ? '10px 10px 0 10px' : '10px 10px 10px 0'};
    }
    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }
  }
`;

export default function LogsAskFEG(): React.ReactElement {
  const [data, setData] = useState<DataLogs[]>();
  const [openTable, setOpenTable] = useState<number[]>([]);
  const [loading, setLoading] = useState(false)
  const [metaData, setMetaData] = useState<any>({before: 0, current: 1, first: 0, last: 0, next: 0})
  const [currentPage, seCurrentPage] = useState(1)


 
  function getLogs() {
    setLoading(true)
    api
      .get(
        `https://openai.fegsystem.com/unsatisfactory-answers/?per_page=50&page=${currentPage}`,
        { timeout: 0 }
      )
      .then((res) => {
        setData(res.data.data.reverse());
        setMetaData({
          first: res.data.meta.first_page,
          last: res.data.meta.last_page,
          current: res.data.meta.current_page,
          next: res.data.meta.next_page
        })
        setLoading(false)
      });
  }
 
  function handleTable(index: number) {
    const filtered = openTable.find((value) => value === index);

    if (filtered) {
      const removeIndex = openTable.filter((value) => value !== index);
      if (removeIndex) {
        setOpenTable([...removeIndex]);
      }
    } else {
      setOpenTable([index, ...openTable]);
    }
  }

  function checkVisibleTable(index: number) {
    const isVisible = openTable.find((value) => value === index);

    if (isVisible) {
      return true;
    } else {
      return false;
    }
  }

  function transformarLinksEmHTML(texto: string) {
    // Expressão regular para encontrar links
    const regex = /(https?:\/\/[^\s]+)/g;
    const regexEmail = /([\w.-]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,6})/g;

    // Substitui os links encontrados pelo texto formatado
    const textoFormatado = texto.replace(regex, '<a href="$1">$1</a>');
    const dataFormated = textoFormatado.replace(
      regexEmail,
      '<a href="mailto:$1">$1</a>'
    );

    return dataFormated;
  }

  useEffect(() => {
    getLogs();
  }, [currentPage]);
  const columns = [
    {
      id: 'idUnique', // add unique id here
      Header: 'Id',
      accessor: 'id'
    },
    {
      id: 'CreatedDate', // add unique id here
      Header: 'Created',
      accessor: 'created_at',
      Cell: ({ cell: { value } }: { cell: { value: string } }) => {
        return <>{value.split('T')[0]}</>;
      }
    },
    {
      id: 'Content', // add unique id here
      Header: 'content',
      accessor: 'content'
    },
    {
      Header: 'Chat',
      accessor: 'conversation',
      // eslint-disable-next-line react/display-name, react/prop-types, @typescript-eslint/no-unused-vars
      Cell: ({ cell: { value, row } }: { cell: { value: any, row:any } }) => {
        return (
          <>
        
            <Btn onClick={() => handleTable(row.id)}>
              {checkVisibleTable(row.id) ? 'Close' : 'View'}
            </Btn>
            <ChatLog
              key={Math.random()}
              onClick={() => handleTable(row.id)}
              className={checkVisibleTable(row.id) ? 'active' : ''}
            >
              {value.messages.map((message: any) => {
                return (
                  <>
                    <ChatMessage role={message.role_id} key={Math.random()}>
                      <div
                        className={`${
                          message.role_id !== 1 ? 'not-active' : 'active'
                        } `}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            whiteSpace: 'pre-line'
                          }}
                        >
                          <p
                            dangerouslySetInnerHTML={{
                              __html: transformarLinksEmHTML(message.content)
                            }}
                          />
                        </div>
                        {message.role_id !== 1 && (
                          <img
                            src={
                              'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/shortcuts/desktop/e82612b7-3670-4c60-932a-eff7c5ff77ae.png'
                            }
                            alt='FEG LOGO'
                          />
                        )}
                        {message.role_id === 1 && (
                          <img
                            src={
                              image
                                ? image
                                : 'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/shortcuts/desktop/e82612b7-3670-4c60-932a-eff7c5ff77ae.png'
                            }
                            alt='FEG LOGO'
                          />
                        )}
                      </div>
                    </ChatMessage>
                  </>
                );
              })}
            </ChatLog>
          </>
        );
      }
    }
  ];

  return (
    <Container>
      <h1 style={{ color: `${Theme.primary}` }}>Chat Analytics</h1>
      {loading && (
          <BeatLoader
            style={{ marginTop: 20 }}
            size={20}
            color={`${Theme.primary}`}
          />
        )}

        <PaginationComp meta={metaData} setState={seCurrentPage}/>

      {data && <TableX columns={columns} data={data} />}
    </Container>
  );
}
