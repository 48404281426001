import { Theme } from 'src/styles/Theme';
import styled from 'styled-components';

export const CardContainer = styled.div`
  div.title {
    display: flex;
    justify-content: space-between;

    h1 {
      color: ${Theme.primary};
      padding: 0px 10px;
    }
  }
  div.to-dos {
    width: 100%;
    display: flex;
    background-color: ${Theme.light};
    padding: 10px;
    border-radius: 8px;
    flex-direction: column;
    div.to-do {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 2px 0;
      padding: 4px;
      border-bottom: 1px solid #ddd;
      button {
        padding: 4px;
        min-width: 80px;
        border: none;
        cursor: pointer;
        color: white;
      }
      button.loading {
        background-color: yellow;
      }
      button.start {
        background-color: green;
      }
      button.stop {
        background-color: red;
      }
    }
    .view {
      display: flex;
      width: 100%;
      justify-content: center;
      p {
        font-weight: bold;
        color: ${Theme.primary};
        margin-top: 5px;
      }
    }
  }
`;
