import React, { useState, useEffect, ChangeEvent } from 'react';
import Select from 'react-select';
import { groupsData } from '../../data';
import {
  TextField,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  FormControl,
  InputLabel,
  Select as Select2,
  MenuItem,
  makeStyles,
  Theme,
  createStyles,
  Chip
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import jwt from 'jsonwebtoken';
import axios from '../../services/axios';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import Loader from 'src/components/Loader';
import { withStyles } from '@material-ui/core/styles';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FiImage } from 'react-icons/fi';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
  DraggableProvided,
  DroppableProvided,
  DraggableStateSnapshot
} from 'react-beautiful-dnd';
import ReorderIcon from '@material-ui/icons/Reorder';
import Profile from 'src/components/UserValidation/Profile';
import { Theme as S } from 'src/styles/Theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: '100%'
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
  })
)

type Profile = {
  id: string
  name: string
}

type Group = {
  id: string
  name: string
  profile_id: string
}

const ShortcutManagement: React.FC = () => {
  const styles = useStyles()
  const [permissions, setPermissions] = useState({
    view: false,
    add: false,
    edit: false,
    delete: false
  })
  const [loaded, setLoaded] = useState<boolean>(false);
  const [defaultSearch, setDefaultSearch] = useState('');
  const [shortcuts, setShortcuts] = useState<any>([]);
  const [defaultShortcuts, setDefaultShortcuts] = useState<any>([]);
  const [shortcutTarget, setShortcutTarget] = useState<{
    id?: string;
    name?: string;
    display_name1?: string;
    display_name2?: string;
    desktop_image?: string;
    mobile_image?: string;
    link_desktop?: string;
    link_mobile?: string;
    order?: number;
    group_ids?: string[];
    favorite?: boolean
  }>({})
  const [groupIds, setGroupIds] = useState<{
    label: string
    value: string
  }[]>([])
  const [cookies, ,] = useCookies(['token']);
  const [triggerModal, setTriggerModal] = useState<string | boolean>(false);
  const [previewDesktop, setPreviewDesktop] = useState('');
  const [imageDesktopName, setImageDesktopName] = useState('');
  const [imageDesktopFile, setImageDesktopFile] = useState({
    selectedFile: '',
    type: ''
  });
  const [previewMobile, setPreviewMobile] = useState('');
  const [imageMobileName, setImageMobileName] = useState('');
  const [imageMobileFile, setImageMobileFile] = useState({
    selectedFile: '',
    type: ''
  });
  const [profiles, setProfiles] = useState<Profile[]>([])
  const [groups, setGroups] = useState<Group[]>([])
  const [selectedProfile, setSelectProfile] = useState<string[]>([])
  const [groupOptions, setGroupOptions] = useState<Group[]>([])
  const [selectedGroup, setSelectedGroup] = useState<string[]>([])
  const allInputs =
    shortcutTarget.name &&
    shortcutTarget.display_name1 &&
    shortcutTarget.display_name2 &&
    (imageDesktopName || shortcutTarget.desktop_image) &&
    shortcutTarget.link_desktop &&
    shortcutTarget.link_mobile;

  const token = cookies.token;
  const history = useHistory();

  const bgColor = S.primary;
  const textColor = '#808285';

  const TableHeadContainer = withStyles(() => ({
    root: {
      backgroundColor: bgColor
    }
  }))(TableHead);

  const TableHeaderCell = withStyles(() => ({
    root: {
      color: 'white'
    }
  }))(TableCell);

  const TableBodyCell = withStyles(() => ({
    root: {
      color: textColor
    }
  }))(TableCell);

  const AddButton = withStyles(() => ({
    root: {
      backgroundColor: bgColor
    }
  }))(Button);

  const handlePermissions = () => {
    if (token) {
      jwt.verify(
        token.toString(),
        '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
        (err: any, decoded: any) => {
          if (err) {
            history.push('/');
          } else {
            if (decoded.roles.shortcuts.view !== true) {
              alert("Insufficient Permissions")
              history.push('/');
            } else {
              setPermissions(decoded.roles.shortcuts)
            }
          }
        }
      );
    }
  };

  function handleLoad () {
    setLoaded(false);
    clearImages();
    setShortcutTarget({});
    setGroupIds([])
    clearSelects()

    axios.get(`/api/v1/shortcuts`).then((shortcutsResponse) => {
      setShortcuts(shortcutsResponse.data);
      setDefaultShortcuts(shortcutsResponse.data);
      setLoaded(true);
    });

    axios.get('api/v1/profiles').then((response) => {
      const profiles: Profile[] = response.data
      const profiles_filtered = profiles.filter((profile: Profile) => profile.id === '2f305f43-c236-4a93-8a1c-c365f296190d' || profile.id === 'c843021e-0c33-40cd-a0d2-0ec36fa1bb7a')
      setProfiles(profiles_filtered)
    })

    axios.get('api/v1/groups').then((response) => {
      const groups = response.data
      setGroups(groups)
    })
  }

  const handleSearchShortcut = (name: string) => {
    const filteredShortcuts = defaultShortcuts.filter(
      (shortcut: any) =>
        shortcut.name.toLowerCase().includes(name.toLowerCase()) ||
        shortcut.display_name1.toLowerCase().includes(name.toLowerCase()) ||
        shortcut.display_name2.toLowerCase().includes(name.toLowerCase())
    );
    setShortcuts(filteredShortcuts);
    setDefaultSearch(name);
  };

  function handleDeleteShortcut (id: string, order: number) {
    setLoaded(false)
    try {
      axios.delete(`/api/v1/shortcuts/${id}/${order}`).then(() => {
        handleLoad()
      })
    } catch (error) {
      alert('Error deleting a shortcut, please try again.')
      handleLoad()
    }
  }

  const handleSelectGroup = (value: any) => {
    if (value) {
      setGroupIds(value)
    } else {
      setGroupIds([])
    }
  }

  const makeShortcutAction = () => {
    if (triggerModal === 'create') {
      let group_ids: string[] = []
      groupIds.map((groupID) => {
        group_ids.push(groupID.value)
      })
      const dataCreate = {
        name: shortcutTarget.name,
        display_name1: shortcutTarget.display_name1,
        display_name2: shortcutTarget.display_name2,
        desktop_image: imageDesktopFile,
        mobile_image: imageMobileFile,
        link_desktop: shortcutTarget.link_desktop,
        link_mobile: shortcutTarget.link_mobile,
        group_ids: selectedGroup,
        favorite: false
      };
      axios
        .post(`/api/v1/shortcuts`, dataCreate)
        .then(() => {
          handleLoad();
          setTriggerModal(false);
        })
        .catch((error) => {
          if (error.response.data.code === '23505') {
            const regExp = /\(([^)]+)\)/;
            const errorMessage: any = regExp.exec(error.response.data.detail);
            alert(`The ${errorMessage[1].replace('_', ' ')} already exists.`);
          }
        });
    }
    if (triggerModal === 'edit') {
      let group_ids: string[] = []
      groupIds.map((groupID) => {
        group_ids.push(groupID.value)
      })
      const dataEdit = {
        name: shortcutTarget.name,
        display_name1: shortcutTarget.display_name1,
        display_name2: shortcutTarget.display_name2,
        desktop_image: imageDesktopFile,
        mobile_image: imageMobileFile,
        link_desktop: shortcutTarget.link_desktop,
        link_mobile: shortcutTarget.link_mobile,
        group_ids: selectedGroup,
        favorite: shortcutTarget.favorite
      };
      setLoaded(true);
      try {
        axios
          .put(`/api/v1/shortcuts/${shortcutTarget.id}`, dataEdit)
          .then(() => {
            handleLoad();
            setDefaultSearch('');
            setTriggerModal(false);
          });
      } catch (error) {
        alert('Error updating a shortcut, please try again.');
        handleLoad();
      }
    }
  };

  useEffect(() => {
    handlePermissions();
    handleLoad();
  }, []);

  function fileSelectDesktop(props: any) {
    if (props[0] === undefined) {
      setImageDesktopName('');
      setImageDesktopFile({
        selectedFile: '',
        type: ''
      });
      return;
    }
    setPreviewDesktop(URL.createObjectURL(props[0]));
    setImageDesktopName(props[0].name);
    const reader = new FileReader();
    reader.readAsDataURL(props[0]);

    reader.onload = (e: any) => {
      setImageDesktopFile({
        selectedFile: e?.target?.result,
        type: props[0].type
      });
    };
  }

  function fileSelectMobile(props: any) {
    if (props[0] === undefined) {
      setImageMobileName('');
      setImageMobileFile({
        selectedFile: '',
        type: ''
      });
      return;
    }
    setPreviewMobile(URL.createObjectURL(props[0]));
    setImageMobileName(props[0].name);
    const reader = new FileReader();
    reader.readAsDataURL(props[0]);

    reader.onload = (e: any) => {
      setImageMobileFile({
        selectedFile: e?.target?.result,
        type: props[0].type
      });
    };
  }

  function clearImages() {
    setPreviewDesktop('');
    setImageDesktopName('');
    setImageDesktopFile({
      selectedFile: '',
      type: ''
    });
    setPreviewMobile('');
    setImageMobileName('');
    setImageMobileFile({
      selectedFile: '',
      type: ''
    });
  }

  function clearGroup() {
    setGroupIds([])
  }

  function handleGroupTarget(group_ids: string[]) {
    if (group_ids) {
      let append: {
        label: string
        value: string
      }[] = []
      group_ids.map((id) => {
        groupsData.forEach((data) => {
          if (data.value == id) {
            append.push({
              label: data.label,
              value: id
            })
          }
        })
      })
      const newIds = [
        ...groupIds,
        ...append
      ]
      setGroupIds(newIds)


      let selectedProfiles: string[] = []
      let selectedGroups: string[] = []

      for (let id of group_ids) {
        groups.forEach((group) => {
          if (group.id === id) {
            const profile = profiles.filter((profile) => profile.id === group.profile_id)[0]
            if (!selectedProfiles.includes(profile.id)) selectedProfiles.push(profile.id)
            if (!selectedGroups.includes(group.id)) selectedGroups.push(group.id)
          }
        })
      }

      setSelectProfile(selectedProfiles)
      setSelectedGroup(selectedGroups)
      handleGroupOptions(selectedProfiles)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDragEnd = (result: DropResult, provided?: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const params = {
      sourceId: result.draggableId,
      sourceIndex: result.source.index,
      destinationIndex: result.destination.index
    };

    if (params.destinationIndex < params.sourceIndex) {
      axios
        .get(`/api/v1/shortcuts/up/${params.sourceId}/${shortcuts[params.destinationIndex].order}`)
        .then(() => {
          handleLoad();
        });
    }

    if (params.destinationIndex > params.sourceIndex) {
      axios
        .get(`/api/v1/shortcuts/down/${params.sourceId}/${shortcuts[params.destinationIndex].order}`)
        .then(() => {
          handleLoad();
        });
    }
  };

  function handleSelectedProfile(event: ChangeEvent<{value: unknown}>) {
    const profiles_id = event.target.value as string[]

    // if (profiles_id.length < selectedProfile.length) {
    //   const newSelectedGroups: string[] = []
    //   for (let id of profiles_id) {
    //     selectedGroup.forEach((group_id) => {
    //       groups.forEach((group) => {
    //         if (group_id === group.id && )
    //       })
    //     })
    //   }
    // } else {
    // }
    setSelectProfile(profiles_id)
    handleGroupOptions(profiles_id)  
  }

  function handleSelectedGroup(event: ChangeEvent<{value: unknown}>) {
    const groups_id = event.target.value as string[]
    setSelectedGroup(groups_id)
  }

  function renderSelectedProfile(selected: unknown) {
    let profile_names: string[] = []

    for (let id of selected as string[]) {
      profiles.map((profile) => {
        if (id === profile.id) {
          profile_names.push(profile.name)
        }
      })
    }

    return (
      <div className={styles.chips}>
        {profile_names.map((value) => (
          <Chip key={value} label={value} className={styles.chip} />
        ))}
      </div>
    )
  }

  function renderSelectedGroup(selected: unknown) {
    let groups_names: string[] = []

    for (let id of selected as string[]) {
      groups.map((group) => {
        if (id === group.id) {
          groups_names.push(group.name)
        }
      })
    }

    return (
      <div className={styles.chips}>
        {groups_names.map((value) => (
          <Chip key={value} label={value} className={styles.chip} />
        ))}
      </div>
    )
  }

  function handleGroupOptions(profiles_id: string[]) {
    let data: Group[] = []

    profiles_id.map((id) => {
      groups.filter((group) => group.profile_id === id).map((group) => {
        data.push(group)
      })
    })

    setGroupOptions(data)
  }

  function clearSelects() {
    setSelectProfile([])
    setSelectedGroup([])
  }

  const shortcutModal = (
    <div style={{ display: triggerModal ? 'block' : 'none' }} className='modal'>
      <div className='modal-content'>
        <div className='modal-header'>
          <span
            onClick={() => {
              setTriggerModal(false);
              clearImages();
              clearGroup()
              clearSelects()
            }}
            className='close'
          >
            &times;
          </span>
          <h3>FEG Home Shortcut Manager</h3>
        </div>
        <div className='modal-form'>
          {!loaded ? (
            <Loader />
          ) : (
            <>
              <div className='modal-row'>
                <TextField
                  className='textField'
                  size='medium'
                  label={'Shortcut Name (Not Displayed)'}
                  value={shortcutTarget.name ? shortcutTarget.name : ''}
                  onChange={(e) =>
                    setShortcutTarget({
                      ...shortcutTarget,
                      name: e.target.value
                    })
                  }
                />

                <div className='textField' style={{display: 'flex', flexDirection: 'column'}}>
                  <FormControl>
                    <InputLabel id="profile2">Profiles</InputLabel>
                    <Select2
                      labelId="profile2"
                      id="profile2"
                      multiple
                      value={selectedProfile}
                      onChange={handleSelectedProfile}
                      renderValue={renderSelectedProfile}
                    >
                      {profiles.map((profile) => (
                        <MenuItem key={profile.id} value={profile.id}>
                          {profile.name}
                        </MenuItem>
                      ))}
                    </Select2>
                  </FormControl>

                  <FormControl>
                    <InputLabel id="group">Groups</InputLabel>
                    <Select2
                      labelId="group"
                      id="groupSelected"
                      multiple
                      value={selectedGroup}
                      onChange={handleSelectedGroup}
                      renderValue={renderSelectedGroup}
                    >
                      {groupOptions.map((group) => (
                        <MenuItem key={group.id} value={group.id}>
                          {group.name}
                        </MenuItem>
                      ))}
                    </Select2>
                  </FormControl>
                </div>

                {/* <Select
                  isMulti
                  name='groups'
                  value={groupIds}
                  options={groupsData}
                  className='textField'
                  classNamePrefix="select"
                  placeholder='Select Groups'
                  onChange={handleSelectGroup}
                /> */}
              </div>
              <div className='modal-row'>
                <TextField
                  className='textField'
                  size='medium'
                  label={'App Name '}
                  value={
                    shortcutTarget.display_name1
                      ? shortcutTarget.display_name1
                      : ''
                  }
                  onChange={(e) =>
                    setShortcutTarget({
                      ...shortcutTarget,
                      display_name1: e.target.value
                    })
                  }
                />
                <TextField
                  className='textField'
                  size='small'
                  label={'App Description (up to 200 characters)'}
                  value={
                    shortcutTarget.display_name2
                      ? shortcutTarget.display_name2
                      : ''
                  }
                  onChange={(e) =>
                    setShortcutTarget({
                      ...shortcutTarget,
                      display_name2: e.target.value
                    })
                  }
                />
              </div>
              <div className='modal-row'>
                <TextField
                  className='textField'
                  size='medium'
                  label={'Desktop Link'}
                  value={
                    shortcutTarget.link_desktop
                      ? shortcutTarget.link_desktop
                      : ''
                  }
                  onChange={(e) =>
                    setShortcutTarget({
                      ...shortcutTarget,
                      link_desktop: e.target.value
                    })
                  }
                />
                <TextField
                  className='textField'
                  size='medium'
                  label={'Mobile Link'}
                  value={
                    shortcutTarget.link_mobile ? shortcutTarget.link_mobile : ''
                  }
                  onChange={(e) =>
                    setShortcutTarget({
                      ...shortcutTarget,
                      link_mobile: e.target.value
                    })
                  }
                />
              </div>
              <div className='modal-row'>
                <div style={{ width: 250 }}>
                  <input
                    id='imageDesktop'
                    type='file'
                    accept='.jpg,.png'
                    style={{ display: 'none' }}
                    onChange={(event) => fileSelectDesktop(event.target.files)}
                  />
                  <label htmlFor='imageDesktop'>
                    <p style={{ color: textColor, marginBottom: 4 }}>
                      Image
                    </p>
                    <img
                      src={
                        previewDesktop
                          ? previewDesktop
                          : shortcutTarget.desktop_image
                      }
                      alt='desktop'
                      style={{
                        display: shortcutTarget.desktop_image
                          ? 'block'
                          : imageDesktopName
                            ? 'block'
                            : 'none',
                        marginBottom: 4
                      }}
                      height='100px'
                      width='100%'
                    />
                    <Button
                      variant='contained'
                      color='default'
                      component='span'
                    >
                      {triggerModal === 'edit'
                        ? 'change'
                        : imageDesktopName
                          ? imageDesktopName
                          : '+'}
                    </Button>
                    <br />
                    <small>Image file must be squared (200 x 200 pixels) and size must be under 2MB </small>
                  </label>
                </div>
                <div style={{ width: 250, display: 'none'}}>
                  <input
                    id='imageMobile'
                    type='file'
                    accept='.jpg,.png'
                    style={{ display: 'none' }}
                    onChange={(event) => fileSelectMobile(event.target.files)}
                  />
                  <label htmlFor='imageMobile'>
                    <p style={{ color: textColor, marginBottom: 4 }}>
                      Mobile Image
                    </p>
                    <img
                      src={
                        previewMobile
                          ? previewMobile
                          : shortcutTarget.mobile_image
                      }
                      alt='mobile'
                      style={{
                        display: shortcutTarget.mobile_image
                          ? 'block'
                          : imageMobileName
                            ? 'block'
                            : 'none',
                        marginBottom: 4
                      }}
                      height='100px'
                      width='100%'
                    />
                    <Button
                      variant='contained'
                      color='default'
                      component='span'
                    >
                      {triggerModal === 'edit'
                        ? 'change'
                        : imageMobileName
                          ? imageMobileName
                          : '+'}
                    </Button>
                    <br />
                    <small>The image file size must be under 2MB.</small>
                  </label>
                </div>
              </div>
              <div className='modal-submit'>
                <Button
                  size='small'
                  variant='contained'
                  color='primary'
                  onClick={() => makeShortcutAction()}
                  disabled={!allInputs}
                >
                  SAVE
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );


  return !loaded ? (
    <Loader />
  ) : (
    <div className='background'>
      <div className='container'>
        <h2>FEG Home Shortcut Manager</h2>
        <div className='searchAndAddRow'>
          <TextField
            variant='outlined'
            size='small'
            label='Search Shortcut'
            defaultValue={defaultSearch}
            onChange={(e) => handleSearchShortcut(e.target.value)}
            className='searchShortcut'
          />
          <AddButton
            style={{display: permissions.add ? '' : 'none'}}
            size='medium'
            variant='contained'
            color='primary'
            onClick={() => {
              setShortcutTarget({});
              clearImages();
              setTriggerModal('create');
              clearSelects()
            }}
          >
            Add Shortcut
          </AddButton>
        </div>
        <div className='tableSection'>
          <TableContainer component={Paper}>
            <Table size='small' aria-label='Shortcuts table'>
              <TableHeadContainer>
                <TableRow>
                  <TableHeaderCell align='center'>&nbsp;</TableHeaderCell>
                  <TableHeaderCell align='center'>
                    Shortcut Name
                  </TableHeaderCell>
                  <TableHeaderCell align='center'>
                  App Name 
                  </TableHeaderCell>
                  <TableHeaderCell align='center'>
                  App Description
                  </TableHeaderCell>
                  <TableHeaderCell align='center'>
                    Desktop Image
                  </TableHeaderCell>

                  <TableHeaderCell align='center'>Link Desktop</TableHeaderCell>
                  <TableHeaderCell align='center'>Link Mobile</TableHeaderCell>
                  <TableHeaderCell align='center'>Order</TableHeaderCell>
                  <TableHeaderCell align='center'>Actions</TableHeaderCell>
                </TableRow>
              </TableHeadContainer>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId='droppable' direction='vertical'>
                  {(droppableProvided: DroppableProvided) => (
                    <TableBody
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                    >
                      {shortcuts.map((shortcut: any, index: number) => (
                        <Draggable
                          key={shortcut.id}
                          draggableId={shortcut.id}
                          index={index}
                        >
                          {(
                            draggableProvided: DraggableProvided,
                            snapshot: DraggableStateSnapshot
                          ) => {
                            return (
                              <TableRow
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                style={{
                                  ...draggableProvided.draggableProps.style,
                                  background: snapshot.isDragging
                                    ? 'rgba(245,245,245, 0.75)'
                                    : 'none'
                                }}
                              >
                                {/* note: `snapshot.isDragging` is useful to style or modify behaviour of dragged cells */}
                                <TableCell align='center'>
                                  {defaultSearch.length >= 1 ? (
                                    <div>
                                      <ReorderIcon
                                        style={{ visibility: 'hidden' }}
                                      />
                                    </div>
                                  ) : (
                                    <div {...draggableProvided.dragHandleProps}>
                                      <ReorderIcon style={{display: permissions.edit ? '' : 'none'}} />
                                    </div>
                                  )}
                                </TableCell>
                                <TableBodyCell
                                  component='th'
                                  scope='row'
                                  align='center'
                                >
                                  <div
                                    title={
                                      shortcut.name.length > 18
                                        ? shortcut.name
                                        : null
                                    }
                                  >
                                    {shortcut.name.length > 18
                                      ? shortcut.name.substring(0, 18) + '...'
                                      : shortcut.name}
                                  </div>
                                </TableBodyCell>
                                <TableBodyCell align='center'>
                                  <div
                                    title={
                                      shortcut.display_name1.length > 14
                                        ? shortcut.display_name1
                                        : null
                                    }
                                  >
                                    {shortcut.display_name1.length > 14
                                      ? shortcut.display_name1.substring(0, 14) + '...'
                                      : shortcut.display_name1}
                                  </div>
                                </TableBodyCell>
                                <TableBodyCell align='center'>
                                  <div
                                    title={
                                      shortcut.display_name2.length > 18
                                        ? shortcut.display_name2
                                        : null
                                    }
                                  >
                                    {shortcut.display_name2.length > 18
                                      ? shortcut.display_name2.substring(0, 18) + '...'
                                      : shortcut.display_name2}
                                  </div>
                                </TableBodyCell>
                                <TableBodyCell align='center'>
                                  <FiImage
                                    width={48}
                                    height={48}
                                    onClick={() =>
                                      window.open(shortcut.desktop_image)
                                    }
                                    style={{ color: `${S.primary}` }}
                                  />
                                </TableBodyCell>
                     
                                <TableBodyCell align='center'>
                                  <div title={shortcut.link_desktop}>
                                    {shortcut.link_desktop.length > 10
                                      ? shortcut.link_desktop.substring(0, 10) + '...'
                                      : shortcut.link_desktop}
                                  </div>
                                </TableBodyCell>
                                <TableBodyCell align='center'>
                                  <div title={shortcut.link_mobile}>
                                    {shortcut.link_mobile.length > 10
                                      ? shortcut.link_mobile.substring(0, 10) + '...'
                                      : shortcut.link_mobile}
                                  </div>
                                </TableBodyCell>
                                <TableBodyCell align='center'>
                                  {shortcut.order}
                                </TableBodyCell>
                                <TableCell align='center'>
                                  <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <IconButton
                                      style={{display: permissions.edit ? '' : 'none'}}
                                      onClick={() => {
                                        setShortcutTarget(shortcut);
                                        clearImages();
                                        clearSelects()
                                        setTriggerModal('edit');
                                        handleGroupTarget(shortcut.group_ids)
                                      }}
                                      aria-label='edit'
                                    >
                                      <Edit />
                                    </IconButton>
                                    <IconButton
                                      style={{display: permissions.delete ? '' : 'none'}}
                                      onClick={() =>
                                        handleDeleteShortcut(
                                          shortcut.id,
                                          shortcut.order
                                        )
                                      }
                                      aria-label='delete'
                                      >
                                      <Delete />
                                    </IconButton>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          }}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </TableContainer>
        </div>
      </div>
      {shortcutModal}
    </div>
  );
};

export default ShortcutManagement;
