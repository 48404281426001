import React, { useEffect, useState } from 'react';
import jwt from 'jsonwebtoken';
import LoginButton from '../LoginButton';
import Notifications from '../Notifications';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';
import { Theme } from 'src/styles/Theme';
import { FaSearch } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import Search from './Search';
import { Avatar } from '@material-ui/core';


const Header = (props: any | 0) => {
  const [loaded, setLoaded] = useState<boolean>();
  const [impersonate, setImpersonate] = useState<boolean>();
  const [info, setInfo] = useState<string>();
  const [cookies, setCookies, removeCookies] = useCookies(['token']);
  const history = useHistory();
  const image = localStorage.getItem('image_url');
  let firstName = '';
  let lastName = '';
  useEffect(() => {
    onLoaded();
    handlePermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onLoaded() {
    setTimeout(() => {
      const firstName = localStorage.getItem('firstName');
      const lastName = localStorage.getItem('lastName');
      setInfo(`${firstName} ${lastName}`);
    }, props.timeout);
  }

  function handlePermissions() {
    if (localStorage.getItem('token')) {
      jwt.verify(
        localStorage.getItem('token')!.toString(),
        '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
        (err: any, decoded: any) => {
          if (err) {
            alert(err);
          } else {
            if (decoded.impersonate && decoded.impersonate === true)
              setImpersonate(decoded.impersonate);
          }
        }
      );
      return;
    }
  }

  function handleChange() {
    while (firstName === undefined || lastName === undefined) {
      setLoaded(true);
    }
  }

  function exitToUser() {
    // localStorage.clear()
    setCookies('token', sessionStorage.getItem('originalToken'), {
      domain: '.systemfeg.com'
    });
    sessionStorage.clear();
    history.push('/');
    window.location.reload();
  }

  return (
    <header
      onLoad={handleChange}
      className={`${props.sidebarOpen ? 'sidebar-open' : ''}`}
    >
      {loaded ? (
        <h2>Welcome</h2>
      ) : (
        <div className='upper'>
          {!props.sidebarOpen && (
            <FiMenu
              size={26}
              className='icon-menu'
              onClick={() => props.setSidebarOpen(!props.sidebarOpen)}
            />
          )}
        {!props.sidebarOpen && (
                <div className='header-title'>
                  <Search  />     
                </div>
        )}
 

          <button
            onClick={exitToUser}
            id='impersonateButton'
            style={{ display: impersonate ? '' : 'none' }}
          >
            Exit to my user
          </button>
        </div>
      )}

      <div className='icons'>
        {
          image ? <img
          src={
            image
              ? image
              : 'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/shortcuts/desktop/e82612b7-3670-4c60-932a-eff7c5ff77ae.png'
          }
          alt='FEG LOGO'
        />:<Avatar>{firstName[0]}</Avatar>
        }
    
      <div className='setting-title-div'>
        <p className='div-name'>{info}</p>
      </div>
      
        <Notifications color={`${Theme.primary}`} />
        <LoginButton color={`${Theme.primary}`} width='20%' />      
      

      </div>
    </header>
  );
};

export default Header;
