//Components with modal for latest news
import React, { useState } from 'react';
import * as S from './styled';
import { useHistory } from 'react-router-dom';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axios from '../../services/axios';
import { AiOutlineFullscreenExit } from "react-icons/ai";
import { Theme } from 'src/styles/Theme';

export default function IdeaLatestNews({close}: any) {
  const [imageFile, setImageFile] = useState({
    selectedFile: '',
    type: ''
  });
  const nav = useHistory();
  const [imageName, setImageName] = useState('');
  const [title, setTitle] = useState<string>('');
  const [fullLegend, setFullLegend] = useState<any>(EditorState.createEmpty());
  const [prevLegend, setPrevLegend] = useState('');
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);

  const customerId = localStorage.getItem('id');

  async function createAnnouncement() {
    const htmlDescription = draftToHtml(
      convertToRaw(fullLegend.getCurrentContent())
    );
    const dataFile = new FormData();
    console.log(file)
    if (file) {
      dataFile.append('file', file);
    }

    await axios.post('/api/v1/latest-news/uploadFile', dataFile, {timeout: 0}).then( res => {
      const data = {
        image: res.data.type === 'image' ? res.data.url : '',
        video: res.data.type === 'video' ? res.data.url : '',
        prev_legend: prevLegend,
        full_legend: htmlDescription,
        title: title,
        idea: true,
        customer_id: customerId
      };
      axios
        .post('/api/v1/latest-news/create', data, { headers: {
          ContentType: 'multipart/form-data'
        }, timeout: 0})
        .then((res) => {
          setTitle('');
          setPrevLegend('');
          setImageName('');
          setImageFile({
            selectedFile: '',
            type: ''
          });
          setFullLegend('');
          setConfirmation(false);
          alert('Latest News created');
          nav.push('/announcements');
        })
        .catch((err) => {
          console.log(err);
        });
    })

  }
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  return (
    <S.Container>
      <S.header>
        <S.Title>Latest News - Idea Submission</S.Title>
        <S.Icon onClick={() => close()}><AiOutlineFullscreenExit color={Theme.primary} size={40}/></S.Icon>
      </S.header>

      <S.Form>
        
     
        <h4>Title</h4>
        <input
          type='text'
          name='nameInput'
          value={title}
          maxLength={250}
          onChange={(e) => setTitle(e.target.value)}
        />
        <h4>Preview Field</h4>
        <input
          type='text'
          name='nameInput'
          value={prevLegend}
          maxLength={250}
          onChange={(e) => setPrevLegend(e.target.value)}
        />
         
        <h4>Picture or Video</h4>
        <label htmlFor="video-upload" style={{padding: 10, background: `${Theme.primary}`, color: 'white', borderRadius: 4, marginRight: 10, cursor: 'pointer'}}>Choose a video or photo to upload:</label>
        {file?.name}
      <input id="video-upload" type="file" onChange={handleFileChange} />
      <p style={{marginTop: 15}}>The system does not accept files larger than 1TB.</p>
        <h4>Full Article</h4>

        <Editor
          editorState={fullLegend}
          wrapperClassName='demo-wrapper'
          stripPastedStyles={true}
          editorClassName='demo-editor'
          onEditorStateChange={(e) => {
            setFullLegend(e);
          }}
        />
          <S.Button className='button' onClick={() => createAnnouncement()}>
        Publish
      </S.Button>
      </S.Form>

    
    </S.Container>
  )
}