import React, { useEffect, useState } from 'react';

const useWindowWith = (): number => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update the window width when the component mounts
    setWindowWidth(window.innerWidth);

    // Create a listener function to update the window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach the listener function to the window's resize event
    window.addEventListener('resize', handleResize);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array to run the effect only once

  return windowWidth;
};

export default useWindowWith;
